import React from "react";
import {Alert} from "react-bootstrap";
import {useHistory} from 'react-router';
import {route} from "app/config/routes/route";

export const InlineQueryError = ({ isLoading, error, isError, message = "Podczas ładowania danych wystąpił bład, prosimy spróbować później", className }) => {
    if ((!isError && isError!==undefined) || isLoading)
        return null;

    return (
        <Alert variant="danger" className={`w-100 ${className || ''}`}>
            {typeof(error) !== "object" ? error : (
                <>
                    {message}
                    {error?.response && ` [${error?.response?.status}: ${error?.response?.statusText}]`}
                </>
            )}
        </Alert>
    );
};


const QueryError = ({ error, message, isError, isLoading, children, noButton }) => {
    const history = useHistory()
    console.log({error})
    if (isLoading || (!isError && isError!==undefined))
        return null;

    return (
        <div className="border bg-white px-10 py-15 rounded-lg text-center d-flex flex-column align-items-center w-100">
            <i className="fas fa-exclamation-triangle fa-3x" />
            {error?.response?.status === 404 ? (
                <>
                    <h1 className="display-4 font-weight-bolder mt-4">Przepraszamy!</h1>
                    <h2>Tej strony nie ma w naszym systemie</h2>
                    {!noButton && (
                        <button className="btn btn-primary btn-lg mt-8" onClick={() => history.push(route['index'])}>
                            <i className="fas fa-arrow-left" /> Powrót do <b>traineron.com</b>
                        </button>
                    )}
                </>
            ) : (
                <>
                    <h1 className="display-4 font-weight-bolder mt-4">Przepraszamy, coś poszło nie tak</h1>
                    <h2>Prosimy spróbować ponownie później</h2>
                    {(message || error?.message || error?.response?.data?.message) && (
                        <span className="text-muted mt-4">{message || error?.response?.data?.message || error?.message}</span>
                    )}
                </>
            )}
            {children}
        </div>
    )
}

export default QueryError;
