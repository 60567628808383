import {
    CLOSE_PLAYER_WELCOME,
    CLOSE_TRAINER_WELCOME,
    DEBUG_OPEN_WELCOME,
    LOGOUT,
    SET_AUTH_TOKEN,
    SET_USER
} from "app/redux/auth/actionTypes";

const initialState = {
    user: undefined,
    authToken: undefined,
    error: undefined,
};

const authReducer = (state = initialState, action) => {

    switch(action.type) {
        case SET_AUTH_TOKEN:
            const authToken = action.payload;
            return {
                ...state,
                authToken,
                user: undefined,
            }
        case LOGOUT:
            // queryCache.clear();
            return {
                ...initialState,
                error: action.payload,
            }
        case SET_USER:
            return {
                ...state,
                user: action.payload,
            }
        case CLOSE_TRAINER_WELCOME:
            return {
                ...state,
                user: {
                    ...state.user,
                    isFirstLoginTrainer: false,
                },
            }
        case CLOSE_PLAYER_WELCOME:
            return {
                ...state,
                user: {
                    ...state.user,
                    isFirstLoginPlayer: false,
                },
            }
        case DEBUG_OPEN_WELCOME: // TODO: remove after testing
            return {
                ...state,
                user: {
                    ...state.user,
                    isFirstLoginTrainer: true,
                    isFirstLoginPlayer: true,
                },
            }
        case 'persist/REHYDRATE':
            return {
                ...initialState,//just in case (user or other scripts can edit localstorage state)
                ...state,
            }
        default:
            return state;
    }
}

export default authReducer;
