import React, {useEffect, useMemo} from "react";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import {useHtmlClassService} from "_metronic/layout/_core/MetronicLayout";
import {toAbsoluteUrl} from "_metronic/_helpers";
import Logo from "components/Logo";
import { route } from "app/config/routes/route";
import {useDispatch, useSelector} from "react-redux";
import {dispatchSetAsideMinimize, LAYOUT_MODE_PLAYER} from "app/redux/app/appActionTypes";
import {KTUtil} from "_metronic/_assets/js/components/util";
import usePrevious from "app/hooks/usePrevious";
import {ASIDE_MINIMIZED, ASIDE_MINIMIZED_TMP, ASIDE_MAXIMIZED, ASIDE_MAXIMIZED_TMP} from "app/redux/app/appReducer";

export function Brand() {
  const uiService = useHtmlClassService();
  const {layoutMode, isAsideMinimized} = useSelector(({app})=>app.layout);
  const dispatch = useDispatch();
  // const prevIsAsideMinimized = usePrevious(isAsideMinimized);

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(
          uiService.config,
          "aside.self.minimize.toggle"
      ),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo()
    };
  }, [uiService]);

  const handleToggleMenuMaximized = () => {
    if (isAsideMinimized===ASIDE_MAXIMIZED || isAsideMinimized===ASIDE_MAXIMIZED_TMP)
        return dispatch(dispatchSetAsideMinimize(ASIDE_MINIMIZED));

    if (isAsideMinimized===ASIDE_MINIMIZED || isAsideMinimized===ASIDE_MINIMIZED_TMP)
        return dispatch(dispatchSetAsideMinimize(ASIDE_MAXIMIZED));

    dispatch(dispatchSetAsideMinimize(ASIDE_MAXIMIZED));
  };

  useEffect(() => {
      const body = KTUtil.getBody();
      if (isAsideMinimized > 0) {
          KTUtil.removeClass(body, 'aside-minimize')
      } else if (isAsideMinimized < 0) {
          KTUtil.addClass(body, 'aside-minimize')
      }
  },[isAsideMinimized]);

  return (
    <>
      {/* begin::Brand */}
      <div
          className={`brand flex-column-auto ${layoutProps.brandClasses}`}
          id="kt_brand"
      >
        {/* begin::Logo */}

          <Logo
            url={layoutMode === LAYOUT_MODE_PLAYER ? route['pp'] : route['tp']}
            color="light"
          />
        {/* end::Logo */}

        {layoutProps.asideSelfMinimizeToggle && (
          <>
            {/* begin::Toggle */}
            <button className="brand-toggle btn btn-sm px-0" onClick={handleToggleMenuMaximized}>
              <span className="svg-icon svg-icon-xl">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/>
              </span>
            </button>
            {/* end::Toolbar */}
            </>
        )}
      </div>
      {/* end::Brand */}
      </>
  );
}
