export const createChatUser = (
  {
    id,
    name,
    surname,
    profilePhotoUrl,
  },
  clientId,
  clientStatus,
  type
) => ({
  id,
  clientId,
  clientStatus,
  name: `${name??''} ${surname??''}`,
  photoUrl: profilePhotoUrl,
  type,
});