export const DAY_TYPE_TRAINING = 1;
export const DAY_TYPE_REST = 2;

export const COLOR_DANGER = 1;
export const COLOR_SUCCESS = 2;
export const COLOR_PRIMARY = 3;
export const COLOR_WARNING = 4;

export const COLORS = [
    ['primary', COLOR_PRIMARY],
    ['danger',  COLOR_DANGER ],
    ['success', COLOR_SUCCESS],
    ['warning', COLOR_WARNING],
];

export const getColor = (colorId) => {
    if(colorId===0) return 'secondary';
    const color = COLORS.findIndex(( ([,id]) => id===colorId ));
    if(color === -1) return 'primary';
    return COLORS[color][0];
}

export const createDayObj = ({
    dayNo,
    type = DAY_TYPE_TRAINING,
    trainerNote = null,
    trainerNoteColor = 0,
    plannedDate = null,
}) => ({
    dayNo,
    type: type===0 ? DAY_TYPE_TRAINING : type,
    trainings: [],
    trainerNote,
    trainerNoteColor: trainerNoteColor,
    plannedDate,
});