import {reducerError} from "app/redux/configurator/reducers/reducerError";

export const reducerDayEditNote = (state, {dayNo, trainerNote, trainerNoteColor}) => {
    if(!state.days[dayNo]) {
        return reducerError(state, 'day.edit.trainingNotExist');
    }

    return {
        ...state,
        days: {
            ...state.days,
            [dayNo]: {
                ...state.days[dayNo],
                trainerNote,
                trainerNoteColor,
            },
        },
    };
}