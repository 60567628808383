import React from 'react';
import {AppSwitch} from "app/core/router/components/AppSwitch";
import {AppRoute} from "app/core/router/components/AppRoute";
import {route} from "app/config/routes/route";
import {defaultTitle} from "app/config/seo";

const Trainers           = React.lazy(() => import('views/pages/public/trainers/View_Trainers/Trainers'));
const ViewTrainer = React.lazy(() => import('views/pages/public/trainers/View_Trainer/View_Trainer'));

export const TrainersRoutes = () => {
    return (
        <AppSwitch>
            <AppRoute
                exact
                path={route['p.trainers']}
                component={Trainers}
                meta={{
                    title: defaultTitle('Trenerzy'),
                }}
            />
            <AppRoute
                exact
                path={route['p.trainer']()}
                component={ViewTrainer}
                meta={{
                    title: defaultTitle('Trener'),
                }}
            />
        </AppSwitch>
    );
};
