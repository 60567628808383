import React, {useEffect, useRef, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {StyledConversationSearch} from "views/pages/common/chats/components/Conversations.styled";
import useAllLocationParams from "app/hooks/useAllLocationParams";
import {useDispatch} from "react-redux";
import {actionSetSearch} from "app/redux/chats/chatActions/actionSetSearch";

const Search = () => {
    const { q } = useAllLocationParams();
    const [searchValue, setSearchValue] = useState(q || '');
    const { search } = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actionSetSearch(q));
    }, [q]);
    useEffect(() => {
        return () => {
            dispatch(actionSetSearch(''))
        }
    }, []);

    const refTimeout = useRef(null);
    const handleChange = (e) => {
        if (e?.target?.value == null) return;
        const value = e.target.value;

        setSearchValue(value);
        clearTimeout(refTimeout.current);
        refTimeout.current = setTimeout(() => {
            const newSearch = new URLSearchParams(search);
            const newValue = value.trim();
            if (newValue) newSearch.set('q', value.trim());
            else newSearch.delete('q');

            history.replace({
                search: newSearch.toString(),
            });
        }, 500);
    };

    return (
        <StyledConversationSearch
            value={searchValue}
            onChange={handleChange}
        />
    );
};

export default Search
