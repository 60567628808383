import {BTN_MINUS, BTN_NONE, BTN_PLUS} from "app/configurator/utils/superseries/enums";

const sortByOrderNo = ({orderNo:a}, {orderNo:b}) => {
    if(a>b) return 1;
    if(a<b) return -1;
    return 0;
}

export const reorderNo = (passedExercises) => {

    if(!passedExercises?.length) return passedExercises;// empty array

    let exercises = passedExercises.sort(sortByOrderNo);

    // helpers
    let lastBase  = 0;
    let lastIndex = 1;
    let lastOrigBase = -1;
    let forceChangeBase = true;

    // add orderNo && reorder && every exercise is superserie (will be removed in next step)
    const exercisesWithSeries = exercises.map(({_superseries, orderNo, ...passedExercise}) => {
        let exercise = {...passedExercise};
        const currentBase  = Math.floor(orderNo/10)*10;
        const currentIndex = orderNo - currentBase;
        if(currentIndex===0 || (currentBase !== lastOrigBase) || forceChangeBase) {
            lastBase+=10;
            lastIndex = 1;
        }
        forceChangeBase = false;
        // console.log('last: ', lastBase, lastIndex,', current:', currentBase, currentIndex, 'orderNo:', lastBase+lastIndex)
        // console.log('-')
        exercise.orderNo = lastBase+lastIndex;
        lastIndex++;
        if(!currentIndex || !currentBase || lastIndex>9 || _superseries?.isLast===true) {
            //TODO: add addCurrentToPrev === true (do not call these instructions)
            // console.log('next')
            forceChangeBase = true;
        }
        lastOrigBase = currentBase;
        return {
            ...exercise,
            _superseries,
        }
    }).reverse(); // reverse all
    // console.log(exercisesWithSeries.map(({orderNo:a})=>a))

    // helpers
    let size  = 1;
    let isNextSuperserie = true;
    lastBase  = -1;
    lastIndex = -1;

    // remove superseries from single elements && add superserie helpers
    const exercisesWithOrderNo = exercisesWithSeries.map(({_superseries, orderNo, ...passedExercise}, i) => {
        const exercise = {...passedExercise};
        const currentBase  = Math.floor(orderNo/10)*10;
        const currentIndex = orderNo - currentBase;
        let superseries = {..._superseries};
        if(lastBase===-1 || lastBase!==currentBase) {
            superseries.bottomBtn = ((currentIndex===1 && size<=8) || (size===1 && currentIndex<=8)) && i!==0 ? BTN_PLUS : BTN_NONE;
            size = currentIndex;
            superseries.isLast = true;
            superseries.superserieSize = size;
        } else {
            superseries.isLast = false;
            superseries.superserieSize = size;
            superseries.bottomBtn = i!==0 ? BTN_MINUS : BTN_NONE;
        }
        // console.log(superseries.showBtn)
        isNextSuperserie = size > 1;
        exercise.orderNo = size===1 ? currentBase : currentBase+currentIndex;
        lastBase = currentBase;
        lastIndex = currentIndex;
        return {
            ...exercise,
            _superseries: superseries,
        }
    }).reverse();
    // console.log(exercisesWithOrderNo.map(({orderNo:a, _superseries:s})=>a));
    // console.log('---')
    return exercisesWithOrderNo;
};