import React from 'react';
import PropTypes from 'prop-types';
import { addSlashesToUrl } from "app/utils/url";

const ExternalLink = ({ to, children, ...props }) => {

    return (
        <a href={addSlashesToUrl(to)} target="_blank" rel="noopener noreferrer" {...props}>{children}</a>
    );
};

ExternalLink.propTypes = {
    to: PropTypes.string.isRequired,
};

export default ExternalLink;
