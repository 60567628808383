import React, {useState} from 'react';
import {storeTrainingToApi} from "app/configurator/utils/utilsApi";
import {SaveConfiguratorTraining} from "app/core/api/trainer/configurator";
import {useSelector} from "react-redux";
import {
    selectAllExercises,
    selectAllSections, selectAllTrainings,
    selectUnsavedTrainings
} from "app/configurator/utils/utilsReduxUseSelector";
import {useConfiguratorType} from "app/configurator/contex/configuratorContext";
import {useSnackbar} from "notistack";
import {useGetConfiguratorModule} from "app/configurator/contex/getModuleContext";

export const CalendarSaveModuleTrainings = ({ refetch:passedRefetch, className='btn btn-primary btn-sm', trainingPlanId, moduleId }) => {
    const [isSaving, setIsSaving] = useState(false);
    const unsavedTrainings = useSelector(selectUnsavedTrainings());
    const trainings = useSelector(selectAllTrainings());
    const sections = useSelector(selectAllSections());
    const exercises = useSelector(selectAllExercises());
    // const {refetch: refetchModule} = useGetConfiguratorModule();
    const type = useConfiguratorType();
    const { enqueueSnackbar } = useSnackbar();
    const {refetch:refetch2} = useGetConfiguratorModule(false);
    const refetch = passedRefetch||refetch2;

    const getTrainingsObjToApi = () => {
        let trainingsToSave = [];
        unsavedTrainings.forEach((trainingIndex, i) => {
            if(trainings[trainingIndex]){
                const objToApi = storeTrainingToApi(trainings[trainingIndex], sections, exercises);
                const dayNo = trainings[trainingIndex]?.dayNo;
                const post = () => SaveConfiguratorTraining(type, objToApi, trainingPlanId, moduleId, dayNo)
                trainingsToSave.push({
                    dayNo,
                    trainingName: trainings[trainingIndex]?.name,
                    post,
                })
            } else {
                console.error('Missing training');
            }
        });
        return trainingsToSave;
    }

    const handleForceSaveModule = async() => {
        if(!isSaving) {
            setIsSaving(true);
            // const objToApi = storeTrainingToApi(training, sections, exercises);
            let success = 0;
            try {
                const trainingsToSave = getTrainingsObjToApi();
                for(let i=0; i<trainingsToSave.length; i++) {
                    try {
                        await trainingsToSave[i]?.post();
                        success++;
                    } catch (e) {
                        const { dayNo, trainingName } = trainingsToSave[i]??{};
                        // console.log(e)
                        // console.log(e?.response)
                        enqueueSnackbar(
                            `Przy zapisywaniu treningu "${trainingName}" (dzień ${dayNo}) wsytąpił błąd.`,
                            {
                                variant: 'error',
                            },
                        );
                    }
                }
                if(success>0) {
                    enqueueSnackbar(
                        `Pomyślnie zapisano${success===trainingsToSave.length?' wszystkie':''} treningi (zapisano: ${success})`,
                        {
                            variant: 'success',
                        },
                    );
                }
                // const response = await Promise.all(trainingsToSave);
                // console.log(response)
                // noinspection ES6MissingAwait
                // console.log(refetch)
                refetch();

                // const response = await PostConfiguratorDay(objToApi, planId, moduleId, dayNo);
                // dispatch(dispatchTrainingForceUpdateFromApi(dayNo, trainingIndex, response.data));
            } catch (e) {
                console.error(e)
            } finally {
                setIsSaving(false);
            }
        }
    }

    return (
        <button
            className={`${className} ${isSaving ? 'spinner spinner-sm spinner-white spinner-left' : ''}`}
            onClick={handleForceSaveModule}
            disabled={isSaving || !unsavedTrainings.length}
        >
            <i className="fas fa-save"/>
            Zapisz zmiany
        </button>
    );
};
