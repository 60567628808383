export const createChatMessage = ({
  id,
  createdAt,
  message,
  isRead,
  createdByTrainerProfileId,
  createdByPlayerProfileId,
}) => {
  return ({
    id,
    createdAt,
    message,
    isTrainer: createdByTrainerProfileId,
    isPlayer: createdByPlayerProfileId,
    isRead,
  });
}
