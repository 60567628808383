
const compareTime = ( a, b ) => {
  if ( a.time < b.time ){
    return 1;
  }
  if ( a.time > b.time ){
    return -1;
  }
  return 0;
}

export const reducerSortChats = (state) => {
  const chatIds = Object.keys(state.chats);
  const filteredChats = chatIds.filter(chatId=>{
    try {
      if (state.search == null) return true;
      if (state?.chats?.[chatId]?.user?.name) {
        if (state.chats[chatId].user.name.toLowerCase().indexOf(state.search.toLowerCase()) !== -1) {
          return true;
        }
      }
      return false;
    } catch(e) {
      return true
    }
  })
  const chats = filteredChats.map(chatId => ({ chatId, time: state.chats[chatId].lastMessageTime }));
  const sorted = chats.sort(compareTime);
  const idsSorted = sorted.map(({chatId})=>chatId);

  return {
    ...state,
    chatsSorted: idsSorted,
  };
}
