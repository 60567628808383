import React from 'react';
import useModal from "app/hooks/useModal";
import {Modal} from "react-bootstrap";
import {Form, Formik, isInteger} from "formik";
import FormikField from "components/form/FormikField";
import FormikTextarea from "components/form/FormikTextarea";
import useQuery2 from "app/hooks/useQuery2";
import {useConfiguratorType} from "app/configurator/contex/configuratorContext";
import {GetConfiguratorModuleInfo, PostConfiguratorModuleInfo} from "app/core/api/trainer/configurator";
import Loading from "components/Loading";
import {FormikSubmitPure} from "components/form/FormikSubmit";
import QueryError from "components/QueryError";
import {FormikShowAlgorithm} from "components/form/FormikShowAlgorithm";
import {CONFIGURATOR_PLAYER_PLAN} from "app/config/configurator";
import FormikCheckbox from "components/form/FormikCheckbox";
import FormikErrors from "components/form/FormikErrors";

const validate = ({ name, descriptionShort, moduleNo }) => {
    const errors = {};
    if (!name) errors.name = 'To pole jest wymagane';
    if (name?.length > 100) errors.name = 'Przekroczono maksymalną długość tego pola (100)';
    if (descriptionShort?.length > 500) errors.descriptionShort = 'Przekroczono maksymalną długość tego pola (500)';
    if (!moduleNo) errors.moduleNo = 'To pole jest wymagane';
    const parsed = Number(moduleNo)
    if (!!moduleNo && !/^([+-]?[1-9]\d*|0)$/.test(moduleNo) || isNaN(parsed) || parsed < 1 || parsed > 20)
        errors.moduleNo = 'Numer modułu musi być liczbą całowitą od 1 do 20';

    return errors;
}

const initialValues = (data) => ({
    name: data?.name??'',
    descriptionShort: data?.descriptionShort??'',
    descriptionLong: data?.descriptionLong??'',
    showAlgorithm: data?.showAlgorithm?.id,
    isActive: data?.isActive,
    moduleNo: data?.moduleNo,
})

export const EditModule = ({trainingPlanId, moduleId}) => {

    const [isOpenEditModuleModal, openEditModuleModal, closeEditModuleModal] = useModal();
    const type = useConfiguratorType();
    const {
        data,
        isFetching,
        isError,
        loadingInfo,
        isSuccess,
    } = useQuery2({
        queryKey: ['tp.trainingPlans.module.info', type, trainingPlanId, moduleId],
        queryFn: GetConfiguratorModuleInfo,
        config: {
            enabled: isOpenEditModuleModal,
            cacheTime: 0,
        }
    });

    const handleSubmit = async (data, { isSubmitting, setSubmitting, setErrors, setStatus }) => {
        if(!isSubmitting) {
            try {
                await PostConfiguratorModuleInfo(type, trainingPlanId, moduleId, data);
                closeEditModuleModal();
                setSubmitting(false);
            } catch({response: {data:errorData}}) {
                setSubmitting(false);
                // console.log(errorData)
                if(errorData?.Message || errorData?.message) {
                    setStatus(errorData.Message??errorData.message);
                }
                if(errorData?.Errors && errorData.Errors?.length) {
                    let errors = {};
                    let hasErrors = false;
                    for(let i=0; i<errorData.Errors.length; i++) {
                        const err = errorData.Errors[i];
                        if(err?.Code && err?.Message) {
                            errors[err?.Code] = err.Message;
                            hasErrors = true;
                        }
                    }
                    if(hasErrors) {
                        setErrors(errors);
                    }
                } else if(errorData?.errors && errorData.errors?.length) {
                    let errors = {};
                    let hasErrors = false;
                    for(let i=0; i<errorData.errors.length; i++) {
                        const err = errorData.errors[i];
                        if(err?.code && err?.message) {
                            errors[err?.code] = err.message;
                            hasErrors = true;
                        }
                    }
                    if(hasErrors) {
                        setErrors(errors);
                    }
                }
                // setStatus
            }
        }
    }

    return (
        <>
            <div className="btn btn-outline-secondary mr-2 btn-sm" onClick={openEditModuleModal}>
                <i className="fas fa-cogs"/>
                Ustawienia modułu
            </div>
            <Modal
                show={isOpenEditModuleModal}
                onHide={closeEditModuleModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edycja modułu</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isError ? (
                        <QueryError
                            {...loadingInfo}
                        />
                    ) : (
                        isFetching ? (
                            <Loading/>
                        ) : (
                            <Formik
                                initialValues={initialValues(data)}
                                validate={validate}
                                onSubmit={handleSubmit}
                            >
                                <Form>
                                    <FormikErrors/>
                                    <div className="row">
                                        <div className="col-12">
                                            <FormikField
                                                name="name"
                                                required
                                                label="Nazwa"
                                                maxLength={100}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <FormikField
                                                name="descriptionShort"
                                                label="Krótki opis"
                                                maxLength={500}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <FormikShowAlgorithm/>
                                        </div>
                                        <div className="col-12">
                                            <FormikTextarea
                                                name="descriptionLong"
                                                label="Długi opis"
                                            />
                                        </div>
                                        <div className={`col-12 ${type===CONFIGURATOR_PLAYER_PLAN ? 'col-sm-6' : ''}`}>
                                            <FormikField
                                                name="moduleNo"
                                                required
                                                label="Numer modułu"
                                            />
                                        </div>
                                        {type===CONFIGURATOR_PLAYER_PLAN && (
                                            <div className="col-12 col-sm-6 d-flex align-items-center">
                                                <FormikCheckbox
                                                    name="isActive"
                                                    label="Moduł aktywny"
                                                />
                                            </div>
                                        )}
                                        <div className="col-12 text-right ">
                                            <span className="btn btn-outline-secondary mr-1" onClick={closeEditModuleModal}>Anuluj</span>
                                            <FormikSubmitPure label="Zapisz"/>
                                        </div>
                                    </div>
                                </Form>
                            </Formik>
                        )
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};
