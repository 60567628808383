
export const selectModule = () => ({
    configurator:{
        module,
    },
}) => module;

export const selectDay = (dayNo) => ({
    configurator:{
        days,
    },
}) => days[dayNo];

export const selectTraining = (trainingIndex) => ({
    configurator: {
        trainings,
    },
}) => trainings[trainingIndex];

export const selectSection = (sectionIndex) => ({
    configurator: {
        sections,
    },
}) => sections[sectionIndex];

export const selectExercises = (sectionIndex) => ({
    configurator: {
        exercises,
    },
}) => exercises[sectionIndex];

export const selectToolbar = () => ({
    configurator: {
        config: {
            isToolbarOpen,
            toolbarTab,
        }
    }
}) => ({
    isToolbarOpen,
    toolbarTab,
});

export const selectToolbarSearch = () => ({
    configurator: {
        toolbarSearch,
    },
}) => toolbarSearch;

export const selectAllTrainings = () => ({
    configurator: {
        trainings,
    },
}) => trainings;

export const selectAllSections = () => ({
    configurator: {
        sections,
    },
}) => sections;

export const selectAllExercises = () => ({
    configurator: {
        exercises,
    },
}) => exercises;

export const selectUnsavedTrainings = () => ({
    configurator: {
        helpers: {
            unsavedTrainings,
        },
    },
}) => unsavedTrainings;

export const selectTrainingPlanInfo = () => ({
    configurator: {
        helpers: {
            trainingPlanId,
            moduleId,
        },
    },
}) => ({ trainingPlanId, moduleId });

export const selectClipboard = () => ({
    configurator: {
        helpers: {
            clipboard,
        },
    },
}) => clipboard;

export const selectFilters = () => ({
    configurator: {
        filters,
    },
}) => filters;