import {reducerError} from "app/redux/configurator/reducers/reducerError";
import {isSectionKeyAllowedToEdit} from "app/configurator/utils/utilsSection";
import {unsavedTrainings} from "app/redux/configurator/utils/configuratorHelpers";

export const reducerSectionEdit = (state, { trainingIndex, sectionIndex, property, newValue }) => {
    if(!state.sections[sectionIndex]) {
        return reducerError(state, 'section.edit.sectionNotExist');
    }
    if(!isSectionKeyAllowedToEdit(property)) {
        return reducerError(state, 'section.edit.propertyNotAllowed');
    }
    return {
        ...state,
        sections: {
            ...state.sections,
            [sectionIndex]: {
                ...state.sections[sectionIndex],
                [property]: newValue,
            },
        },
        helpers: {
            ...state.helpers,
            unsavedTrainings: unsavedTrainings(state.helpers).add([trainingIndex]),
        }
    }
}