import axios from "axios";
import { actionLogout } from "app/redux/auth/actionTypes";

const setupAxios = (store) => {
    // add user token to axios if provided
    axios.interceptors.request.use(
        config => {
            const { auth: { authToken } } = store.getState();
            if(!config.url) console.error('request.url is undefined');
            if (
                (
                    authToken
                ) && (
                    config.url.indexOf('localhost')>=0
                    || (
                        config.url.indexOf('traineron.com')>=0
                    ) && (
                        config.url.indexOf('traineron.com/v1/auth')===-1
                        || config.url.indexOf('traineron.com/v1/auth/me')>=0
                    )
                )
            ) {
                config.headers.Authorization = `Bearer ${authToken}`;
            }
            return config;
        },
        err => Promise.reject(err)
    );

    axios.interceptors.response.use(
        response => {
            if(response && response.headers && response.headers['content-type'] ==='text/html'){
                console.error(`Expected 'application/json' got 'text/html`)
                return Promise.reject(`Expected 'application/json' got 'text/html'`);
            }
            return response;
        },
        error => {
            if(error.request.status === 401) {
                store.dispatch(actionLogout('session.expired'));
            }
            return Promise.reject(error);
        }
    );
}

export default setupAxios;