import React, {useEffect, useMemo, useState} from 'react';
import {useParams} from "react-router";
import {
    ConversationBoxWrapper,
    ConversationListItemWrapper,
    ConversationListWrapper,
    LastMessage,
    StyledProfilePhotoImage
} from "views/pages/common/chats/components/Conversations.styled";
import {Spinner} from "react-bootstrap";
import useAllLocationParams from "app/hooks/useAllLocationParams";
import Search from "views/pages/common/chats/components/Search";
import {useSelectChatsSorted} from "app/redux/chats/selectors/selectChatsSorted";
import {useSelectChatInfo} from "app/redux/chats/selectors/selectChatInfo";
import {actionAddNewMessage} from "app/redux/chats/chatActions/actionAddNewMessage";
import {useMessagesHub, useMessagesHubReceiveMessage} from "app/hooks/signalR/useMessagesHub";
import {useDispatch} from "react-redux";
import {actionSetChats} from "app/redux/chats/chatActions/actionSetChats";
import {useConversations} from "app/core/crud/tp/common/chats/getConversations";
import {actionSetActiveChat} from "app/redux/chats/chatActions/actionSetActiveChat";
import {actionSetChatPagination} from "app/redux/chats/chatActions/actionSetChatPagination";
import {useSelectChatPagination} from "app/redux/chats/selectors/selectChatPagination";

export const useIsConversationActive = (conversationId) => {
    const { chatId } = useParams();
    return parseInt(chatId) === parseInt(conversationId);
}

export const Conversations = ({ panelType = 'pp' }) => {
    const { q } = useAllLocationParams();
    const { chatId } = useParams();

    return (
        <ConversationBoxWrapper chatId={chatId}>
            <Search />
            <ConversationListPure panelType={panelType} search={q} />
        </ConversationBoxWrapper>
    );
};

export const ConversationListPure = ({ panelType, search }) => {
    const { chatsSorted } = useSelectChatsSorted();
    const { chatsPagination } = useSelectChatPagination();
    const [ messagesConnection ] = useMessagesHub();
    const dispatch = useDispatch();

    const handleGetNewMessage = (message) => {
        dispatch(actionAddNewMessage(message.chatConversationId, message));
    }
    useMessagesHubReceiveMessage( messagesConnection, handleGetNewMessage );

    const handleLoadNewMessages = ({data, pagedData}) => {
        // console.log(data, pagedData)
        dispatch(actionSetChats(data, pagedData));
    }

    const handleSetPagination = () => {
        dispatch(actionSetChatPagination);
    }
    const { chatId } = useParams();
    const { status, pagedData } = useConversations(panelType, handleLoadNewMessages, search, chatsPagination);

    useEffect(() => {
        dispatch(actionSetActiveChat(chatId));
    }, [chatId]);


    return (
        <ConversationListWrapper>
            {status!=='success' ? (
                <Spinner className="d-block m-auto" animation="border" />
            ) : (
                chatsSorted.length === 0 ? (
                    status === 'success' ? (
                        <div className="text-center">Nie znaleziono konwersacji</div>
                    ) : (
                        <Spinner animation="border"/>
                    )
                ) : (
                    <>
                        {
                            chatsSorted.map(chatId=>(
                                panelType === 'tp' ? (
                                    <TrainerPanelConversation
                                        key={chatId}
                                        panelType={panelType}
                                        chatId={chatId}
                                    />
                                ) : (
                                    <PlayerPanelConversation
                                        key={chatId}
                                        panelType={panelType}
                                        chatId={chatId}
                                    />
                                )
                            ))
                        }
                        {chatsSorted.length < pagedData?.resultsCount && (
                            <button
                                className="btn btn-sm btn-light mx-auto mt-4 d-block"
                                onClick={handleSetPagination}
                            >
                                Załaduj więcej...
                            </button>
                        )}
                    </>
                )
            )}
        </ConversationListWrapper>
    )
}

const TrainerPanelConversation = ({chatId, panelType}) => {
    const {chat, activeChatId} = useSelectChatInfo(chatId);
    if(!chat) return null;
    const {
        lastMessage,
        lastMessageTime,
        stats,
        unreadCount,
        user,
    } = chat;
    return (
        <ConversationListItemWrapper
            panelType={panelType}
            chatId={chatId}
            id={user?.id}
        >
            <StyledProfilePhotoImage profilePhotoUrl={user?.photoUrl} statusId={user?.clientStatus?.id} />
            <LastMessage
                name={user?.name}
                unread={unreadCount}
                {...lastMessage}
            />
        </ConversationListItemWrapper>
    );
}

const PlayerPanelConversation = ({chatId, panelType}) => {
    const {chat, activeChatId} = useSelectChatInfo(chatId);
    if(!chat) return null;
    const {
        lastMessage,
        lastMessageTime,
        stats,
        unreadCount,
        user,
    } = chat;
    return (
        <ConversationListItemWrapper
            panelType={panelType}
            chatId={chatId}
            id={user?.id}
        >
            <StyledProfilePhotoImage profilePhotoUrl={user?.photoUrl} />
            <LastMessage
                name={user?.name}
                unread={unreadCount}
                {...lastMessage}
            />
        </ConversationListItemWrapper>
    );
}
