import {reducerError} from "app/redux/configurator/reducers/reducerError";
import {initialState} from "app/redux/configurator/configuratorReducer";
import {reducerModuleSetBasicData} from "app/redux/configurator/reducers/0.configurator/reducerModuleSetBasicData";
import {createDayObj} from "app/configurator/utils/utilsDay";
import {createTrainingObj} from "app/configurator/utils/utilsTraining";
import {createSectionObj} from "app/configurator/utils/utilsSection";
import {
    createExerciseObj,
    createReducerExerciseObj,
} from "app/configurator/utils/utilsExercise";
import {NO_CHANGES} from "components/form/enums/changeStatus";
import {reorderNo} from "app/configurator/utils/superseries/reorderNo";
import {parseSeries} from "app/redux/configurator/reducers/0.configurator/helpers/parseSeries";
import {getTestExercise} from "app/redux/configurator/reducers/4.exercise/reducerExerciseSetTestExercise";

export const reducerModuleSetEntire = (prevState, { trainingPlanId, moduleId, module }) => {
    const state = initialState();

    if(!module) return reducerError(state, 'module.setEntire.moduleIsEmpty');
    const { days, ...moduleBasicInfo } = module;

    //TODO: set module to store: id, isDemo, photoUrl, publishStatus
    const stateWithModule = reducerModuleSetBasicData(state, moduleBasicInfo);
    //
    let stateDays = {};
    let stateTrainings = {};
    let stateSections  = {};
    let stateExercises = {};
    let highestDayNo = 0;

    for(const day of days) {
        if(!day?.dayNo) continue; // TODO: add error message && more validation;
        const {dayNo, type, trainerNote=null, trainerNoteColor=null, plannedDate} = day;
        if(dayNo>highestDayNo) highestDayNo = dayNo;
        // create dayObj
        const newDay = createDayObj({ dayNo, type:type?.id , trainerNoteColor:trainerNoteColor?.id, trainerNote, plannedDate});
        const trainings = day?.trainings || [];
        // process trainings
        for(const training of trainings) {
            if(!training) continue; //TODO: add error message && more validation
            const {id, name, description, trDisplayType:displayType} = training;
            // add trainingIndex to day.trainings[]
            const trainingIndex = id;
            newDay.trainings.push(trainingIndex);
            // create trainingObj
            const newTraining = createTrainingObj({ id, name, description, dayNo, trainingIndex, displayType });
            // get sections
            const sections = training?.sections || [];
            let sectionsWithoutOrder = []
            // process sections
            for(const section of sections) {
                if(!section) continue; //TODO: add error message && more validation
                //
                const sectionIndex = section.id;
                sectionsWithoutOrder.push([sectionIndex, section?.sectionNo])
                // create sectionObj && exerciseList
                const newSection = createSectionObj({ trainingIndex, ...section });
                let newExerciseList = createReducerExerciseObj();
                // get exercises
                const exercises = section?.exercises || [];
                // process exercises
                for(const exercise of exercises) {
                    if(!exercise) continue; //TODO: add error message && more validation
                    const {exercise:originalExercise, series:seriesToParse, testExercise, ...exerciseParams } = exercise;
                    const {
                        id:exerciseId,
                        name:_name,
                        nameENG:_nameENG,
                        photoUrl:_photoUrl,
                        measureUnitGroup,
                        hasTestExercises,
                    } = originalExercise || {};
                    const changeStatus = NO_CHANGES;
                    const series = parseSeries(measureUnitGroup, seriesToParse);
                    const newExercise = createExerciseObj({
                        exerciseId,
                        _name,
                        _nameENG,
                        _photoUrl,
                        changeStatus,
                        hasTestExercises,
                        testExercise: getTestExercise(testExercise),
                        series,
                        ...exerciseParams,
                    });
                    newExerciseList.exercises.push(newExercise);
                }
                newExerciseList.exercises = reorderNo(newExerciseList.exercises);
                // add section & exerciseList to globals
                stateSections[sectionIndex] = {...newSection};
                stateExercises[sectionIndex] = {...newExerciseList};
            }
            const sectionsOrdered = sectionsWithoutOrder.sort(([,a],[,b]) => a>b);
            newTraining.sections = sectionsOrdered?.map(([a])=>a);
            // add training to globals
            stateTrainings[trainingIndex] = {...newTraining};
        }
        // add day to globals
        stateDays[dayNo] = {...newDay};
    }
    // console.log('--------------------------------------------------------')
    // console.log(stateDays)
    // console.log(stateTrainings)
    // console.log(stateSections)
    // console.log(stateExercises)
    // console.log('--------------------------------------------------------')
    const weeks = Math.max(2, Math.ceil(highestDayNo/7));

    return {
        ...stateWithModule,
        days: stateDays,
        weeks,
        trainings: stateTrainings,
        sections: stateSections,
        exercises: stateExercises,
        config: {
            ...state.config,
            type: prevState.config.type,
        },
        helpers: {
            ...stateWithModule.helpers,
            trainingPlanId,
            moduleId,
        },
    };
}