import {createChatMessage} from "app/redux/chats/utlis/createChatMessage";
import {updateLastMessage} from "app/redux/chats/utlis/updateLastMessage";


export const reducerSetNewMessage = (state, {chatId, message}) => {


  if(!state?.chats?.[chatId]) {
    // TODO: error
    return state;
  }
  const newMessage = createChatMessage(message);
  const lastMessage = updateLastMessage(state.chats[chatId].lastMessage, newMessage);
  return {
    ...state,
    chats: {
      ...state.chats,
      [chatId]: {
        ...state.chats[chatId],
        newMessage: '',
        lastMessage,
        lastMessageTime: lastMessage.createdAt ? new Date(lastMessage.createdAt).getTime() : 0,
      },
    },
    chatMessages: {
      ...state.chatMessages,
      [chatId]: {
        ...state.chatMessages[chatId],
        [message.id]: newMessage,
      },
    },
  };
}
