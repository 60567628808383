import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import App from "./app/core/App";
import "./index.scss"; // Standard version
import 'react-image-lightbox/style.css'; // photo gallery
import 'cropperjs/dist/cropper.css'; // image cropper
// import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
// import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
import './_metronic/_assets/sass/pages/wizard/wizard-2.scss';
// Datepicker
import {registerLocale} from 'react-datepicker'
import setupAxios from "./app/redux/setupAxios";
import {MetronicI18nProvider} from "_metronic/i18n";
import {persistor, store} from "app/redux/rootReducer";
import {MetronicLayoutProvider, MetronicSplashScreenProvider, MetronicSubheaderProvider} from "_metronic/layout";
import pl from "date-fns/locale/pl";
import moment from "moment"; // the locale you want
import 'moment/locale/pl';
import ErrorBoundary from "./app/core/ErrorBoundary";
import {BrowserRouter} from "react-router-dom";
//

const { PUBLIC_URL } = process.env;
setupAxios(store);

registerLocale("pl", pl); // datepicker
moment.locale('pl');

ReactDOM.render(
    <BrowserRouter basename={PUBLIC_URL}>
        <ErrorBoundary>
            <MetronicI18nProvider>
                <MetronicLayoutProvider>
                    <MetronicSubheaderProvider>
                        <MetronicSplashScreenProvider>
                            <App store={store} persistor={persistor}/>
                        </MetronicSplashScreenProvider>
                    </MetronicSubheaderProvider>
                </MetronicLayoutProvider>
            </MetronicI18nProvider>
        </ErrorBoundary>
    </BrowserRouter>,
    document.getElementById("root")
);
