import {reducerError} from "app/redux/configurator/reducers/reducerError";
import {reducerSectionCreate} from "app/redux/configurator/reducers/3.section/reducerSectionCreate";
import {unsavedTrainings} from "app/redux/configurator/utils/configuratorHelpers";
import {reorderNo} from "app/configurator/utils/superseries/reorderNo";
import {moveExerciseSuperserie} from "app/configurator/utils/superseries/moveExercise";

export const reducerExerciseMove = (
    state,
    {
        fromSectionIndex,
        fromExerciseIndex,
        toSectionIndex,
        toExerciseIndex,
        toTrainingIndex,
        toDayNo,
    },
    alreadyTriedAddSection = false
) => {

    if(fromSectionIndex === toSectionIndex){
        return reducerError(state, 'exercise.move.tryingToReorder');
    }
    if(!state.exercises?.[fromSectionIndex]?.exercises[fromExerciseIndex]){
        return reducerError(state, 'exercise.move.sourceNotExist');
    }
    if(!state.exercises?.[toSectionIndex]){
        if(alreadyTriedAddSection) {
            return reducerError(state, 'exercise.move.destinationNotExist');
        }
        const stateWithNewlyCreatedSection = reducerSectionCreate(state, { trainingIndex: toTrainingIndex, dayNo: toDayNo });
        const newSectionIndex = `c${stateWithNewlyCreatedSection.helpers.itemsCount-1}`;
        return reducerExerciseMove(
            stateWithNewlyCreatedSection,
            {
                fromSectionIndex,
                fromExerciseIndex,
                toSectionIndex: newSectionIndex,
                toExerciseIndex,
                toTrainingIndex,
                toDayNo,
            },
            true
        );
    }

    let fromExercises = [...state.exercises[fromSectionIndex].exercises];
    const toExercises = [...state.exercises[toSectionIndex].exercises];

    let [removedExercise] = fromExercises.splice(fromExerciseIndex, 1);
    // console.log(removedExercise)
    if(removedExercise._superseries.isLast && fromExerciseIndex>0 && removedExercise.orderNo-(Math.floor(removedExercise.orderNo/10))*10 !== 0) {
        fromExercises[fromExerciseIndex-1]._superseries.isLast = true;
    }
    removedExercise._superseries.isLast = null;
    removedExercise._superseries.superserieSize = null;
    removedExercise.orderNo = null;

    let removedDest;
    if(toExerciseIndex<=0){
        removedDest = [null, ...toExercises.splice(0, 1)];
    } else {
        removedDest = toExercises.splice(toExerciseIndex-1,2);
    }
    const [prevToReorder, nextToReorder=null] = removedDest;

    const [prev, orig, next] = moveExerciseSuperserie(prevToReorder, removedExercise, nextToReorder);
    if(prev && next){ // prev and next exists
        toExercises.splice(Math.max(0, toExerciseIndex-1), 0, prev, orig, next);
    } else if(prev) { // only prev exists
        toExercises.splice(Math.max(0, toExerciseIndex-1), 0, prev, orig);
    } else if(next) { // only next exists
        toExercises.splice(Math.max(0, toExerciseIndex-1), 0, orig, next);
    } else {
        toExercises.splice(0, 0, orig);
    }


    return {
        ...state,
        exercises: {
            ...state.exercises,
            [fromSectionIndex]: {
                ...state.exercises[fromSectionIndex],
                exercises: reorderNo(fromExercises),
            },
            [toSectionIndex]: {
                ...state.exercises[toSectionIndex],
                exercises: reorderNo(toExercises),
            },
        },
        helpers: {
            ...state.helpers,
            unsavedTrainings: unsavedTrainings(state.helpers).add([
                state.sections[fromSectionIndex].trainingIndex,
                state.sections[toSectionIndex].trainingIndex,
            ]),
        },
    }
}