import {
    SERIE_MODE_MATH,
    SERIE_MODE_MAX,
    SERIE_MODE_MIN
} from "app/redux/configurator/reducers/0.configurator/helpers/parseSeries";

export const removeEmptySeries = (series) => {
    series.headerOrder.forEach(id => {
        // console.log(series.serieRows)
        series.header[id].hasValues = false;
        for(let i=0; i<series.serieRows?.length ;i++) {
            const item = series?.serieRows?.[i]?.items?.[id];
            if((item?.countStr && parseFloat(item?.countStr)) || item?.mode === SERIE_MODE_MIN || item?.mode === SERIE_MODE_MAX || item?.mode === SERIE_MODE_MATH || item?.warning?.id === 3) {
                series.header[id].hasValues = true;
            }
        }
    });
    let newSeries = {
        ...series,
        serieRows: [...series.serieRows],
        serieHeader: {...series.serieHeader},
    };
    newSeries.hasValues = false;
    if(series && series.serieRows) {
        newSeries.serieRows = newSeries.serieRows.filter(({items})=>{
            let isEmpty = true;
            newSeries.headerOrder.forEach((id)=>{
                const item = items[id];
                if(newSeries.header?.[id] && ((item?.countStr && parseFloat(item?.countStr)) || item?.mode === SERIE_MODE_MIN || item?.mode === SERIE_MODE_MAX || item?.mode === SERIE_MODE_MATH  || item?.warning?.id === 3)) {
                    isEmpty = false;
                    newSeries.hasValues = true;
                }
            });
            return !isEmpty;
        });
    }
    // console.log(newSeries.serieRows)
    return newSeries;
};