import React from 'react';
import {AppSwitch} from "app/core/router/components/AppSwitch";
import {AppRoute} from "app/core/router/components/AppRoute";
import {route} from "app/config/routes/route";
import {defaultTitle} from "app/config/seo";

const Players = React.lazy(() => import('views/pages/public/players/Players'));
const Player  = React.lazy(() => import('views/pages/public/players/Player'));

export const PlayersRoutes = () => {
    return (
        <AppSwitch>
            <AppRoute
                exact
                path={route['p.players']}
                component={Players}
                meta={{
                    title: defaultTitle('Sportowcy'),
                }}
            />
            <AppRoute
                exact
                path={route['p.player']()}
                component={Player}
                meta={{
                    title: defaultTitle('Sportowiec'),
                }}
            />
        </AppSwitch>
    );
};
