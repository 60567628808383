import React from 'react';
import { useFormikContext } from "formik";
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {route} from "app/config/routes/route";

const FormikSubmit = ({ label, variant = 'primary', forgotPassword, isSubmit, className, ...props }) => {

    const { isSubmitting, submitForm } = useFormikContext();

    return (<div className="form-group mb-0 d-flex flex-wrap justify-content-between align-items-center">
        {forgotPassword && <Link to={route['auth.password.forgot']} className="kt-link kt-login__link-forgot">
            Zapomniałeś hasła?
        </Link>}
        <button
            type={isSubmit ? 'submit' : 'button'} // sometimes button is outside <form> element (for example in modal)
            disabled={isSubmitting}
            className={`btn btn-${variant} font-weight-bold ml-auto px-9 py-4 my-0 d-flex justify-content-center ${className}`}
            onClick={() => { if(!isSubmit) submitForm()}}
            {...props}
        >
            <span style={{lineHeight: '26.55px'}} className={`${isSubmitting ? "pr-3" : ""}`}>{label}</span>
            {isSubmitting && <span className="spinner-border text-light"/>}
        </button>
    </div>);
};

export const FormikSubmitPure = ({ label, color = 'primary' }) => {
    const { isSubmitting } = useFormikContext();
    return <button
        type="submit"
        disabled={isSubmitting}
        className={`btn btn-${color} ${isSubmitting?'spinner spinner-right':''}`}
    >
        {label}
    </button>
}

FormikSubmit.propTypes = {
    label: PropTypes.node,
    forgotPassword: PropTypes.bool,
};

export default FormikSubmit;
