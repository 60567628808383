import {BASE_URL} from "app/config/env";

export const routeAuth = {
    'auth':                        `${BASE_URL}/auth`,
    'auth.register':               `${BASE_URL}/auth/register`,
    'auth.register.trainer':       `${BASE_URL}/auth/register/trainer`,
    'auth.register.player':        `${BASE_URL}/auth/register/player`,
    'auth.login':                  `${BASE_URL}/auth/login`,
    'auth.logout':                 `${BASE_URL}/auth/logout`,
    'auth.password.forgot':        `${BASE_URL}/auth/password/forgot`,
    'auth.password.reset':         `${BASE_URL}/auth/password/reset`,
    'redirect.afterLogin':         `${BASE_URL}/`,
    'redirect.afterLogin.trainer': `${BASE_URL}/panel-trainer`,
    'redirect.afterLogin.player':  `${BASE_URL}/panel-player`,
}