import {reducerError} from "app/redux/configurator/reducers/reducerError";
import {reducerDayCreate} from "app/redux/configurator/reducers/1.day/reducerDayCreate";
import {DAY_TYPE_REST} from "app/configurator/utils/utilsDay";


export const reducerDayToggleType = (state, { dayNo, newType, removeTrainings, restoreTrainings }, wasTriedCreateDay = false) => {
    if(!state.days[dayNo]) {
        if(wasTriedCreateDay) {
            return reducerError(state, 'day.toggleType.dayNotExist');
        }
        const newState = reducerDayCreate(state, {dayNo});
        return reducerDayToggleType(newState, { dayNo, newType, removeTrainings, restoreTrainings }, true);
    }

    if(removeTrainings) {
        //TODO: remove trainings from edit history
    }
    if(restoreTrainings) {
        //TODO: restore trainings (and add them to editHistory)
    }
    return {
        ...state,
        days: {
            ...state.days,
            [dayNo]: {
                ...state.days[dayNo],
                type: newType,
                trainings: newType===DAY_TYPE_REST ? ([]) : (state.days[dayNo].trainings)
            }
        }
    }
}