import {reducerError} from "app/redux/configurator/reducers/reducerError";
import {unsavedTrainings} from "app/redux/configurator/utils/configuratorHelpers";

export const reducerSeriesToggleAreTheSame = (state, {sectionIndex, exerciseIndex}) => {
    if(!state.exercises?.[sectionIndex]?.exercises?.[exerciseIndex]) {
        return reducerError(state, 'exercise.edit.exerciseNotExist');
    }
    return {
        ...state,
        exercises: {
            ...state.exercises,
            [sectionIndex]: {
                ...state.exercises[sectionIndex],
                exercises: [
                    ...state.exercises[sectionIndex].exercises.slice(0, exerciseIndex),
                    {
                        ...state.exercises[sectionIndex].exercises[exerciseIndex],
                        series: {
                            ...state.exercises[sectionIndex].exercises[exerciseIndex].series,
                            areSerieRowsTheSame: !state.exercises[sectionIndex].exercises[exerciseIndex].series.areSerieRowsTheSame,
                        },
                    },
                    ...state.exercises[sectionIndex].exercises.slice(exerciseIndex+1),
                ]
            }
        },
        helpers: {
            ...state.helpers,
            unsavedTrainings: unsavedTrainings(state.helpers).add([state.sections[sectionIndex].trainingIndex])
        }
    };
};