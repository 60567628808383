import {
    CONFIGURATOR_SET_ENTIRE_MODULE,
    CONFIGURATOR_SET_TYPE,
    SET_SEARCH_EXERCISE_FOLDER,
    SET_TOOLBAR_SEARCH,
    SET_TOOLBAR_TAB,
    WEEK_ADD,
    WEEK_REMOVE
} from "app/redux/configurator/configuratorTypes";

export const dispatchSetToolbarSearch = (search) => ({
    type: SET_TOOLBAR_SEARCH,
    payload: {
        search,
    },
});

export const dispatchSetToolbarTab = (tab) => ({
    type: SET_TOOLBAR_TAB,
    payload: {
        tab,
    },
});

export const dispatchSetEntireModule = (trainingPlanId, moduleId, module) => ({
    type: CONFIGURATOR_SET_ENTIRE_MODULE,
    payload: {
        trainingPlanId,
        moduleId,
        module,
    },
});

export const dispatchWeekAdd = () => ({
    type: WEEK_ADD,
});

export const dispatchWeekRemove = () => ({
    type: WEEK_REMOVE,
});

export const dispatchConfiguratorSetType = (configuratorType) => ({
   type: CONFIGURATOR_SET_TYPE,
    payload: {
       type: configuratorType,
    },
});


export const dispatchSetExerciseSearchFolder = (folder) => ({
    type: SET_SEARCH_EXERCISE_FOLDER,
    payload: {
        folder,
    },
});