import {validateSeries} from "app/redux/configurator/reducers/0.configurator/helpers/validateSeries";
import {
    checkSeriesTheSame,
    createEmptySerieObj,
    createEmptySerieRow
} from "app/redux/configurator/reducers/5.series/helpers/createDefaultSerieHelpers";
import {sortBy} from "app/utils/sort";

export const SERIE_MODE_DEFAULT = 0;
export const SERIE_MODE_MIN     = 1;
export const SERIE_MODE_MAX     = 2;
export const SERIE_MODE_MATH    = 3;
export const SERIE_MODE_CUSTOM  = -1; // used when switching between states

export const parseSerieHeader = (measureUnitGroup) => {
    return measureUnitGroup.items.map(
        ({ id, measureUnit, orderNo, measureUnitOption }) => {
            // console.log(measureUnitOption)
            return ({
                id,
                orderNo,
                name: measureUnit?.name,
                baseUnitGroupId: measureUnit?.id,
                defaultMeasureUnitOption: measureUnitOption?.id,
                defaultMultiplier: measureUnitOption?.conversionBaseValue??1,
                defaultUnitCode: measureUnitOption?.unitCode,
                hasValues: false,
            })
        }
    );
}

const getCountStr = (countStr, multiplier = 1) => {
    // console.log(countStr)
    if(countStr==='0') {
        return '0';
    } else if(!countStr) {
        return null;
    }
    try {
        const countFloat = parseFloat(countStr);
        const parsedCount = (countFloat / multiplier);
        const countWithPrecision = parseFloat(parsedCount).toFixed(3);
        return parseFloat(countWithPrecision);
    } catch (e) {
        return null;
    }
}

// API to redux object
export const parseSeries = (measureUnitGroup, series = []) => {
    // console.log(measureUnitGroup, series)
    let serieObj = createEmptySerieObj();
    serieObj.hasValues = false;
    // console.log(measureUnitGroup, series)
    if(validateSeries(measureUnitGroup, series)) {
        // get ids from header
        const ids = measureUnitGroup.items.map(({id}) => id);
        // console.log(measureUnitGroup)
        const measureUnitIds = measureUnitGroup.items.map(({measureUnit}) => measureUnit?.id);
        const defaultMeasureUnitOptionIds = measureUnitGroup.items.map(({measureUnitOption}) => measureUnitOption?.id);
        const defaultMeasureUnitOptionMultiplier = measureUnitGroup.items.map(({measureUnitOption}) => measureUnitOption?.conversionBaseValue);
        const defaultMeasureUnitOptionUnitCode =  measureUnitGroup.items.map(({measureUnitOption}) => measureUnitOption?.unitCode);
        // create header
        const headerToSet = parseSerieHeader(measureUnitGroup);
        headerToSet.forEach(({id, ...headerItem})=>{
            serieObj.header[id] = {id, ...headerItem};
        });
        // create header order
        // console.log(headerToSet)
        serieObj.headerOrder = headerToSet.sort(sortBy('orderNo')).map(({id}) => id)
        // parse all series
        for(let i=0; i<series.length; i++) {
            // create empty Row
            let serieRow = createEmptySerieRow(ids, defaultMeasureUnitOptionIds, undefined, series[i]?.id, series[i]?.description, defaultMeasureUnitOptionUnitCode);
            serieObj.serieRowsCount++;
            // parse all serieRow.items
            for(let j = 0; j<series?.[i]?.items?.length; j++) {
                // helper:
                const itemMeasureUnitId = series?.[i]?.items?.[j]?.measureUnitOption?.measureUnit?.id;
                // check if measureGroupId is in headerIds
                const multiplier = parseFloat(series?.[i]?.items?.[j]?.measureUnitOption?.conversionBaseValue??1);
                // console.log(measureUnitIds, itemMeasureUnitId)
                if(measureUnitIds.indexOf(itemMeasureUnitId) !== -1) {
                    const id = ids[measureUnitIds.indexOf(itemMeasureUnitId)];
                    // console.log(itemMeasureUnitId, id, series?.[i]?.items?.[j]?.measureUnitOption?.id, parseInt(series?.[i]?.items?.[j].countStr))
                    const item = series?.[i]?.items?.[j];
                    serieRow.items[id].countStr = '';
                    serieRow.items[id].mathMode = {};
                    serieRow.items[id].testExercise = item?.testExercise;
                    serieRow.items[id].isDynamic = item?.isDynamic;
                    serieRow.items[id].countStr = getCountStr(series?.[i]?.items?.[j].countStr, multiplier);
                    // PARSE MODE
                    // console.log(item)
                    if(item?.countStr === 'MIN') {
                        serieRow.items[id].mode = SERIE_MODE_MIN;

                    } else if(item?.countStr === 'MAX') {
                        serieRow.items[id].mode = SERIE_MODE_MAX;

                    } else if(item?.isDynamic && item?.testExercise?.id) {
                        serieRow.items[id].mode = SERIE_MODE_MATH;
                        serieRow.items[id].mathMode = {
                            testExerciseId: item?.testExercise?.id,
                            testExerciseName: item?.testExercise?.name,
                            dynamicOperationStr: item?.dynamicOperationStr,
                            testExerciseBetterScore: item?.testExercise?.betterScore,
                            // testExerciseSubName: item?.testExercise.......
                        };
                    } else {
                        serieRow.items[id].mode = SERIE_MODE_DEFAULT;
                    }
                    if(item?.warning?.id===3 && item?.testExercise?.id) {
                        serieObj.missingTestExercises[item?.testExercise?.id] = item?.testExercise;
                    }
                    serieRow.items[id].id         = item.id;
                    serieRow.items[id].unitCode   = item?.measureUnitOption?.unitCode;
                    serieRow.items[id].unitId     = item?.measureUnitOption?.id;
                    serieRow.items[id].multiplier = multiplier;
                    serieRow.items[id].warning    = item?.warning;
                    // serieRow.items[id].mode       = SERIE_MODE_DEFAULT;
                    // serieRow.items[id].mathMode   = {};
                    if((serieRow.items[id].countStr && parseFloat(serieRow.items[id].countStr) && serieObj.header?.[id]) || serieRow.items[id].mode !== SERIE_MODE_DEFAULT || item?.warning?.id===3) {
                        // console.log(parseFloat(serieRow.items[id].countStr))
                        serieObj.header[id].hasValues = true;
                        serieObj.hasValues = true;
                    }
                } else {
                    // console.log(ids,measureUnitIds,itemMeasureUnitId, series?.[i]?.items?.[j]?.measureUnitOption, series?.[i]?.items?.[j])
                }
            }
            // add to serie items
            serieObj.serieRows.push(serieRow);
        }
        if(serieObj.serieRows.length === 0) {
            let serieRow = createEmptySerieRow(ids, defaultMeasureUnitOptionIds, defaultMeasureUnitOptionMultiplier, undefined, undefined, defaultMeasureUnitOptionUnitCode);
            serieObj.serieRowsCount++;
            serieObj.serieRows.push(serieRow);
        }
        return checkSeriesTheSame(serieObj)
    } else {
        // empty series (without any items or items)
        return serieObj;
    }
}

// const defaultSerie = (measureUnitGroup) => {
//     if(!measureUnitGroup || !measureUnitGroup?.items) {
//         return {
//             header: [],
//             items: [],
//         };
//     }
//     const header = measureUnitGroup.items.map(
//         ({ id, measureUnit, orderNo }) => ({
//             id,
//             orderNo,
//             name: measureUnit?.name,
//         })
//     );
//     return {
//         header,
//         items: [],
//     };
// }
