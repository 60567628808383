import { takeLatest, call, put} from "redux-saga/effects";
import { REQUEST_USER, setUser, AUTH_SUCCESS, setAuthToken } from "app/redux/auth/actionTypes";
import { fetchLoggedUserPromise } from "app/core/api/auth/auth";


function* fetchUserData(action) {
    if (action.payload) yield put(setAuthToken(action.payload));
    try {
        const response = yield call(fetchLoggedUserPromise);
        const { data } = response;
        const userData = {
            ...data,
            ...(action?.role != null && {
                isFirstLoginPlayer: !!action?.role,
                isFirstLoginTrainer: action?.role === 'trainers',
            }),
        };
        if (window?.dataLayer?.push && data?.data?.id) {
            window.dataLayer.push({
                'userId': data.data.id
            })
        }
        yield put(setUser(userData));
    } catch(err) {
        // console.log(err);//TODO: add error message // logout user(?)
    }
}

export function* authSaga() {
    yield takeLatest(REQUEST_USER, fetchUserData);
    yield takeLatest(AUTH_SUCCESS, fetchUserData);
}
