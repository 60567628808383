import {storeTrainingToApi} from "app/configurator/utils/utilsApi";
import {SaveConfiguratorTraining} from "app/core/api/trainer/configurator";
import {dispatchTrainingForceUpdateFromApi} from "app/redux/configurator/actions/actions.2.Training";
import {useDispatch, useSelector} from "react-redux";
import {selectAllExercises, selectAllSections} from "app/configurator/utils/utilsReduxUseSelector";
import {useConfiguratorType} from "app/configurator/contex/configuratorContext";
import {CONFIGURATOR_SINGLE_TRAINING} from "app/config/configurator";
import {useSnackbar} from "notistack";

       const DISPLAY_TYPE_UNKNOWN = 0;
export const DISPLAY_TYPE_EXERCISES = 1;
export const DISPLAY_TYPE_DESCRIPTION = 2;

const allowedTrainingKeys = ['name', 'description', 'displayType'];

export const isTrainingKeyAllowedToEdit = (key) => {
    return allowedTrainingKeys.indexOf(key) !== -1;
}

export const createTrainingObj = ({
    id = null,
    name = 'Trening 1',
    dayNo = null,
    description = '',
    trainingIndex = null,
    displayType = DISPLAY_TYPE_EXERCISES,
}={}) => ({
    trainingIndex: trainingIndex ?? id,
    id,
    name,
    description,
    dayNo,
    displayType: (
        displayType===DISPLAY_TYPE_UNKNOWN
        || (
            typeof displayType!=='number'
                ? DISPLAY_TYPE_EXERCISES
                : displayType
        )
    ),
    areSectionsCollapsed: false,
    sections: [],
});

export const useForceSaveTraining = (trainingPlanId, moduleId, isSaving, setIsSaving) => {

    const sections = useSelector(selectAllSections());
    const exercises = useSelector(selectAllExercises());
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();
    const type = useConfiguratorType();

    return async (training, success=()=>{}, error=()=>{}) => {
        const trainingIndex = training?.trainingIndex;
        if (isSaving) return;

        setIsSaving(true);
        const objToApi = storeTrainingToApi(training, sections, exercises);
        try {
            const response = await SaveConfiguratorTraining(type, objToApi, trainingPlanId, moduleId, training?.dayNo);
            setIsSaving(false);
            success(response);
            if(type===CONFIGURATOR_SINGLE_TRAINING) return;
            if(response.data?.warningMessage) {
                enqueueSnackbar(response.data.warningMessage, { variant: 'warning'});
            }
            dispatch(dispatchTrainingForceUpdateFromApi(training?.dayNo, trainingIndex, response.data));
        } catch (e) {
            console.error(e)
            setIsSaving(false);
            error(e);
        }
    };
}