export const AUTH_SUCCESS = 'auth.user.success';
export const SET_AUTH_TOKEN = 'auth.user.setToken';
export const LOGOUT = 'auth.user.logout';
export const PASSWORD_RESET = 'auth.user.password.reset';//TODO: add password reset
export const SET_USER = 'auth.user.loaded';
export const REQUEST_USER = 'auth.user.requestMe';
export const CLOSE_TRAINER_WELCOME = 'trainer.welcome.close';
export const CLOSE_PLAYER_WELCOME = 'player.welcome.close';
export const DEBUG_OPEN_WELCOME = 'debug.welcome.open'; // TODO: remove after testing

export const actionLogout = (error= undefined) => ({
    type: LOGOUT,
    payload: error,
});

export const authSuccess = (authToken, role) => ({
    type: AUTH_SUCCESS,
    payload: authToken,
    role,
});

export const setAuthToken = (authToken) => ({
    type: SET_AUTH_TOKEN,
    payload: authToken,
});

export const setUser = (data) => ({
   type: SET_USER,
   payload: data,
});

export const requestUserMe = () => ({
   type: REQUEST_USER,
});

export const closeTrainerWelcome = {
    type: CLOSE_TRAINER_WELCOME,
}

export const closePlayerWelcome = {
    type: CLOSE_PLAYER_WELCOME,
}
