import {reducerError} from "app/redux/configurator/reducers/reducerError";

export const reducerExerciseToggleCollapse = (state, { sectionIndex, exerciseIndex }) => {
    if(!state.sections[sectionIndex] || !state.exercises[sectionIndex]){
        return reducerError(state, 'exercise.toggleCollapse.sectionNotExist');
    }
    if(!state.exercises[sectionIndex].exercises[exerciseIndex]){
        return reducerError(state, 'exercise.toggleCollapse.exerciseNotExist');
    }
    return {
        ...state,
        exercises: {
            ...state.exercises,
            [sectionIndex]: {
                ...state.exercises[sectionIndex],
                exercises: [
                    ...state.exercises[sectionIndex].exercises.slice(0, exerciseIndex),
                    {
                        ...state.exercises[sectionIndex].exercises[exerciseIndex],
                        isCollapsed: !state.exercises[sectionIndex].exercises[exerciseIndex].isCollapsed,
                    },
                    ...state.exercises[sectionIndex].exercises.slice(exerciseIndex+1),
                ]
            }
        }
    }
}