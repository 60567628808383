import {reducerCreateChat} from "app/redux/chats/reducers/chat/reducerCreateChat";
import {createChatMessage} from "app/redux/chats/utlis/createChatMessage";

export const reducerSetMessages = (state, {chat, data}, alreadyTried = false) => {
  // console.log(1)
  if(!data || !data.data || !data.pagedData) {
    return state;
  }
  // console.log(2)
  // console.log(state?.chats)
  // console.log(state?.chats?.[chat.id])
  if(!state?.chats?.[chat.id]) {
    if(alreadyTried) {
      // TODO: error
      return state;
    }
    const stateWithChat = reducerCreateChat(state, {chat});
    // console.log(stateWithChat)
    return reducerSetMessages(stateWithChat, {chat, data}, true);
  }
  // console.log(3)
  const { data:messages, pagedData } = data;
  let newMessages = {};
  messages.map(message=>{
    newMessages[message.id] = createChatMessage(message)
  });
  // console.log(4)
  return {
    ...state,
    chats: {
      ...state.chats,
      [chat.id]: {
        ...state.chats[chat.id],
        stats: {
          ...state.chats[chat.id].stats,
          isReachOldest: pagedData.pages===pagedData.pageNumber,
          messagesCount: pagedData.resultsCount,
          //TODO: oldestId, newestId
        },
      },
    },
    chatMessages: {
      ...state.chatMessages,
      [chat.id]: {
        ...state.chatMessages[chat.id],
        ...newMessages,
      }
    }
  };
}
