import React from 'react';
import styled, { css } from "styled-components";

const ImageContainer = styled.div`
    max-width: ${({maxWidth = 200}) => maxWidth}px;
    height: auto;
`;
const StyledSymbolImage = styled.img`
    width: 100%;
    display: block;
    ${({isCircle}) => isCircle && css`
        object-fit: cover;
        width: ${({size}) => size || 1}px;
        height: ${({size}) => size || 1}px;
        border-radius: 50% !important;
    `}
    ${({fixedSize}) => fixedSize && css`
        width: ${({size}) => size ?? 1}px !important;        
    `}
`;


const SymbolImage = ({ isCircle, src, fixedSize, alt, maxWidth, classNameOuter='', children, ...props } ) => (
    <ImageContainer className={classNameOuter} maxWidth={maxWidth}>
        <StyledSymbolImage
            fixedSize={fixedSize}
            isCircle={isCircle}
            size={maxWidth}
            className="rounded"
            src={src}
            alt={alt}
            {...props}
        />
        {children}
    </ImageContainer>
);

// export const SymbolCircleImage = ({})
export default SymbolImage;
