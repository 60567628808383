
export const moveExerciseSuperserie = (prev, orig, next) => {

    const prevSuperserieIndex = prev ? prev?.orderNo-(Math.floor(prev?.orderNo/10)*10) : 0
    const nextSuperserieIndex = next ? next?.orderNo-(Math.floor(next?.orderNo/10)*10) : 0

    if(isSameBaseNo(prev, next)) {
        orig._superseries.isLast = false;
        orig.orderNo = prev.orderNo;
    } else if(prev && isSameBaseNo(prev, orig)) {
        prev._superseries.isLast = false;
        if(prevSuperserieIndex===0) {
            prev.orderNo+=1;
        }
        orig.orderNo = prev.orderNo;
        orig._superseries.isLast = true;
    } else if(next && isSameBaseNo(orig, next)) {
        orig._superseries.isLast = false;
        if(nextSuperserieIndex===0) {
            next.orderNo+=1;
        }
        orig.orderNo = next.orderNo;
    } else {
        const orderNoToRecalculate = prev ? prev.orderNo+10 : (next ? next.orderNo : 0);
        orig.orderNo = Math.floor(orderNoToRecalculate/10)*10;
    }

    return [prev, orig, next];
};

const isSameBaseNo = (a, b) => {
    return Math.floor(a?.orderNo/10) === Math.floor(b?.orderNo/10) && a.orderNo!=null;
}