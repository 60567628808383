import {prefix} from "app/config/routes/tp/_cfg";

export const routeTPSurveys = {
    'tp.surveys':                                           `${prefix}/surveys`,
    'tp.surveys.traineron':                                 `${prefix}/surveys/traineron`,
    //
    'tp.survey':                (id = ':surveyId') => `${prefix}/surveys/${id}`,
    'tp.survey.edit':           (id = ':surveyId') => `${prefix}/surveys/${id}/edit`,
    'tp.survey.create':                                      `${prefix}/surveys/create`,
}
