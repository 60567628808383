import React, {useEffect, useMemo} from "react";
import objectPath from "object-path";
// LayoutContext
import {useHtmlClassService} from "..";
// Import Layout components
import {Header} from "./header/Header";
import {HeaderMobile} from "./header-mobile/HeaderMobile";
import {Aside} from "./aside/Aside";
import {LayoutInit} from "./LayoutInit";
import {SubHeader} from "./subheader/SubHeader";
import {ScrollTop} from "./extras/ScrollTop";
import I_Footer from "templates/includes/I_Footer";
import {useSelector} from "react-redux";
import {
    LAYOUT_MODE_CLUB,
    LAYOUT_MODE_PLAYER,
    LAYOUT_MODE_PUBLIC,
    LAYOUT_MODE_TRAINER
} from "app/redux/app/appActionTypes";
import {BreadcrumbProvider} from "app/providers/BreadcrumbProvider";

export function Layout({ children, isSubheader }) {
    const uiService = useHtmlClassService();
    const {layoutMode} = useSelector(({app})=>app.layout)
    const isAside = ([LAYOUT_MODE_PLAYER, LAYOUT_MODE_TRAINER, LAYOUT_MODE_CLUB].includes(layoutMode));
    // Layout settings (cssClasses/cssAttributes)
    const layoutProps = useMemo(() => {
        return {
            layoutConfig: uiService.config,
            selfLayout: objectPath.get(uiService.config, "self.layout"),
            asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
            subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
            desktopHeaderDisplay: objectPath.get(
                uiService.config,
                "header.self.fixed.desktop"
            ),
            contentCssClasses: uiService.getClasses("content", true),
            contentContainerClasses: uiService.getClasses("content_container", true),
            contentExtended: objectPath.get(uiService.config, "content.extended")
        };
    }, [uiService]);

    useEffect(() => {
        if (!isAside) document.body.classList.remove('aside-enabled', 'aside-fixed');
        else document.body.classList.add('aside-enabled', 'aside-fixed');
    }, [isAside]);


    return layoutProps.selfLayout !== "blank" ? (
        <>
            {/*begin::Main*/}
            <HeaderMobile isAside={isAside}/>
            <div className={`d-flex flex-column flex-root${isAside ? ' aside-enabled subheader-fixed' : ''}`}>
                {/*begin::Page*/}
                <div className="d-flex flex-row flex-column-fluid page">
                    {isAside && <Aside />}
                    {/*begin::Wrapper*/}
                    <div className="position-relative d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                        <Header showMenu={!isAside} />
                        {/*begin::Content*/}
                        <div
                            id="kt_content"
                            className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid pb-0 position-relative`}
                        >
                            {isSubheader && <SubHeader />}
                            <BreadcrumbProvider>
                                {children}
                            </BreadcrumbProvider>
                        </div>
                        {/*end::Content*/}
                        {layoutMode === LAYOUT_MODE_PUBLIC && <I_Footer />}
                    </div>
                    {/*end::Wrapper*/}
                </div>
                {/*end::Page*/}
            </div>
            {/*<QuickUser/> - this is right side menu*/}
            {/*<QuickPanel/>*/}
            <ScrollTop/>
            {/*end::Main*/}
            <LayoutInit />
        </>
    ) : (
        // BLANK LAYOUT
        <div className="d-flex flex-column flex-root">{children}</div>
    );
}
