import React, {useContext, useEffect, useMemo} from "react";
import useObjectState from "app/hooks/useObjectState";
import {NavLink} from "react-router-dom";
import CondLink from "app/core/router/components/CondLink";
import {useSelector} from "react-redux";
import {
    LAYOUT_MODE_CLUB,
    LAYOUT_MODE_PLAYER,
    LAYOUT_MODE_PUBLIC,
    LAYOUT_MODE_TRAINER
} from "app/redux/app/appActionTypes";
import {route} from "app/config/routes/route";

const Breadcrumb = ({
    homeUrl,
    items,
    buttons,
}) => {
    return (
        <div className="subheader py-2 py-lg-4 subheader-solid">
            <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                <div className="d-flex align-items-center flex-wrap mr-1">
                    <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2">
                        <li className="breadcrumb-item">
                            {homeUrl && (
                                <NavLink to={homeUrl}>
                                    <i className="fas fa-home text-muted" />
                                </NavLink>
                            )}
                        </li>
                        {items && items.map(({ url, name, key }) => (
                            <li key={url ?? key} className="breadcrumb-item text-muted break-word">
                                <CondLink to={url}>
                                    {name}
                                </CondLink>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="d-flex align-items-center">
                    {buttons}
                </div>
            </div>
        </div>
    );
};


const defaultValue = () => ({items:[], buttons:null});
const BreadcrumbContext = React.createContext();

export const BreadcrumbProvider = ({children}) => {
    const {layoutMode} = useSelector(({app})=>app.layout)
    const [{ items, buttons }, setState] = useObjectState(defaultValue());
    const homeUrl = useMemo(() => {
        switch (layoutMode){
            case LAYOUT_MODE_TRAINER:
                return route['tp'];
            case LAYOUT_MODE_PLAYER:
                return route['pp'];
            case LAYOUT_MODE_CLUB:
                return route['cp'];
            case LAYOUT_MODE_PUBLIC:
            default:
                return route['/'];
        }
    }, [layoutMode]);
    return (
        <BreadcrumbContext.Provider value={[setState, buttons, items]}>
            {layoutMode!== LAYOUT_MODE_PUBLIC && (
                <Breadcrumb
                    items={items}
                    buttons={buttons}
                    homeUrl={homeUrl}
                />
            )}
            {children}
        </BreadcrumbContext.Provider>
    );
};

export const useBreadcrumb = (itemsToSet=null, buttonsToSet=null) => {
    const [setState, buttons, items] = useContext(BreadcrumbContext);
    //
    useEffect(()=>{
        let newObj = {};
        if(itemsToSet) newObj.items = itemsToSet;
        if(buttonsToSet !== true) newObj.buttons = buttonsToSet;
        setState(newObj);
    }, []);
    //
    return {
        setBreadcrumb:     setState,
        breadcrumbButtons: buttons,
        breadcrumbItems:   items,
    };
}
export const useSafeBreadcrumb = () => {
    const [setState, buttons, items] = useContext(BreadcrumbContext);
    //
    return {
        setBreadcrumb:     setState,
        breadcrumbButtons: buttons,
        breadcrumbItems:   items,
    };
}
