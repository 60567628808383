import {reducerError} from "app/redux/configurator/reducers/reducerError";
import {unsavedTrainings} from "app/redux/configurator/utils/configuratorHelpers";
import {DELETED} from "components/form/enums/changeStatus";
import {reorderNo} from "app/configurator/utils/superseries/reorderNo";

export const reducerExerciseDelete = (state, { sectionIndex, exerciseIndex }) => {
    if(!state.exercises?.[sectionIndex]?.exercises?.[exerciseIndex]) {
        return reducerError(state, 'exercise.delete.exerciseNotExist');
    }
    let deleted = [];
    if(!!state.exercises[sectionIndex].exercises[exerciseIndex].id) {
        // do nothing if exercise not exist in database
        deleted.push({
            ...state.exercises[sectionIndex].exercises[exerciseIndex],
            changeStatus: DELETED,
        });
    }
    const exercisesToReorder = [
        ...state.exercises[sectionIndex].exercises.slice(0, exerciseIndex),
        // delete from there
        ...state.exercises[sectionIndex].exercises.slice(exerciseIndex+1), //rest of array or empty []
    ];
    const exercises = reorderNo(exercisesToReorder);
    return {
        ...state,
        exercises: {
            ...state.exercises,
            [sectionIndex]: {
                exercises,
                deleted: [
                    ...state.exercises[sectionIndex].deleted,
                    ...deleted, // put deleted here or delete (this is why i use this helper variable)
                ],
            }
        },
        helpers: {
            ...state.helpers,
            unsavedTrainings: unsavedTrainings(state.helpers).add([state.sections[sectionIndex].trainingIndex]),
        },
    }
}