
import {useCallback, useEffect} from "react";
import {useSignalREvent} from "app/hooks/signalR/useSignalrEvent";
import {useSignalRConnections} from "app/providers/signalR/SignalRProvider";
import {
  HUB_MESSAGES_RECEIVE_CONVERSATION_MESSAGE,
  HUB_MESSAGES_SEND_CONVERSATION_MESSAGE,
  hubMessagesSendConversationMessage
} from "app/providers/signalR/hubs/messagesHub";


export const useMessagesHub = () => {

  // check if connection exists
  const connections = useSignalRConnections();
  if(!connections?.current?.messagesHub) {
    throw new Error('Missing SignalRProvider');
  }
  useEffect(() => {
    if (connections.current.messagesHub.connectionState === 'Disconnected') {
      connections.current.messagesHub.start().then(() => console.log('connection started'));
    }
  }, []);

  // send message helper
  const sendConversationMessage = useCallback(async(chatId=27, message='') => {
    try {
      await connections.current.messagesHub.invoke(
        HUB_MESSAGES_SEND_CONVERSATION_MESSAGE,
        chatId,
        hubMessagesSendConversationMessage(message)
      );
    } catch(e) {
      // toast.error('Nie udało się wysłac wiadomości. Błąd wewnętrzny serwera.');
    }
  }, []);

  return [
    connections.current.messagesHub, // connection
    { // helpers:
      sendConversationMessage,
    }
  ];
}

// getMessage observer
export const useMessagesHubReceiveMessage = (messagesConnection, callback) => {
  return useSignalREvent( messagesConnection, HUB_MESSAGES_RECEIVE_CONVERSATION_MESSAGE, callback );
}
