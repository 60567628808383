import React from "react";

export const debugLog = (log) => {
    if (process.env.NODE_ENV === 'development') {
        // console.log(log)
    }
}
export const debugWarn = (warn) => {
    if (process.env.NODE_ENV === 'development') {
        console.warn(warn)
    }
}