import {reducerError} from "app/redux/configurator/reducers/reducerError";
import {createDayObj} from "app/configurator/utils/utilsDay";

export const reducerDayCreate = (state, { dayNo }) => {
    if(isNaN(dayNo)) {
        return reducerError(state, 'day.create.dayNoIsNaN');
    }
    if(state.days[dayNo]) {
        return reducerError(state, 'day.create.dayAlreadyExist');
    }
    return {
        ...state,
        days: {
            ...state.days,
            [dayNo]: createDayObj({dayNo}),
        }
    }
}