import { format as format_, parseISO, differenceInMonths } from "date-fns";
import moment from "moment";

/*

*  TODO
*  FIXME
*  TODO
*
*  This file contains many issues. Use "./date.js" instead
*
*  FIXME
*  TODO
*  FIXME
*
* */



export const momentObj = (date) => {
    if(moment(date).isValid()) return moment(date);
    return moment();
}

export const momentObjTimestamp = (date) => {
    if(moment(date).isValid()) return moment(date);
    return null;
}


export const parse = (date, showError = false) => {
    try {
        const parsedISO = parseISO(date);
        // const parsed = parse(date);
        // console.log(parsedISO, date)
        // noinspection EqualityComparisonWithCoercionJS,JSIncompatibleTypesComparison
        if(parsedISO == 'Invalid Date') {
            throw 'invalid date format';
        }
        return parsedISO;
    } catch (e) {
        if(showError) console.error('Invalid date format');
        return null;
    }
}

export const formatDatetime = (date, format) => {
    if(!date) return null;
    const parsed = parse(date)
    if(!parsed) return parsed;
    return format_(parsed ,format);
} //TODO: add validation

// yearMonthDuration
export const ymDuration = (date, dangerousOverwriteToday = moment()) => {
    if(!date) return null;
    try {
        const compared = momentObj(date);
        //
        // console.log(compared, dangerousOverwriteToday)
        const diff = dangerousOverwriteToday.diff(compared, 'months', true);
        // console.log(diff)
        // console.log(diff)
        if(diff <= 0) return null;
        // split date
        const years = Math.floor(diff/12);
        const months = Math.ceil(years ? diff-(12*years) : diff);
        // add limit
        if(years > 90) return null;
        //
        return [years, months];
    } catch {
        return null;
    }
}

export const formatYear = ['rok', 'lata', 'lat'];
export const formatMonth = ['miesiąc', 'miesiące', 'miesięcy'];

export const months = ['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień'];

export const weekdays = ['niedziela', 'poniedziałek', 'wtorek', 'środa', 'czwartek', 'piątek', 'sobota'];

export const getDaysInMonth = (month,year, startWithZero = true) => {
    return new Date(year, month+(startWithZero?1:0), 0).getDate();
};

export const formatTime = (seconds = 0) => {
    const s = seconds%60;
    const m = Math.floor(seconds/60);
    return `${m}:${s<10?'0':''}${s} min`;
}

export const translatedUnit = (unit) => ({
    year: 'rok',
    month: 'miesiąc',
    week: 'tydzień',
    day: 'dzień',
    hour: 'godzina',
    minute: 'minuta',
    second: 'sekunda',
    now: 'teraz',
})[unit] ?? '';

export const translatedUnitShort = (unit) => ({
    year: 'lat',
    month: 'msc',
    week: 'tyg',
    day: 'd',
    hour: 'h',
    minute: 'm',
    second: 's',
    now: 'teraz',
})[unit] ?? '';

export const largestDiffString = (dateA, dateB) => {
    const a = moment(dateA)
    const b = dateB ? moment(dateB) : moment();
    for (const unit of ['year', 'month', 'week', 'day', 'hour', 'minute']) {
        const diff = b.diff(a, unit);
        if (diff >= 1) return { diff, unit };
    }
    return { diff: 0, unit: 'now' };
}
