import React from 'react';
import {Switch} from "react-router-dom";

export const AppSwitch = ({children, outer:Outer}) => {
    return (
        Outer ? (
            <Outer>
                <Switch>
                    {children}
                </Switch>
            </Outer>
        ) : (
            <Switch>
                {children}
            </Switch>
        )
    );
};