import styled from "styled-components";

const Dot = styled.div`
    width: ${({ size }) => size || '0.5em'};
    height: ${({ size }) => size || '0.5em'};
    margin-right: 0.35em;
    border-radius: 50%;
    background-color: ${({ color }) => color};
`;

export default Dot;
