import {
    TRAINING_ADD_FROM_TEMPLATE,
    TRAINING_CREATE, TRAINING_DELETE,
    TRAINING_EDIT_BY_KEY,
    TRAINING_SET_MODAL_ID, TRAINING_TOGGLE_IS_UPDATING, TRAINING_UPDATE_FORCE_FROM_API
} from "app/redux/configurator/configuratorTypes";
import {TRAINING_TOGGLE_COLLAPSE} from "app/redux/configurator/configuratorTypes";


export const dispatchTrainingCreate = (dayNo, name) => ({
    type: TRAINING_CREATE,
    payload: {
        dayNo,
        name,
    },
});

export const dispatchTrainingAddFromTemplate = (dayNo, training) => ({
    type: TRAINING_ADD_FROM_TEMPLATE,
    payload: {
        dayNo,
        training,
    },
});

export const dispatchTrainingEditProperty = (trainingIndex, property, newValue) => ({
    type: TRAINING_EDIT_BY_KEY,
    payload: {
        trainingIndex,
        property,
        newValue,
    },
});

export const dispatchTrainingToggleCollapse = (trainingIndex) => ({
    type: TRAINING_TOGGLE_COLLAPSE,
    payload: {
        trainingIndex,
    },
});

export const dispatchTrainingOpenModal = ( dayNo = null,trainingIndex = null ) => ({
    type: TRAINING_SET_MODAL_ID,
    payload: {
        trainingIndex,
        dayNo,
    },
});

export const dispatchTrainingDelete = ( dayNo = null,trainingIndex = null, force = false ) => ({
    type: TRAINING_DELETE,
    payload: {
        trainingIndex,
        dayNo,
        force,
    },
});

export const dispatchTrainingForceUpdateFromApi = (dayNo, trainingIndex, data, oldIndex) => ({
    type: TRAINING_UPDATE_FORCE_FROM_API,
    payload: {
        dayNo,
        trainingIndex,
        data,
        oldIndex,
    },
});

export const dispatchTrainingSetIsUpdating = (isUpdating) => ({
    type: TRAINING_TOGGLE_IS_UPDATING,
    payload: {
        isUpdating,
    },
});