import React from 'react';
import {AppSwitch} from "app/core/router/components/AppSwitch";
import {AppRoute} from "app/core/router/components/AppRoute";
import {route} from "app/config/routes/route";
import {defaultTitle} from "app/config/seo";

const Articles = React.lazy(() => import('views/pages/public/articles/Articles'));
const Article = React.lazy(() => import('views/pages/public/articles/Article'));

export const ArticlesRoutes = () => {
    return (
        <AppSwitch>
            <AppRoute
                path={route['p.articles']}
                exact
                component={Articles}
                meta={{
                    title: defaultTitle('Artykuły'),
                }}
            />
            <AppRoute
                exact
                path={route['p.article']()}
                component={Article}
                meta={{
                    title: defaultTitle('Artykuł'),
                }}
            />
        </AppSwitch>
    );
};
