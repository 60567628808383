import React from 'react';
import {AppSwitch} from "app/core/router/components/AppSwitch";
import {AppRoute} from "app/core/router/components/AppRoute";
import {route} from "app/config/routes/route";
import {defaultTitle} from "app/config/seo";

const TrainingPlans = React.lazy(() => import('views/pages/public/trainingPlans/View_TrainingPlans/View_TrainingPlans'));
const TrainingPlan  = React.lazy(() => import('views/pages/public/trainingPlans/View_TrainingPlan/View_TrainingPlan'));
const BuyOffer  = React.lazy(() => import('views/pages/public/payment/BuyOffer'));

export const TrainingPlansRoutes = () => {
    return (
        <AppSwitch>
            <AppRoute
                exact
                path={route['p.trainingPlans']}
                component={TrainingPlans}
                meta={{
                    title: defaultTitle('Plany treningowe'),
                }}
            />
            <AppRoute
                exact
                path={route['p.trainingPlan.buy.offer']()}
                component={BuyOffer}
                meta={{
                    title: defaultTitle('Kupno planu treningowego'),
                }}
            />`
            <AppRoute
                exact
                path={[
                    route['p.trainingPlan'](),
                    route['p.trainingPlan.buy'](),
                    route['p.trainingPlan.try'](),
                ]}
                component={TrainingPlan}
                meta={{
                    title: defaultTitle('Plan treningowy'),
                }}
            />
        </AppSwitch>
    );
};
