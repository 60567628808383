import React, { useEffect } from 'react';
import useObjectState from "app/hooks/useObjectState";

const GAPI_CONFIG = {
    clientId: process.env.REACT_APP_GAPI_ID,
    scope: ''
};
const FB_CONFIG = {
    appId      : process.env.REACT_APP_FB_ID,
    cookie     : true,
    xfbml      : true,
    version    : 'v8.0'
};

const INITIAL_STATE = { google: null, facebook: null };
export const SocialLoginContext = React.createContext(INITIAL_STATE);

const SocialLoginProvider = ({ children }) => {
    const [state, setState] = useObjectState(INITIAL_STATE);
    // console.log(state);

    const setSignedIn = (key, value) =>
        setState({
            [key]: {
                ...state[key],
                isSignedIn: value,
            },
        });

    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = 'https://apis.google.com/js/client.js';
    //     script.defer = true;
    //     script.async = true;
    //
    //     script.onload = () => {
    //         window.gapi.load('auth2', async () => {
    //             try {
    //                 await window.gapi.auth2.init(GAPI_CONFIG);
    //                 const auth = await window.gapi.auth2.getAuthInstance();
    //                 auth.isSignedIn.listen((value) => setSignedIn('google', value));
    //
    //                 setState({
    //                     google: {
    //                         isSignedIn: auth.isSignedIn.get(),
    //                         signIn: async () => {
    //                             const auth = await window.gapi.auth2.getAuthInstance();
    //                             await auth.signIn();
    //                             return auth.currentUser.get().getAuthResponse();
    //                         },
    //                         signOut: async () => {
    //                             const auth = await window.gapi.auth2.getAuthInstance();
    //                             await auth.signOut();
    //                         },
    //                     },
    //                 });
    //             } catch (e) {
    //                 console.error(e)
    //             }
    //         });
    //     }
    //
    //     document.head.appendChild(script);
    // }, []);

    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = 'https://connect.facebook.net/pl_PL/sdk.js';
    //     script.defer = true;
    //     script.async = true;
    //
    //     script.onload = () => {
    //         window.FB.init(FB_CONFIG);
    //
    //         window.FB.getLoginStatus((response) => {
    //             window.FB.Event.subscribe('auth.statusChange', ({ status }) => {
    //                 setSignedIn('facebook', status === 'connected');
    //             })
    //             setState({
    //                 facebook: {
    //                     isSignedIn: response.status === 'connected',
    //
    //                     signIn: () => new Promise((resolve, reject) => {
    //                         window.FB.init(FB_CONFIG);
    //                         window.FB.login((response) => response.status === 'connected'
    //                             ? resolve(response)
    //                             : reject(response))
    //                     }),
    //
    //                     signOut: () => new Promise((resolve, reject) => {
    //                         window.FB.logout((response) => resolve(response))
    //                     }),
    //                 },
    //             });
    //         });
    //     }
    //
    //     document.head.appendChild(script);
    // }, []);

    return (
        <SocialLoginContext.Provider value={state}>
            {children}
        </SocialLoginContext.Provider>
    );
};

export default SocialLoginProvider;
