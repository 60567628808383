import {createSectionObj} from "app/configurator/utils/utilsSection";
import {
    createExerciseObj,
    createReducerExerciseObj
} from "app/configurator/utils/utilsExercise";
import {NO_CHANGES} from "components/form/enums/changeStatus";
import {createTrainingObj} from "app/configurator/utils/utilsTraining";
import {unsavedTrainings} from "app/redux/configurator/utils/configuratorHelpers";
import {reorderNo} from "app/configurator/utils/superseries/reorderNo";
import {parseSeries} from "app/redux/configurator/reducers/0.configurator/helpers/parseSeries";
import {getTestExercise} from "app/redux/configurator/reducers/4.exercise/reducerExerciseSetTestExercise";

export const reducerTrainingForceUpdateFromApi = (state, { dayNo, trainingIndex, data }) => {
    const { trainings, sections, exercises } = state;
    const training = trainings[trainingIndex];
    const newTrainingIndex = data?.id;

    let newTrainings = {...trainings};
    delete newTrainings[trainingIndex];//delete old
    let newSections = {};
    let newExercises = {};

    if(!training) {
        newSections = {...sections};
        newExercises = {...exercises};
    } else {
        Object.keys(sections).forEach(sectionIndex => {
            if(training.sections.indexOf(sectionIndex) === -1) {// delete all sections from old training
                newSections[sectionIndex] = {...sections[sectionIndex]};
                newExercises[sectionIndex] = {...exercises[sectionIndex]};
            }
        });
    }
    const {id, name, description, trDisplayType: displayType } = data;
    const newTraining = createTrainingObj({ id, name, description, dayNo, newTrainingIndex, displayType });

    // get new data
    if(!data || !data?.sections || !data?.sections?.length) {
        // console.log('training without sections');
    } else {
        let sectionsWithoutOrder = [];
        const sectionsToParse = data.sections.sort((a,b)=>a?.sectionNo>b?.sectionNo ? 1 : -1);
        for(const section of sectionsToParse) {
            if(!section) continue; //TODO: add error message && more validation
            const { id, name } = section;
            //
            const sectionIndex = id;
            sectionsWithoutOrder.push([sectionIndex, section?.sectionNo])
            // create sectionObj && exerciseList
            const newSection = createSectionObj({ ...section, id, name, trainingIndex });
            const newExerciseList = createReducerExerciseObj();
            // get exercises
            const exercises = section?.exercises || [];
            // process exercises
            for(const exercise of exercises) {
                if(!exercise) continue; //TODO: add error message && more validation
                const {exercise:originalExercise, series: seriesToParse, testExercise, ...exerciseParams } = exercise;
                const {
                    id:exerciseId,
                    name:_name,
                    nameENG:_nameENG,
                    photoUrl:_photoUrl,
                    measureUnitGroup,
                    hasTestExercises,
                } = originalExercise || {};
                const series = parseSeries(measureUnitGroup, seriesToParse);
                const changeStatus = NO_CHANGES;
                const newExercise = createExerciseObj({
                    exerciseId,
                    _name,
                    _nameENG,
                    f: {},
                    _photoUrl,
                    series,
                    hasTestExercises,
                    testExercise: getTestExercise(testExercise),
                    changeStatus,
                    ...exerciseParams,
                });
                newExerciseList.exercises.push(newExercise);
            }
            newExerciseList.exercises = reorderNo(newExerciseList.exercises);
            // add section & exerciseList to globals
            newSections[sectionIndex] = {...newSection};
            newExercises[sectionIndex] = {...newExerciseList};
        }
        const sectionsOrdered = sectionsWithoutOrder.sort(([,a],[,b]) => a>b);
        newTraining.sections = sectionsOrdered?.map(([a])=>a);
    }

    return {
        ...state,
        days: {
            ...state.days,
            [dayNo]: {
                ...state.days[dayNo],
                trainings: [
                    ...state.days[dayNo].trainings.filter(id=>id!==trainingIndex),
                    newTrainingIndex,
                ],
          },
        },
        trainings: {
            ...newTrainings,
            [newTrainingIndex]: newTraining,
        },
        sections: newSections,
        exercises: newExercises,
        helpers: {
            ...state.helpers,
            unsavedTrainings: unsavedTrainings(state.helpers).remove(trainingIndex),
        },
    }
}
