import {errorHandler} from "app/core/errorHandler";
import {CUT, DAY} from "app/redux/configurator/configuratorTypes";
import {createTrainingObj} from "app/configurator/utils/utilsTraining";
import {createSectionObj} from "app/configurator/utils/utilsSection";
import {createExerciseObj, createReducerExerciseObj} from "app/configurator/utils/utilsExercise";

export const reducerClipboardDayPaste = (state, {dayNo}) => {

    const dayTrainings = state.days[dayNo]?.trainings;
    const dayType = state.days[dayNo]?.type;
    const clipboard = state.helpers.clipboard;

    // clipboard must exist and scope===DAY
    if(!clipboard || clipboard.scope !== DAY) {
        errorHandler({
            expected: 'clipboard != null && type===DAY',
            got: !state.helpers.clipboard ? 'empty clipboard' : 'clipboard scope !== DAY',
            details: 'user trying to paste DAY but clipboard not exist',
            file: 'reducerClipboardDayPaste',
            json: state.helpers.clipboard,
        });
        return state;
    }
    // dayType cannot have any trainings
    if(dayTrainings && dayTrainings.length > 0) {
        errorHandler({
            expected: 'dayTrainings.length === 0 OR undefined',
            got: 'dayTrainings.length>0',
            details: 'user trying to paste DAY that have some trainings',
            file: 'reducerClipboardDayPaste',
            json: state.days[dayNo],
        });
        return state;
    }

    let dataToSet = {
        trainings: {},
        sections: {},
        exercises: {},
        itemsCount: state.helpers.itemsCount,
        unsavedTrainings: [],
    };
    const getItemsCount = () => {
        dataToSet.itemsCount++;
        return `c${dataToSet.itemsCount}`;
    }
    const trainingsToCopy = state.days[clipboard.dayNo]?.trainings;
    if(trainingsToCopy && trainingsToCopy.length) {
        trainingsToCopy.forEach(trainingId => {
            const {id, areSectionCollapsed, sections:sectionsToCopy, dayNo:dayNoToDelete, ...trainingToCreate} = state.trainings[trainingId];
            const trainingIndex = getItemsCount();
            dataToSet.unsavedTrainings.push(trainingIndex);
            let training = createTrainingObj({...trainingToCreate, dayNo, trainingIndex});
            //
            if(sectionsToCopy && sectionsToCopy.length) {
                sectionsToCopy.forEach(sectionId => {
                    const {id, ...sectionToCreate} = state.sections[sectionId];
                    const sectionIndex = getItemsCount();
                    training.sections.push(sectionIndex);
                    let section = createSectionObj({...sectionToCreate, idLocal: sectionIndex});
                    let exercises = createReducerExerciseObj();
                    //
                    const exercisesToSet = state.exercises[sectionId].exercises;
                    if(exercisesToSet && exercisesToSet.length) {
                        exercisesToSet.forEach(({id, _key, series:seriesToCopy, ...exerciseToSet}) => {
                            const exerciseIndex = getItemsCount();
                            const {
                                serieRowsDeletedIds,
                                serieRows:serieRowsToCopy,
                                ...restOfSerie
                            } = seriesToCopy;
                            let serieRows = [];
                            if(serieRowsToCopy){
                                serieRows = serieRowsToCopy.map(({id, items:itemsToCopy, ...serieRow})=>{
                                    let items = {};
                                    if(itemsToCopy) {
                                        Object.keys(itemsToCopy).forEach((itemKey=>{
                                            items[itemKey] = {
                                                ...itemsToCopy[itemKey],
                                                id: null,
                                            };
                                        }));
                                    }
                                    return {
                                        id: null,
                                        items,
                                        ...serieRow,
                                    };
                                });
                            }
                            const series = {
                                serieRowsDeletedIds: [],
                                ...restOfSerie,
                                serieRows,
                            };
                            const exercise = createExerciseObj({...exerciseToSet, series, _key:exerciseIndex});
                            exercises.exercises.push(exercise);
                        });
                    }
                    dataToSet.sections[sectionIndex] = section;
                    dataToSet.exercises[sectionIndex] = exercises;

                });
            }
            dataToSet.trainings[trainingIndex] = training;
        });
    }
    return {
        ...state,
        days: {
            ...state.days,
            [dayNo]: {
                ...state.days[dayNo],
                trainings: Object.keys(dataToSet.trainings),
            },
        },
        trainings: {
            ...state.trainings,
            ...dataToSet.trainings,
        },
        sections: {
            ...state.sections,
            ...dataToSet.sections,
        },
        exercises: {
            ...state.exercises,
            ...dataToSet.exercises,
        },
        helpers: {
            ...state.helpers,
            itemsCount: dataToSet.itemsCount,
            unsavedTrainings: [
                ...state.helpers.unsavedTrainings,
                ...dataToSet.unsavedTrainings,
            ],
            clipboard: null,
        },
    };
};