import {reducerError} from "app/redux/configurator/reducers/reducerError";
import {reducerTrainingCreate} from "app/redux/configurator/reducers/2.training/reducerTrainingCreate";
import {createSectionObj} from "app/configurator/utils/utilsSection";
import {createReducerExerciseObj} from "app/configurator/utils/utilsExercise";
import {unsavedTrainings} from "app/redux/configurator/utils/configuratorHelpers";

export const reducerSectionCreate = (state, { dayNo = null, trainingIndex, name }, alreadyTriedCreateTraining = false) => {
    const newSectionId = `c${state.helpers.itemsCount}`;
    if(state.sections[newSectionId]){
        return reducerError(state, 'section.create.alreadyExist');
    }
    if(!state.trainings[trainingIndex]) {
        if(!trainingIndex && !dayNo) {
            return reducerError(state, 'section.create.trainingNotExist.dayNotExist');
        }
        if(alreadyTriedCreateTraining) {
            return reducerError(state, 'section.create.trainingNotExist.createTrainingFailed')
        }
        const stateWithNewlyCreatedTraining = reducerTrainingCreate(state, {dayNo});
        const newTrainingIndex = `${stateWithNewlyCreatedTraining.helpers.itemsCount-1}.c`;
        return reducerSectionCreate(stateWithNewlyCreatedTraining, {dayNo, trainingIndex: newTrainingIndex}, true);
    }
    return {
        ...state,
        trainings: {
            ...state.trainings,
            [trainingIndex]: {
                ...state.trainings[trainingIndex],
                areSectionsCollapsed: false,
                sections: [
                    ...state.trainings[trainingIndex].sections,
                    newSectionId,
                ],
            },
        },
        sections: {
            ...state.sections,
            [newSectionId]: createSectionObj({ name, trainingIndex, idLocal: newSectionId }),
        },
        exercises: {
            ...state.exercises,
            [newSectionId]: createReducerExerciseObj(),
        },
        helpers: {
            ...state.helpers,
            itemsCount: state.helpers.itemsCount+1,
            unsavedTrainings: unsavedTrainings(state.helpers).add([ trainingIndex ]),
        },
    }
}