import useObjectState from "./useObjectState";

const useModal = () => {
    const [ { isOpen, params }, setModal ] = useObjectState({
        isOpen: false,
        params: null,
    });


    // noinspection JSCheckFunctionSignatures
    const openModal = (params = null) => {
        setModal({
            isOpen: true,
            params,
        });
    }

    const closeModal = () => setModal({
        isOpen: false,
    });

    return [ isOpen, openModal, closeModal, params ];
}

export const useModalObj = () => {
    const [ isOpen, openModal, closeModal, params ] = useModal();

    return {
        isOpen,
        open: openModal,
        close: closeModal,
        params,
    }
}

export default useModal;