import {reducerError} from "app/redux/configurator/reducers/reducerError";
import {createEmptySerieRow} from "app/redux/configurator/reducers/5.series/helpers/createDefaultSerieHelpers";
import {unsavedTrainings} from "app/redux/configurator/utils/configuratorHelpers";


export const reducerSeriesSetCount = (state, { sectionIndex, exerciseIndex, seriesCount}) => {
    if(!state.exercises?.[sectionIndex]?.exercises?.[exerciseIndex]?.series) {
        return reducerError(state, 'exercise.edit.exerciseNotExist');
    }
    let newSerieRows = [
        ...state.exercises[sectionIndex].exercises[exerciseIndex].series.serieRows,
    ];
    if(seriesCount > newSerieRows.length) {
        // const defaultMeasureUnitOptionMultiplier = measureUnitGroup.items.map(({measureUnitOption}) => measureUnitOption?.conversionBaseValue);
        const defaultMeasureOptions = state.exercises[sectionIndex].exercises[exerciseIndex].series.headerOrder ? (
            state.exercises[sectionIndex].exercises[exerciseIndex].series.headerOrder.map(
                id => state.exercises[sectionIndex].exercises[exerciseIndex].series.header[id].defaultMeasureUnitOption
            )
        ) : (
            []
        );
        const defaultMeasureOptionsMultiplier = state.exercises[sectionIndex].exercises[exerciseIndex].series.headerOrder ? (
            state.exercises[sectionIndex].exercises[exerciseIndex].series.headerOrder.map(
                id => state.exercises[sectionIndex].exercises[exerciseIndex].series.header[id].defaultMultiplier
            )
        ) : (
            []
        );
        const defaultMeasureOptionsUnitCode = state.exercises[sectionIndex].exercises[exerciseIndex].series.headerOrder ? (
            state.exercises[sectionIndex].exercises[exerciseIndex].series.headerOrder.map(
                id => state.exercises[sectionIndex].exercises[exerciseIndex].series.header[id].defaultUnitCode
            )
        ) : (
            []
        );
        for(let i=newSerieRows.length; i<seriesCount; i++){
            // console.log(state.exercises[sectionIndex].exercises[exerciseIndex].series)
            newSerieRows.push(
                createEmptySerieRow(
                    state.exercises[sectionIndex].exercises[exerciseIndex].series.headerOrder,
                    defaultMeasureOptions,
                    defaultMeasureOptionsMultiplier,
                    undefined,
                    undefined,
                    defaultMeasureOptionsUnitCode,
                )
            );
        }
    }
    return {
        ...state,
        exercises: {
            ...state.exercises,
            [sectionIndex]: {
                ...state.exercises[sectionIndex],
                exercises: [
                    ...state.exercises[sectionIndex].exercises.slice(0, exerciseIndex),
                    {
                        ...state.exercises[sectionIndex].exercises[exerciseIndex],
                        series: {
                            ...state.exercises[sectionIndex].exercises[exerciseIndex].series,
                            serieRows: newSerieRows,
                            serieRowsCount: seriesCount,
                        },
                    },
                    ...state.exercises[sectionIndex].exercises.slice(exerciseIndex+1),
                ]
            }
        },
        helpers: {
            ...state.helpers,
            unsavedTrainings: unsavedTrainings(state.helpers).add([state.sections[sectionIndex].trainingIndex])
        }
    };
};