export const MODULE_DISPLAY_TYPE_SHOW_ALL    = 1;
export const MODULE_DISPLAY_TYPE_BY_CALENDAR = 2;
export const MODULE_DISPLAY_TYPE_AFTER_DONE  = 3; // when user finished - no matter when

export const MODULE_DISPLAY_TYPE_OPTIONS = [
    {
        label: 'Pokaż wszystkie treningi',
        sublabel: 'Sportowiec widzi od razu wszystkie treningi w danym module',
        value: MODULE_DISPLAY_TYPE_SHOW_ALL
    },
    {
        label: 'Pokazuj zgodnie z kalendarzem',
        sublabel: 'Sportowiec codziennie otrzymuje informację o nowym treningu',
        value: MODULE_DISPLAY_TYPE_BY_CALENDAR
    },
    {
        label: 'Pokazuj kolejny po zakończeniu treningu',
        sublabel: 'Wyświetlenie treningu jest uzależnione od tego kiedy sportowiec oznaczy trening jako zakończony',
        value: MODULE_DISPLAY_TYPE_AFTER_DONE
    },
];