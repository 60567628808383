import axios from 'axios';
import {CONFIGURATOR_DEFAULT, CONFIGURATOR_PLAYER_PLAN, CONFIGURATOR_SINGLE_TRAINING} from "app/config/configurator";
import {BASE_URL_API} from "app/config/env";

export const CreateEmptyConfiguratorModule = (data, trainingPlanId) => axios({
    method: 'POST',
    url: `${BASE_URL_API}/v1/trainer-panel/training-plans/${trainingPlanId}/modules`,
    data,
});

export const FetchConfiguratorModule = ({queryKey: [_key, type, trainingPlanId, moduleId]}) => {
    let url;
    if(type === CONFIGURATOR_DEFAULT)         url = `${BASE_URL_API}/v1/trainer-panel/training-plans/${trainingPlanId}/modules/${moduleId}/configurator`;
    if(type === CONFIGURATOR_PLAYER_PLAN)     url = `${BASE_URL_API}/v1/trainer-panel/client-training-plans/${trainingPlanId}/modules/${moduleId}/configurator`;
    if(type === CONFIGURATOR_SINGLE_TRAINING) url = `${BASE_URL_API}/v1/training-templates/${trainingPlanId}/update`;
    return axios({
        method: 'GET',
        url,
    });
}

export const SaveConfiguratorTraining = (type, data, trainingPlanId, moduleId, dayNo) => {
    let url;
    if(type === CONFIGURATOR_SINGLE_TRAINING)  url = `${BASE_URL_API}/v1/training-templates/${trainingPlanId}/update`;
    else if(type === CONFIGURATOR_DEFAULT)     url = `${BASE_URL_API}/v1/trainer-panel/training-plans/${trainingPlanId}/modules/${moduleId}/days/${dayNo}/trainings`;
    else if(type === CONFIGURATOR_PLAYER_PLAN) url = `${BASE_URL_API}/v1/trainer-panel/client-training-plans/${trainingPlanId}/modules/${moduleId}/days/${dayNo}/trainings`;
    else return null;
    return axios({
        method: type === CONFIGURATOR_SINGLE_TRAINING ? 'PUT' : 'POST',
        url,
        data,
    });
}

export const ToggleDayType = (type, status, trainingPlanId, moduleId, dayNo) => {
    const url = type === CONFIGURATOR_DEFAULT ? (
        `${BASE_URL_API}/v1/trainer-panel/training-plans/${trainingPlanId}/modules/${moduleId}/days/${dayNo}`
    ) : (
        `${BASE_URL_API}/v1/trainer-panel/client-training-plans/${trainingPlanId}/modules/${moduleId}/days/${dayNo}`
    );
    return axios({
        url,
        method: 'PUT',
        data: {
            type: status,
        }
    })
};

export const GetConfiguratorModuleInfo = ({queryKey: [_key, type, trainingPlanId, moduleId]}) => {
    const url = type===CONFIGURATOR_DEFAULT ? (
        `${BASE_URL_API}/v1/trainer-panel/training-plans/${trainingPlanId}/modules/${moduleId}/info`
    ) : (
        `${BASE_URL_API}/v1/trainer-panel/client-training-plans/${trainingPlanId}/modules/${moduleId}/info`
    );
    return axios({
        url,
        method: 'GET',
    });
}

export const PostConfiguratorModuleInfo = (type, trainingPlanId, moduleId, data) => {
    const url = type===CONFIGURATOR_DEFAULT ? (
        `${BASE_URL_API}/v1/trainer-panel/training-plans/${trainingPlanId}/modules/${moduleId}/info`
    ) : (
        `${BASE_URL_API}/v1/trainer-panel/client-training-plans/${trainingPlanId}/modules/${moduleId}/info`
    );
    return axios({
        url,
        method: 'PUT',
        data,
    });
}

export const DeleteConfiguratorSection = (type, trainingPlanId, moduleId, dayNo, trainingId, sectionId) => {
    let url;
    if(type===CONFIGURATOR_DEFAULT) {
        url = `${BASE_URL_API}/v1/trainer-panel/training-plans/${trainingPlanId}/modules/${moduleId}/days/${dayNo}/trainings/${trainingId}/sections/${sectionId}`;
    } else if(type===CONFIGURATOR_PLAYER_PLAN) {
        url = `${BASE_URL_API}/v1/trainer-panel/client-training-plans/${trainingPlanId}/modules/${moduleId}/days/${dayNo}/trainings/${trainingId}/sections/${sectionId}`;
    } else {
        url = `${BASE_URL_API}/v1/training-templates/${trainingId}/sections/${sectionId}`
    }
    return axios({
        url,
        method: 'DELETE',
    });
}

export const DeleteConfiguratorTraining = (type, trainingPlanId, moduleId, dayNo, trainingId) => {
    const url = type===CONFIGURATOR_DEFAULT ? (
        `${BASE_URL_API}/v1/trainer-panel/training-plans/${trainingPlanId}/modules/${moduleId}/days/${dayNo}/trainings/${trainingId}`
    ) : (
        `${BASE_URL_API}/v1/trainer-panel/client-training-plans/${trainingPlanId}/modules/${moduleId}/days/${dayNo}/trainings/${trainingId}`
    );
    return axios({
        url,
        method: 'DELETE',
    });
}

export const saveTrainingAsTemplate = (type, trainingPlanId, moduleId, dayNo, trainingId) => (data) => {
    const url = type===CONFIGURATOR_DEFAULT ? (
        `${BASE_URL_API}/v1/trainer-panel/training-plans/${trainingPlanId}/modules/${moduleId}/days/${dayNo}/trainings/${trainingId}/save-template`
    ) : (
        `${BASE_URL_API}/v1/trainer-panel/client-training-plans/${trainingPlanId}/modules/${moduleId}/days/${dayNo}/trainings/${trainingId}/save-template`
    );
    return axios({
        url,
        method: 'POST',
        data,
    });
}
