import axios from "axios";
import {BASE_URL_API} from "app/config/env";
import useQuery2 from "app/hooks/useQuery2";


const getMeasureUnitGroup  = () => axios({
    method: 'GET',
    url: `${BASE_URL_API}/v1/list/measure-units`,
});

export const useMeasureUnitGroup = (enabled) => useQuery2({
    queryKey:['global.units'],
    queryFn: getMeasureUnitGroup,
    extractLevel: 3,
    isArray: true,
    config: {
        enabled,
    }
});
