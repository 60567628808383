import {createChatUser} from "app/redux/chats/utlis/createChatUser";
import {createChatMessage} from "app/redux/chats/utlis/createChatMessage";

export const createChat = ({
    id,
    lastMessageDate,
    unreadCount,
    lastMessage,
    trainerProfile,
    playerProfile,
    clientStatus,
    clientId,
}) => {
    return ({
        chatId: id,
        user: createChatUser(playerProfile??trainerProfile, clientId, clientStatus, playerProfile?'player':'trainer'),
        unreadCount: 0,
        lastMessageTime: lastMessageDate ? new Date(lastMessageDate).getTime() : 0,
        lastMessage: lastMessage ? createChatMessage(lastMessage) : null,
        newMessage: '',
        stats: {
            messagesCount: 0,
            oldestId: null,
            newestId: null,
            isReachOldest: false,
        },
    });
}
