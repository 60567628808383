import {prefix} from "app/config/routes/tp/_cfg";

export const routeTPClients = {
    //
    'tp.clients':         `${prefix}/clients`,
    'tp.clients.invites': `${prefix}/clients/invites`,
    //
    'tp.client':               (id = ':clientId') => `${prefix}/clients/${id}`,
    'tp.client.byPlayerId':    (id = ':clientId') => `${prefix}/clients/${id}?qt=1`,
    //
    'tp.client.scores':        (id = ':clientId') => `${prefix}/clients/${id}/scores`,
    'tp.client.score':        (id = ':clientId', eId = ':testExerciseId') => `${prefix}/clients/${id}/scores/${eId}`,
    'tp.client.injuries':      (id = ':clientId') => `${prefix}/clients/${id}/injuries`,
    'tp.client.measures':      (id = ':clientId') => `${prefix}/clients/${id}/measures`,
    //
    'tp.client.trainingPlans': (id = ':clientId') => `${prefix}/clients/${id}/training-plans`,
    'tp.client.trainings':     (id = ':clientId') => `${prefix}/clients/${id}/trainings`,
    //
    //
    'tp.clients.groups':                                           `${prefix}/clients/groups`,
    //
    'tp.clients.group':               (gId = ':groupId') => `${prefix}/clients/groups/${gId}`,
    'tp.clients.group.trainingPlans': (gId = ':groupId') => `${prefix}/clients/groups/${gId}/training-plans`,
}
