/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {matchPath, NavLink, useLocation} from "react-router-dom";
import {route} from "app/config/routes/route";
import {
    ClientsIcon,
    ExerciseIcon,
    getIcon,
    LimitIcon,
    MeasureIcon,
    MessagesIcon,
    ModuleIcon,
    PanelIcon,
    PlansIcon,
    ProblemIcon,
    ProfileIcon,
    ScoreIcon,
    SettingsIcon,
    TestExerciseIcon,
    TrainerIcon,
    TrainingIcon,
    UserPlansIcon,
    UserTrainingsIcon
} from "components/Icons";
import {useDispatch, useSelector} from "react-redux";
import {
    dispatchToggleAsideMobileOpen,
    LAYOUT_MODE_CLUB,
    LAYOUT_MODE_PLAYER,
    LAYOUT_MODE_TRAINER
} from "app/redux/app/appActionTypes";
import useAuth from "app/hooks/useAuth";
import {role} from "app/config/roles";

const IconNavItem = ({ url, icon: Icon, name, exact, altPaths = [] }) => {
    const { pathname } = useLocation();
    const {isAsideMobileOpen} = useSelector(({app})=>app.layout);
    const dispatch = useDispatch();
    const isActive = [url, ...altPaths].some((path) => !!matchPath(pathname, {
        path,
        exact,
        strict: false,
    }));

    const hideAside = () => {
        if (!isAsideMobileOpen) return;
        dispatch(dispatchToggleAsideMobileOpen())
    }
    return (
        <li
            className={`menu-item ${isActive ? 'menu-item-active menu-item-open' : ''}`}
            aria-haspopup="true"
        >
            <NavLink className="menu-link" to={url} onClick={hideAside}>
                {Icon && <Icon className="svg-icon menu-icon" />}
                <span className="menu-text">{name}</span>
            </NavLink>
        </li>
    );
};

const Separator = ({ name }) => (
    <li className="menu-section ">
        <h4 className="menu-text">{name}</h4>
        <i className="menu-icon flaticon-more-v2"/>
    </li>
);

const TrainerMenuList = () => {

    const { hasRole } = useAuth();

    return (
        <>
            <IconNavItem
                name="Pulpit"
                url={route['tp.dashboard']}
                icon={PanelIcon}
                exact
            />
            <IconNavItem
                name="Wiadomości"
                url={route['common.chats']('tp')}
                icon={MessagesIcon}
            />

            {hasRole(role['developer']) && (
                <>
                    <Separator name="Developer"/>
                    <IconNavItem
                        name="Ankiety"
                        url={route['tp.surveys']}
                        icon={ModuleIcon}
                    />
                </>
            )}

            <Separator name="Trening"/>
            <IconNavItem
                name="Klienci"
                url={route['tp.clients']}
                icon={ClientsIcon}
            />
            <IconNavItem
                name="Plany klientów"
                url={route['tp.clientTrainingPlans']}
                icon={UserPlansIcon}
            />
            <IconNavItem
                name="Treningi klientów"
                url={route['tp.clientTrainings']}
                icon={UserTrainingsIcon}
            />

            <Separator name="Baza" />
            <IconNavItem
                name="Biblioteka planów"
                url={route['tp.template.trainingPlans']}
                icon={PlansIcon}
            />
            <IconNavItem
                name="Szablony treningów"
                url={route['tp.template.trainings']}
                icon={TrainingIcon}
            />
            <IconNavItem
                name="Ćwiczenia"
                url={route['tp.template.exercises']}
                icon={ExerciseIcon}
            />
            <IconNavItem
                name="Ćwiczenia sprawdzające"
                url={route['tp.template.testExercises']}
                icon={TestExerciseIcon}
            />

            <Separator name="Konto"/>
            <IconNavItem
                name="Mój profil"
                url={route['tp.editProfile']}
                icon={ProfileIcon}
            />
            <IconNavItem
                name="Moje konto"
                url={route['tp.account']}
                icon={SettingsIcon}
            />
            <IconNavItem
                name="Zgłoś pomysł/problem"
                url={route['common.bugReport']('tp')}
                icon={ProblemIcon}
            />
        </>
    );
}


const PlayerMenuList = () => (
    <>
        <IconNavItem
            url={route['pp.dashboard']}
            name="Pulpit"
            icon={PanelIcon}
            exact
        />
        <IconNavItem
            name="Wiadomości"
            url={route['common.chats']('pp')}
            icon={MessagesIcon}
        />

        <Separator name="Trening"/>
        {/*<IconNavItem*/}
        {/*    url={route['pp.training']}*/}
        {/*    name="Trening"*/}
        {/*    icon={TrainingIcon}*/}
        {/*/>*/}
        <IconNavItem
            url={route['pp.myTrainingPlans']}
            icon={PlansIcon}
            name="Moje plany treningowe"
        />
        <IconNavItem
            url={route['pp.playerTrainers']}
            name="Moi trenerzy"
            icon={TrainerIcon}
        />

        <Separator name="Sportowiec"/>
        <IconNavItem
            url={route['pp.myScores']}
            name="Wyniki"
            icon={ScoreIcon}
        />
        <IconNavItem
            url={route['pp.myMeasures']}
            name="Pomiary"
            icon={MeasureIcon}
        />
        <IconNavItem
            url={route['pp.myInjuries']}
            icon={LimitIcon}
            name="Ograniczenia"
        />
        <Separator name="Konto"/>
        <IconNavItem
            url={route['pp.profile']}
            name="Mój profil"
            icon={ProfileIcon}
        />
        <IconNavItem
            url={route['pp.account']}
            name="Konto"
            icon={SettingsIcon}
        />
        <IconNavItem
            name="Zgłoś pomysł/problem"
            url={route['common.bugReport']('pp')}
            icon={ProblemIcon}
        />
    </>
);


const ClubMenuList = () => (
    <>
        <IconNavItem
            url={route['cp.dashboard']}
            name="Pulpit"
            icon={PanelIcon}
            exact
        />
        <IconNavItem
            url={route['cp.trainers']}
            name="Trenerzy"
            icon={TrainerIcon}
        />
        <IconNavItem
            url={route['cp.players']}
            name="Sportowcy"
            icon={ClientsIcon}
        />
        <IconNavItem
            url={route['cp.profile']}
            name="Profil"
            icon={ProfileIcon}
        />
    </>
);

export function AsideMenuList({ layoutProps }) {
    const {layoutMode} = useSelector(({app})=>app.layout);

    return (
        <>
            {/* begin::Menu Nav */}
            <ul className={`my-4 menu-nav ${layoutProps.ulClasses}`}>
                {/*begin::1 Level*/}

                {layoutMode === LAYOUT_MODE_TRAINER && <TrainerMenuList />}
                {layoutMode === LAYOUT_MODE_PLAYER && <PlayerMenuList />}
                {layoutMode === LAYOUT_MODE_CLUB && <ClubMenuList />}

            </ul>

            {/* end::Menu Nav */}
        </>
    );
}
