import React from 'react';
import SymbolImage from 'components/SymbolImage';

const FormikOptionWithSubtitle = ({ imageUrl, label, sublabel, children, icon }) => (
    <div className="d-flex align-items-center cursor-pointer">
        {imageUrl && <SymbolImage
            isCircle
            src={imageUrl}
            maxWidth={30}
        />}
        {icon}
        <div className="d-flex flex-column ml-3 flex-grow-1">
            <b>{label}</b>
            <small className="text-muted">{sublabel}</small>
        </div>
        {children}
    </div>
);

export default FormikOptionWithSubtitle;
