import {prefix} from "app/config/routes/tp/_cfg";

const t = ':trainingId';

export const routeTPTrainings = {

    'tp.template.trainings':       `${prefix}/trainings-templates`,
    'tp.template.training.create': `${prefix}/trainings-templates/create`,
    'tp.template.training':              (id=t)=> `${prefix}/trainings-templates/${id}`,
    'tp.template.training.edit':         (id=t)=> `${prefix}/trainings-templates/${id}/edit`,
}
