
import React from "react";
import Logo from "components/Logo";
import { Link } from "react-router-dom";
import ExternalLink from "app/core/router/components/ExternalLink";
import {route} from "app/config/routes/route";
import styled from "styled-components";
import useModal from "app/hooks/useModal";
import { Modal } from "react-bootstrap";
import { AppStoreAsset, EuFooterAsset, GooglePlayAsset } from "components/Assets";
import {useBuildVersion} from "app/providers/BuildVersionProvider";

export const UEFlag = styled.img`
    max-width: 500px;
    width:100%;
    display:block;
    margin: 0 auto;
`;

const I_Footer = () => {
    const [isModal, openModal, closeModal] = useModal();
    const buildVersion = useBuildVersion()

    const year = new Date().getFullYear();
    return (<>
        <footer
            className="bg-dark text-light"
        >
            <div className="container pt-12 pb-8">
                <div className="row">
                    <div className="col-12 col-lg-4 text-center text-lg-left">
                        <div className="d-flex justify-content-center justify-content-lg-start">
                            <Logo color="light"/>
                        </div>
                        <p className="mb-8">
                            TRAINERON to innowacyjny projekt, który wprowadza na rynek nową jakość treningu poprzez dostarczenie trenerom i sportowcom inteligentnych narzędzi opartych o nowe technologie.
                        </p>
                        <p className="mb-0">
                            &copy; {year} Traineron Sp. z.o.o.
                        </p>
                        <p>
                            Made with <span role="img" aria-label="red heart">❤️</span> in Rzeszów
                        </p>
                        <p>
                            <span className="small text-muted text-center">
                                v{buildVersion}
                            </span>
                        </p>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 justify-content-center d-flex">
                        <div className="d-flex flex-column">
                            <h3 className="mt-7 mb-1">Informacje</h3>
                            <div className="py-1">
                                <Link to={route['app.terms']}>Regulamin</Link>
                            </div>
                            <div className="py-1">
                                <Link to={route['app.privacy']}>Polityka prywatności</Link>
                            </div>
                            <div className="py-1">
                                <Link to={route['app.pricing']}>Cennik</Link>
                            </div>
                            <div className="py-1">
                                <Link to={route['app.faq']}>FAQ</Link>
                            </div>
                            <div className="py-1">
                                <Link to={route['app.contact']}>Kontakt</Link>
                            </div>
                            {/*<div className="py-1">*/}
                            {/*    <Link to={route['app.investorRelations']}>Relacje Inwestorskie</Link>*/}
                            {/*</div>*/}
                            <div className="py-1">
                                <Link to={route['app.euProject']}>Projekt UE</Link>
                            </div>
                            <div className="mt-5">
                                <ExternalLink to="https://www.facebook.com/traineroncom/" className="btn btn-lg m-2 btn-icon btn-circle btn-facebook">
                                    <i className="fab fa-facebook-f"/>
                                </ExternalLink>
                                <ExternalLink to="https://www.instagram.com/traineroncom/" className="btn btn-lg m-2 btn-icon btn-circle btn-instagram">
                                    <i className="fab fa-instagram"/>
                                </ExternalLink>
                                <ExternalLink to="https://www.youtube.com/channel/UClZrZJf-P5dcom9rjMDm5Kg" className="btn btn-lg m-2 btn-icon btn-circle btn-youtube">
                                    <i className="fab fa-youtube"/>
                                </ExternalLink>
                                <ExternalLink to="https://www.linkedin.com/company/traineroncom/" className="btn btn-lg m-2 btn-icon btn-circle btn-linkedin">
                                    <i className="fab fa-linkedin-in"/>
                                </ExternalLink>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 justify-content-center d-flex">
                        <div className="d-flex flex-column">
                            <h3 className="mt-7 mb-1">Aplikacja</h3>
                            <a href="https://play.google.com/store/apps/details?id=com.traineron.traineronapp" target="_blank" className="mb-5 mt-3">
                                <img
                                    style={{maxWidth: '170px'}}
                                    src={GooglePlayAsset}
                                    alt="download from google play banner"
                                />
                            </a>
                            <a href="https://apps.apple.com/us/app/traineron/id1537567987" target="_blank">
                                <img
                                    style={{maxWidth: '170px'}}
                                    src={AppStoreAsset}
                                    alt="download from app store banner"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-1 bg-white">
                <div className="container">
                    <UEFlag src={EuFooterAsset} alt="UE"/>
                </div>
            </div>
        </footer>
        <Modal show={isModal} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Już wkrótce!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Ciężko pracujemy, aby wydać pierwszą wersję Aplikacji. Poinformujemy Cię kiedy będzie dostępna.
            </Modal.Body>
        </Modal>
    </>);
}

export default I_Footer;
