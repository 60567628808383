import { useSelector } from "react-redux";


const useAuth = () => {

    const { user, authToken } = useSelector(({ auth }) => auth);

    const isLoggedIn = () => authToken && user?.data?.roles;

    const hasRole = (role) => {
        if(isLoggedIn()){
            return user.data.roles.includes(role);
        }
        return false;
    }

    return {
        isLoggedIn,
        hasRole,
    }

}

export default useAuth;