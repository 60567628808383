import React from 'react';
import {route} from "app/config/routes/route";
import {clubPanelTitle, defaultTitle, playerPanelTitle, trainerPanelTitle} from "app/config/seo";
import {
        LAYOUT_MODE_CLUB,
        LAYOUT_MODE_PLAYER,
        LAYOUT_MODE_PUBLIC,
        LAYOUT_MODE_TRAINER
} from "app/redux/app/appActionTypes";
import {AppSwitch} from "app/core/router/components/AppSwitch";
import {AppRoute} from "app/core/router/components/AppRoute";
import {Auth} from "views/routes/core/Auth";
import {TrainingPlansRoutes} from "views/routes/public/TrainingPlansRoutes";
import {TrainersRoutes} from "views/routes/public/TrainersRoutes";
import {PlayersRoutes} from "views/routes/public/PlayersRoutes";
import {ClubsRoutes} from "views/routes/public/ClubsRoutes";
import {ArticlesRoutes} from "views/routes/public/ArticlesRoutes";
import Error404 from "views/pages/core/errors/Error404";
import useAuth from "app/hooks/useAuth";
import {role} from "app/config/roles";


const TrainerRoutes     = React.lazy(() => import('views/routes/tp/TrainerRoutes'));
const PlayerRoutes      = React.lazy(() => import('views/routes/pp/PlayerRoutes'));
const ClubRoutes        = React.lazy(() => import('views/routes/cp/ClubRoutes'));
//
const IndexPage         = React.lazy(() => import('views/pages/public/index/IndexPage'));
const TermsPage         = React.lazy(() => import('views/pages/public/about/Rules'));
const PrivacyPage       = React.lazy(() => import('views/pages/public/about/Privacy'));
const FaqPage           = React.lazy(() => import('views/pages/public/about/FAQ'));
const EuProjectPage     = React.lazy(() => import('views/pages/public/about/EUProject'));
const ContactPage       = React.lazy(() => import('views/pages/public/about/Contact'));
const PricingPage       = React.lazy(() => import('views/pages/public/about/Pricing'));
const InvestorRelations = React.lazy(() => import('views/pages/public/about/InvestorRelations'));

const Routes = () => {
    const { hasRole } = useAuth();
    return (
        <AppSwitch>

            {/* HOMEPAGE */}
            <AppRoute
                path="/"
                exact
                chatPlugin={true}
                layoutMode={LAYOUT_MODE_PUBLIC}
                component={IndexPage}
                meta={{
                    title: 'Traineron - nowa jakość treningu',
                    description: 'Kompleksowe narzędzie wspomagające trenerów oraz sportowców',
                }}
            />

            {/* AUTH */}
            <AppRoute
                chatPlugin={false}
                path={route['auth']}
                layoutMode={LAYOUT_MODE_PUBLIC}
                component={Auth}
            />

            {/* TRAINER PANEL */}
            <AppRoute
                chatPlugin={false}
                path={route['tp']}
                layoutMode={LAYOUT_MODE_TRAINER}
                meta={{ title:trainerPanelTitle('') }}
                component={TrainerRoutes}
                validate={hasRole(role['profileType.trainer'])}
            />
            <AppRoute
                chatPlugin={false}
                path={route['pp']}
                layoutMode={LAYOUT_MODE_PLAYER}
                meta={{ title:playerPanelTitle('') }}
                component={PlayerRoutes}
                validate={hasRole(role['profileType.player'])}
            />
            <AppRoute
                chatPlugin={false}
                path={route['cp']}
                layoutMode={LAYOUT_MODE_CLUB}
                meta={{ title:clubPanelTitle('') }}
                component={ClubRoutes}
                validate={hasRole(role['profileType.club'])}
            />


            {/* APP */}
            <AppRoute
                exact
                chatPlugin={true}
                path={route['app.terms']}
                layoutMode={LAYOUT_MODE_PUBLIC}
                component={TermsPage}
                meta={{ title: 'Regulamin' }}
            />
            <AppRoute
                exact
                chatPlugin={true}
                path={route['app.privacy']}
                layoutMode={LAYOUT_MODE_PUBLIC}
                component={PrivacyPage}
                meta={{ title: defaultTitle('Polityka prywatności') }}
            />
            <AppRoute
                exact
                chatPlugin={true}
                path={route['app.faq']}
                layoutMode={LAYOUT_MODE_PUBLIC}
                component={FaqPage}
                meta={{ title: defaultTitle('Często zadawane pytania') }}
            />
            <AppRoute
                exact
                chatPlugin={true}
                path={route['app.euProject']}
                layoutMode={LAYOUT_MODE_PUBLIC}
                component={EuProjectPage}
                meta={{ title: defaultTitle('Projekt UE') }}
            />
            <AppRoute
                exact
                chatPlugin={true}
                path={route['app.investorRelations']}
                layoutMode={LAYOUT_MODE_PUBLIC}
                component={InvestorRelations}
                meta={{ title: defaultTitle('Relacje inwestorskie') }}
            />
            <AppRoute
                exact
                chatPlugin={true}
                path={route['app.contact']}
                layoutMode={LAYOUT_MODE_PUBLIC}
                component={ContactPage}
                meta={{ title: defaultTitle('Kontakt') }}
            />
            <AppRoute
                exact
                chatPlugin={true}
                path={route['app.pricing']}
                layoutMode={LAYOUT_MODE_PUBLIC}
                component={PricingPage}
                meta={{ title: defaultTitle('Cennik') }}
            />

            {/* TRAINING PLANS */}
            <AppRoute
                chatPlugin={true}
                path={route['p.trainingPlans']}
                layoutMode={LAYOUT_MODE_PUBLIC}
                component={TrainingPlansRoutes}
            />

            {/* TRAINERS & PLAYERS */}
            <AppRoute
                chatPlugin={true}
                path={route['p.trainers']}
                layoutMode={LAYOUT_MODE_PUBLIC}
                component={TrainersRoutes}
            />
            <AppRoute
                chatPlugin={true}
                path={route['p.players']}
                layoutMode={LAYOUT_MODE_PUBLIC}
                component={PlayersRoutes}
            />

            {/* CLUBS */}
            <AppRoute
                chatPlugin={true}
                path={route['p.clubs']}
                layoutMode={LAYOUT_MODE_PUBLIC}
                component={ClubsRoutes}
            />

            {/* ARTICLES */}
            <AppRoute
                chatPlugin={true}
                path={route['p.articles']}
                layoutMode={LAYOUT_MODE_PUBLIC}
                component={ArticlesRoutes}
            />

            <AppRoute
                chatPlugin={true}
                layoutMode={LAYOUT_MODE_PUBLIC}
                component={Error404}
            />


        </AppSwitch>
    );
};

export default Routes;
