import React, {useEffect, useMemo} from "react";
import objectPath from "object-path";
import {Brand} from "../brand/Brand";
import {AsideMenu} from "./aside-menu/AsideMenu";
import {useHtmlClassService} from "_metronic/layout";
import {useDispatch, useSelector} from "react-redux";
import {dispatchToggleAsideMobileOpen} from "app/redux/app/appActionTypes";
import {KTUtil} from "_metronic/_assets/js/components/util";


export const Aside = () => {
  const uiService = useHtmlClassService();
  const dispatch = useDispatch();
  const {isAsideMobileOpen} = useSelector(({app})=>app.layout);

  const layoutProps = useMemo(() => {
    return {
      disableScroll:
          objectPath.get(uiService.config, "aside.menu.dropdown") === "true" ||
          false,
      asideClassesFromConfig: uiService.getClasses("aside", true),
      disableAsideSelfDisplay:
          objectPath.get(uiService.config, "aside.self.display") === false,
      headerLogo: uiService.getLogo()
    };
  }, [uiService]);

  useEffect(() => {
    const body = KTUtil.getBody();
    if (isAsideMobileOpen) {
      KTUtil.addClass(body, 'aside-on')
      KTUtil.addClass(body, 'header-menu-wrapper-on')
    } else {
      KTUtil.removeClass(body, 'aside-on')
      KTUtil.removeClass(body, 'header-menu-wrapper-on')
    }
  },[isAsideMobileOpen]);

  const handleToggleAsideOpen = () => dispatch(dispatchToggleAsideMobileOpen());

  return (
    <>
      {/* begin::Aside */}
      <div className={`aside aside-left ${layoutProps.asideClassesFromConfig} ${isAsideMobileOpen ? 'aside-on' : ''} d-flex flex-column flex-row-auto`}>

        <Brand/>

        {/* begin::Aside Menu */}
        <div id="kt_aside_menu_wrapper" className="aside-menu-wrapper flex-column-fluid flex-grow-1">
          <AsideMenu disableScroll={layoutProps.disableScroll}/>
        </div>
        {/* end::Aside Menu */}
      </div>
      {/* end::Aside */}
      {isAsideMobileOpen && (
          <div className="aside-overlay" onClick={handleToggleAsideOpen} />
      )}
    </>
  );
}
