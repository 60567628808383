import React from "react";
import {Redirect, useLocation} from "react-router-dom";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "_metronic/_helpers";
import { route } from "app/config/routes/route";
import {useDispatch, useSelector} from "react-redux";
import {dispatchToggleAsideMobileOpen} from "app/redux/app/appActionTypes";

const NavItem = ({ url, name, textColor }) => {
    const location = useLocation();
    const {isAsideMobileOpen} = useSelector(({app})=>app.layout);
    const dispatch = useDispatch();
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    }

    const hideAside = () => {
        if (!isAsideMobileOpen) return;
        dispatch(dispatchToggleAsideMobileOpen())
    }

    return (
        <li className={`menu-item menu-item-rel ${getMenuItemActive(url)}`}>
            <NavLink className="menu-link" to={url} onClick={hideAside}>
                <span className={`menu-text ${textColor ? textColor : ''}`}>{name}</span>
            </NavLink>
        </li>
    );
}

export function HeaderMenu({ layoutProps }) {
    const { user, authToken } = useSelector(({ auth }) => auth);

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*{authToken && user?.data && (*/}
            {/*    <>*/}
            <NavItem
                url={route['p.trainingPlans']}
                name={"Plany treningowe"}
            />
            <NavItem
                url={route['p.trainers']}
                name={"Trenerzy"}
            />
            {/*<NavItem*/}
            {/*    url={route['p.players']}*/}
            {/*    name={"Sportowcy"}*/}
            {/*/>*/}
            <NavItem
                url={route['p.clubs']}
                name={"Kluby"}
            />
            <NavItem
                url={route['p.articles']}
                name={"Artykuły"}
            />
            {/*    </>*/}
            {/*)}*/}
        </ul>
    </div>;
}
