import React, {useEffect} from 'react';
import {route} from "app/config/routes/route";
import styled, {css} from "styled-components";
import {Link, useLocation} from "react-router-dom";
import {ConversationListPure} from "views/pages/common/chats/components/Conversations";

const FloatingBox = styled.div`
  width: 350px;
  transform: translateY(100%);
  transition: opacity 0.2s ease, transform 0.2s ease;
  transform-origin: top right;
  max-height: min(400px, 80vh);
  ${({ isOpen }) => !isOpen && css`
    opacity: 0;
    transform: translateY(100%) scale(0.9);
    pointer-events: none;
  `}
`;

const TopbarChatsFlyout = ({ panelType = 'pp', isOpen, onClose }) => {
    const location = useLocation();

    useEffect(() => {
        if (isOpen) onClose()
    }, [location])

    return (
        <FloatingBox
            className="bg-white position-absolute right-0 bottom-0 rounded-lg shadow-lg overflow-hidden d-flex flex-column"
            isOpen={isOpen}
        >
            <div className="bg-light d-flex align-items-center justify-content-center h6 font-weight-bolder py-5 mb-0">
                Wiadomości
            </div>
            {isOpen && <ConversationListPure panelType={panelType} />}
            <Link as="div" onClick={onClose} to={route[`common.chats`](panelType)} className="d-flex align-items-center justify-content-center py-5">
                Zobacz wszystkie
            </Link>
        </FloatingBox>
    );
};

export default TopbarChatsFlyout;

