import {UPDATE} from "components/form/enums/changeStatus";
import {createEmptySerieObj} from "app/redux/configurator/reducers/5.series/helpers/createDefaultSerieHelpers";

export const allowedKeys = [ 'countStr', 'description' ];

export const createReducerExerciseObj = () => ({
    deleted: [],
    exercises: [],
});

const createSuperserieObj = ({
    isLast = null,
    superserieSize = 0,
    topBtn = null,
    bottomBtn = null,
}) => ({
    isLast,
    superserieSize,
    topBtn,
    bottomBtn,
});

export const createExerciseObj = ({
    id = null, // id from API || null
    timeFrom = null,
    timeTo = null,
    afterExerciseBreakTime = null,
    orderNo = null,
    countStr = '',
    countComment = '',
    // scoreType = 1,
    isSaveScore = false,
    exerciseId = null,// exerciseId from database || null
    // helpers
    changeStatus = UPDATE,
    _name = '', // exercise.name
    _nameENG = '', //exercise.nameENG
    _key = null, // draggable id / unique key for Array().map()
    _photoUrl = null, // display photoUrl
    _updateCount = 0,
    _hasTestExercises,
    countJSON,
    _superseries = {},
    description,
    series = createEmptySerieObj(),
    hasTestExercises: isTestExerciseAllowed = false,
    testExercise = null,
}) => {
    if(!_key && !id) console.error('Missing keys: _key || id');
    if(!exerciseId) console.error('Missing exerciseId');
    const key = _key || (id ? `api.exercise.${id}` : null);
    return {
        id, timeFrom, timeTo, afterExerciseBreakTime, countStr, countComment, isCollapsed: true,
        isSaveScore, exerciseId, _name, _nameENG, _key:key, changeStatus, _photoUrl, _updateCount,
        series, _superseries: createSuperserieObj(_superseries), orderNo, description,
        hasTestExercise: !!testExercise?.id, testExercise, isTestExerciseAllowed
    }
}
