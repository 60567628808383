import moment from "moment";

export const ACCEPT_COOKIES = 'settings.cookies.accept';
export const SET_LAST_MOBILE_POPUP = 'settings.mobilePopup.set'

export const acceptCookies = () => ({
    type: ACCEPT_COOKIES,
    payload: true,
})
export const actionSetLastMobilePopup = () => ({
    type: SET_LAST_MOBILE_POPUP,
    payload: moment().toISOString(),
})