import React from 'react';

const Loading = ({ align = 'center' }) => (
    <div className={`d-flex justify-content-${align} w-100 m-4`}>
        <div className="badge badge-secondary py-4 px-8 rounded-lg d-flex align-items-center text-dark-50">
            <i
                className="spinner-border mr-2 text-dark-50"
                style={{
                    width: '1.5em',
                    height: '1.5em',
                }}
            />
            <span className="font-weight-bolder">Ładowanie...</span>
        </div>
    </div>
);

export default Loading;
