import {prefix} from "app/config/routes/pp/_cfg";

export const routePPTrainingPlans = {
    'pp.myTrainingPlans':      `${prefix}/my-training-plans`,
    'pp.myTrainingPlans.done': `${prefix}/my-training-plans/done`,
    //
    'pp.myTrainingPlan':           (id=':trainingPlanId') => `${prefix}/my-training-plans/${id}`,
    'pp.myTrainingPlan.basicInfo': (id=':trainingPlanId') => `${prefix}/my-training-plans/${id}/info`,
    'pp.myTrainingPlan.stats':     (id=':trainingPlanId') => `${prefix}/my-training-plans/${id}/stats`,
    'pp.myTrainingPlan.settings':  (id=':trainingPlanId') => `${prefix}/my-training-plans/${id}/settings`,
    'pp.myTrainingPlan.buyModule': (id=':playerTrainingPlanId', tId=':trainingPlanId', oId=':offerId') => `${prefix}/my-training-plans/${id}/buy-module/${tId}/${oId}`,
    //
    'pp.myTrainingPlan.module':              (id=':trainingPlanId', mId=':moduleId') => `${prefix}/my-training-plans/${id}/module/${mId}`,
    'pp.myTrainingPlan.module.calendarView': (id=':trainingPlanId', mId=':moduleId') => `${prefix}/my-training-plans/${id}/module/${mId}/calendar`,
    'pp.myTrainingPlan.module.planView':     (id=':trainingPlanId', mId=':moduleId') => `${prefix}/my-training-plans/${id}/module/${mId}/list`,
    'pp.myTrainingPlans.module.stats':       (id=':trainingPlanId', mId=':moduleId') => `${prefix}/my-training-plans/${id}/module/${mId}/stats`,
}