import {prefix} from "app/config/routes/tp/_cfg";

export const routeConfigurator = {

    // CLIENT TRAINING PLAN
    'tp.configurator.clientTrainingPlan.module': (
        id = ':trainingPlanId',
        mId=':moduleId',
        tId=':trainingId?'
    ) => `${prefix}/client-training-plans/${id}/module/${mId}/configurator/${tId}`,

    // TEMPLATE TRAINING PLAN
    'tp.configurator.template.trainingPlan.module': (
        id=':trainingPlanId',
        mId=':moduleId',
        tId=':trainingId?'
    ) => `${prefix}/training-plans-templates/${id}/module/${mId}/configurator/${tId}`,

}
