import {reducerError} from "app/redux/configurator/reducers/reducerError";

export const reducerTrainingToggleCollapse = ( state, { trainingIndex } ) => {
    if(!state.trainings[trainingIndex]) {
        return reducerError('training.toggleCollapse.trainingNotExist');
    }
    return {
        ...state,
        trainings: {
            ...state.trainings,
            [trainingIndex]: {
                ...state.trainings[trainingIndex],
                areSectionsCollapsed: !state.trainings[trainingIndex].areSectionsCollapsed,
            }
        }
    }
}