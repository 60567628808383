import React, {useEffect} from 'react';
import usePaginatedQuery2 from "app/hooks/usePaginatedQuery2";
import {TP_Notifications} from "app/core/api/trainer/notifications";
import {route} from "app/config/routes/route";
import Loading from "components/Loading";
import styled, {css} from "styled-components";
import moment from "moment";
import {PP_Notifications} from "app/core/api/player/notifications";
import CondLink from "app/core/router/components/CondLink";
import {useNotificationRedirect} from "app/configurator/utils/notificationRedirect";
import QueryError from "components/QueryError";
import SymbolImage from "components/SymbolImage";
import {Link, useLocation} from "react-router-dom";
import Dot from "components/Dot";
import useMatchPathname from "app/hooks/useMatchPath";

const FloatingBox = styled.div`
  width: 350px;
  transform: translateY(100%);
  transition: opacity 0.2s ease, transform 0.2s ease;
  max-height: min(400px, 80vh);
  transform-origin: top right;
  ${({ isOpen }) => !isOpen && css`
    opacity: 0;
    transform: translateY(100%) scale(0.9);
    pointer-events: none;
  `}
`;

const TopbarNotificationsFlyout = ({ panelType = 'pp', isOpen, onClose }) => {
    const location = useLocation();
    const notificationRedirect = useNotificationRedirect(panelType);
    const isNotifications = useMatchPathname(route['common.notifications'](panelType));

    useEffect(() => {
        if (isOpen) onClose()
    }, [location])

    const {
        data,
        loadingInfo,
        isLoading,
        isSuccess,
        isError,
    } = usePaginatedQuery2({
        queryKey: ['notifications.flyout', panelType],
        queryFn: panelType==='tp' ? TP_Notifications : PP_Notifications,
        config: {
            enabled: isOpen || isNotifications,
        },
    });
    const url = ({ notificationRedirectType, entityId, entity1Id }) => notificationRedirect(notificationRedirectType, entityId, entity1Id);

    return (
        <FloatingBox className="bg-white position-absolute right-0 bottom-0 rounded-lg shadow-lg overflow-hidden d-flex flex-column" isOpen={isOpen}>
            {isError && <QueryError {...loadingInfo}/>}
            {isLoading && <Loading />}
            {isSuccess && (
                <>
                    <div className="bg-light d-flex align-items-center justify-content-center h6 font-weight-bolder py-5 mb-0">
                        Powiadomienia
                    </div>
                    <div className="d-flex flex-column overflow-auto">
                        {!!data?.length ? (
                            <div className="p-6">
                                {data?.map(({id, createdAt, name, entityName, isRead, notificationType, entityPhotoUrl, ...rest}) => (
                                    <CondLink
                                        key={id}
                                        to={url({...rest})}
                                        onClick={() => url({...rest})}
                                    >
                                        <div className="d-flex w-100 align-items-center py-4 border-bottom text-hover-primary text-dark small">
                                            {!isRead && <Dot color="#6993FF" className="mr-2" />}
                                            {entityPhotoUrl && <SymbolImage src={entityPhotoUrl} fixedSize={30} isCircle maxWidth={30} classNameOuter="mr-4" />}
                                            <div className="flex-1">
                                                <div className={`${!isRead ? 'font-weight-bolder' : ''}`}>{name}</div>
                                                <div className="text-muted">{entityName}</div>
                                            </div>
                                            <div className="ml-2 flex-shrink-0">
                                                {createdAt && <div
                                                    className="small text-muted">{moment(createdAt)?.fromNow()}</div>}
                                                {notificationType && !!notificationType?.id &&
                                                <div className="small">{notificationType?.name}</div>}
                                            </div>
                                        </div>
                                    </CondLink>
                                ))}
                            </div>
                        ) : (
                            <div className="text-muted mx-auto text-center p-6">Nie masz jeszcze powiadomień</div>
                        )}
                    </div>
                    <Link as="div" to={route[`common.notifications`](panelType)} className="d-flex align-items-center justify-content-center py-5">
                        Zobacz wszystkie
                    </Link>
                </>
            )}
        </FloatingBox>
    );
};

export default TopbarNotificationsFlyout;

