import {BASE_URL_API} from "app/config/env";

export const getRemoteAsset = (path, source = BASE_URL_API) => source + path;

/*
 = getRemoteAsset('');
*/

export const BackgroundAsset = getRemoteAsset('/images/bg/background-traineron.png');

export const TrainerIconAsset = getRemoteAsset('/icons/main/trainer.png');
export const PlayerIconAsset = getRemoteAsset('/icons/main/player.png');
export const ClubIconAsset = getRemoteAsset('/icons/main/sport-club.png');

export const ConfiguratorFeatureAsset = getRemoteAsset('/images/features/product-configurator.webp');
export const ExercisesFeatureAsset = getRemoteAsset('/images/features/product-exercises.webp');
export const TrainerFeatureAsset = getRemoteAsset('/images/features/product-trainer.webp');
export const TraineronAIAsset = getRemoteAsset('/images/features/product-ai.webp');
export const StoreAsset = getRemoteAsset('/images/features/product-marketplace.webp');
export const AssistantAsset = getRemoteAsset('/images/features/product-assistant.webp');
export const PlayerAsset = getRemoteAsset('/images/features/product-player.webp');

export const EuFooterAsset = getRemoteAsset('/images/other/eu-footer.png');

export const AppStoreAsset = getRemoteAsset('/images/other/app-store-black.png');
export const GooglePlayAsset = getRemoteAsset('/images/other/google-play-black.png');

export const TraineronDarkLogoAsset = getRemoteAsset('/images/logo/logo_traineron_black.png');
export const TraineronLightLogoAsset = getRemoteAsset('/images/logo/logo_traineron_white.png');

export const SkeletonChartAsset = (params) => {
  const newParams = {...params}

  Object.entries(params).forEach(([key, value]) => value == null && delete newParams[key])
  return getRemoteAsset(`/v1/static-content/manekin.svg?${new URLSearchParams(newParams).toString()}`);
}
