export const unsavedTrainings = ({ unsavedTrainings }) => ({
    add: (newIds = []) => {
        return  [
            ...new Set([
                ...unsavedTrainings,
                ...newIds,
            ]),
        ];
    },
    remove: (idToRemove, id2ToRemove) => {
        // noinspection EqualityComparisonWithCoercionJS
        return [
            ...unsavedTrainings.filter(id => id!=idToRemove && id!=id2ToRemove),
        ]
    }
});