import {prefix} from "app/config/routes/tp/_cfg";

export const routeTPExercises = {
    'tp.template.exercises':                                    `${prefix}/exercises`,
    'tp.template.exercises.traineron':                          `${prefix}/exercises/traineron`,
    //
    'tp.template.exercise':      (id = ':exerciseId') => `${prefix}/exercises/${id}`,
    'tp.template.exercise.create':                              `${prefix}/exercises/add`,
    'tp.template.exercise.edit': (id = ':exerciseId') => `${prefix}/exercises/${id}/edit`,
    //
    'tp.template.testExercises':                                `${prefix}/test-exercises`,
    'tp.template.testExercises.traineron':                      `${prefix}/test-exercises/traineron`,
    //
    'tp.template.testExercise':  (id = ':exerciseId') => `${prefix}/test-exercises/${id}`,
    'tp.template.testExercise.create':                          `${prefix}/test-exercises/add`,
    'tp.template.testExercise.edit':  (id = ':exerciseId') => `${prefix}/test-exercises/${id}/edit`,
}
