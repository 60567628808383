import {DELETED} from "components/form/enums/changeStatus";
import {
    SERIE_MODE_DEFAULT,
    SERIE_MODE_MATH,
    SERIE_MODE_MAX,
    SERIE_MODE_MIN
} from "app/redux/configurator/reducers/0.configurator/helpers/parseSeries";

export const validateApiToStore = () => true;

export const storeTrainingToApi = ( training, sections, exercises ) => {
    let resultObj = {
        ...getTrainingParams(training),
        sections: [],
    };
    training.sections.forEach((sectionIndex, orderNo) => {
        let section = {
            ...getSectionParams(sections[sectionIndex], orderNo+1),
            exercises: [],
        };

        //process exercises
        exercises[sectionIndex].exercises.forEach(exercise => {
            section.exercises.push( getExerciseParams(exercise) );
        });

        //process deleted exercises
        exercises[sectionIndex].deleted.forEach(exercise => {
            section.exercises.push( getDeletedExerciseParams(exercise) );
        });

        resultObj.sections.push(section);
    });

    return resultObj;
};

const getTrainingParams = ({ id, name, description, displayType:trDisplayType }) => {
    return { id, name, description, trDisplayType };
};

const getSectionParams = ({ id, name, description, sectionDisplayType }, sectionNo) => {
    return { id, name, description, sectionDisplayType, sectionNo };
};

const getExerciseParams = ({
    id,
    changeStatus,
    exerciseId,
    countStr,
    isSaveScore,
    timeFrom,
    timeTo,
    orderNo,
    afterExerciseBreakTime,
    series,
    description,
    testExercise,
    hasTestExercise,
}) => {
    return {
        id,
        changeStatus,
        description,
        exerciseId,
        countStr,
        isSaveScore,
        timeFrom,
        orderNo,
        timeTo,
        afterExerciseBreakTime,
        series: getSeriesParams(series),
        testExerciseId: hasTestExercise && testExercise?.id ? testExercise?.id : null,
        isTestExercise: !!(hasTestExercise && testExercise?.id),
    };
};

const getCountStr = (countStr, multiplier) => {
    try {
        if(countStr==null || countStr==='') {
            return null;
        }
        // console.groupCollapsed('count');
        const parsedCountStr = (countStr+'').replace(',', '.');
        let countFloat = parseFloat(parsedCountStr).toFixed(3);
        if(isNaN(countFloat)) {
            countFloat = 0;
        }
        let multiplierFloat = parseFloat(multiplier);
        if(isNaN(multiplierFloat)) {
            multiplierFloat = 1;
        }
        const result = countFloat * multiplierFloat;
        // console.log({countStr, countFloat, parsedCountStr, multiplier, multiplierFloat, result});
        return parseInt(result);
    } catch (error) {
        return null;
    }
}

const getSeriesParams = (series) => {
    if(!series || !series?.header) return [];
    const {header, headerOrder, areSerieRowsTheSame, serieRows, serieRowsCount, serieRowsDeletedIds} = series;
    const rowCountWithId = serieRows.filter(({id}) => id).length;
    if(areSerieRowsTheSame) {
        if(!serieRows.length) {
            return [];
        } else {
            let rows = [];
            let values = {};
            let isEmpty = true;
            for(let i=0; i<headerOrder.length; i++){
                let measureOptionId = headerOrder[i];
                const item = serieRows[0].items[measureOptionId];
                if(item.countStr || item.mode === SERIE_MODE_MIN || item.mode === SERIE_MODE_MAX || item.mode === SERIE_MODE_MATH) {
                    isEmpty = false;
                }
                values[measureOptionId] = getSerieItemObj(item, header[measureOptionId]);
            }
            for(let i=0;i<serieRowsCount; i++){
                if(isEmpty) {
                    if(serieRows[i].id) {
                        rows.push({
                            id: serieRows[i].id,
                            changeStatus: DELETED,
                        });
                    }
                } else {
                    let row = {
                        id: serieRows[i].id ?? null,
                        description: serieRows[i].description,
                        orderNo: i,
                        items: [],
                    };
                    for (let j = 0; j < headerOrder.length; j++) {
                        let measureOptionId = headerOrder[j]
                        row.items.push({
                            id: serieRows[i].items[measureOptionId].id,
                            measureUnitOptionId: measureOptionId,
                            ...values[measureOptionId],
                        });
                    }
                    rows.push(row);
                }
            }
            for(let i=serieRowsCount; i<rowCountWithId ; i++) {
                rows.push({
                    id: serieRows[i].id,
                    changeStatus: DELETED,
                });
            }
            return rows;
        }
    } else {
        if(!serieRows.length) {
            return [];
        } else {
            let rows = [];
            for(let i=0;i<serieRowsCount; i++){
                let isEmpty = true;
                let row = {
                    id: serieRows[i].id??null,
                    description: serieRows[i].description,
                    orderNo: i,
                    items:[],
                };
                for(let j=0; j<headerOrder.length; j++){
                    let measureOptionId = headerOrder[j];
                    let item = serieRows[i].items[measureOptionId];
                    if(item.countStr || item.mode === SERIE_MODE_MIN || item.mode === SERIE_MODE_MAX || item.mode === SERIE_MODE_MATH) {
                        isEmpty = false;
                    }
                    // console.log('item.countStr', item.countStr)
                    // console.log('item.multiplier', item.multiplier)
                    row.items.push({
                        id:                  item.id,
                        measureUnitOptionId: item.unitId,
                        ...getSerieItemObj(item, header[measureOptionId]),
                    });
                }
                if(!isEmpty) {
                    rows.push(row);
                } else {
                    rows.push({
                        id: serieRows[i].id,
                        changeStatus: DELETED,
                    });
                }
            }
            for(let i=serieRowsCount; i<rowCountWithId ; i++) {
                rows.push({
                    id: serieRows[i].id,
                    changeStatus: DELETED
                });
            }
            // return [];
            return rows;
        }
    }
}

const getSerieItemObj = (item, header) => {

    switch(item.mode) {
        case SERIE_MODE_MIN:
            return {
                seriesItemType:      SERIE_MODE_MIN,
                measureUnitOptionId: item.unitId,
                measureUnitId:       header.baseUnitGroupId,
            }
        case SERIE_MODE_MAX:
            return {
                seriesItemType:      SERIE_MODE_MAX,
                measureUnitOptionId: item.unitId,
                measureUnitId:       header.baseUnitGroupId,
            }
        case SERIE_MODE_MATH:
            if(item.mathMode?.testExerciseId && item.mathMode.dynamicOperationStr) {
                return {
                    seriesItemType:      SERIE_MODE_MATH,
                    measureUnitOptionId: item.unitId,
                    measureUnitId:       header.baseUnitGroupId,
                    testExerciseId:      item.mathMode.testExerciseId,
                    dynamicOperationStr: item.mathMode.dynamicOperationStr,
                }
            } else {
                // return {
                //     seriesItemType:      SERIE_MODE_MATH,
                //     measureUnitOptionId: item.unitId,
                //     measureUnitId:       header.baseUnitGroupId,
                //     testExerciseId:      116,
                //     dynamicOperationStr: '20%',
                // }
                break;
            }
    }
    //DEFAULT (including break from switch ;)
    return {
        seriesItemType:      SERIE_MODE_DEFAULT,
        countStr:            getCountStr(item.countStr, item.multiplier),
        measureUnitOptionId: item.unitId,
        measureUnitId:       header.baseUnitGroupId,
    }
}

const getDeletedExerciseParams = ({ id, changeStatus, exerciseId }) => {
    return { id, changeStatus, exerciseId };
};