import React from "react";
import {createMuiTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import { plPL } from "@material-ui/core/locale";
import DateFnsUtils from "@date-io/date-fns";
import { pl } from 'date-fns/locale'
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

const theme = createMuiTheme(
  {
    // direction: "rtl",
    typography: {
      fontFamily: ["Poppins"].join(",")
    },

    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#17c191",
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        main: "#3783e7",
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      error: {
        // light: will be calculated from palette.primary.main,
        main: "#f018a6",
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      }
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        disableRipple: true // No more ripple, on the whole application 💣!
      },

      // Set default elevation to 1 for popovers.
      MuiPopover: {
        elevation: 1
      }
    }
  },
  plPL
);

export function MaterialThemeProvider(props) {
  const { children } = props;
  return <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider locale={pl} utils={DateFnsUtils}>
          {children}
      </MuiPickersUtilsProvider>
  </ThemeProvider>;
}
