import React from 'react';

const DefaultLayout = ({children}) => children;
// {
//     return (
//         <Layout isFooter>
//             {children}
//             {/*<MessengerCustomerChat*/}
//             {/*    pageId="600796410397123"*/}
//             {/*    appId="<APP_ID>"*/}
//             {/*/>*/}
//         </Layout>
//     );
// };

export default DefaultLayout;
