import React from "react";
import {GetModuleProvider} from "app/configurator/contex/getModuleContext";
import {useSelector} from "react-redux";

// noinspection JSCheckFunctionSignatures
const ConfiguratorContext = React.createContext();

export const ConfiguratorProvider = ({ type, children, breadcrumbItems }) => {

    return (
        <ConfiguratorContext.Provider value={null}>
            <GetModuleProvider
                isFetchingExercises={false}
                statusExercises={'success'}
                errorExercises={null}
                breadcrumbItems={breadcrumbItems}
                type={type}
            >
                {children}
            </GetModuleProvider>
        </ConfiguratorContext.Provider>
    );
};

export const useConfiguratorType = () => {
    const { type } = useSelector(state=>state.configurator.config);
    if(type === undefined) {
        throw new Error('useConfiguratorType must be used within ConfiguratorProvider')
    }
    return type;
}