import React, {createContext, useContext, useMemo} from 'react';
import {useMeasureUnitGroup} from "app/core/crud/core/global/getUnitGroup";
import {useSelectToken} from "app/redux/auth/selectors";

const UnitGroupContext = createContext({});

export const MeasureUnitGroupProvider = ({ children }) => {

    const token = useSelectToken();

    const {
        status,
        data:queryData,
        isFetching,
    } = useMeasureUnitGroup(!!token);

    const data = useMemo(()=>{
        let newData = {};
        if(queryData && queryData.length) {
            for(let i=0 ; i<queryData.length ; i++) {
                newData[queryData[i].id] = {
                    ...queryData[i],
                };
            }
        }
        return newData;
    }, [queryData]);

    return (
        <UnitGroupContext.Provider value={{data, isFetching, status}}>
            {children}
        </UnitGroupContext.Provider>
    );
};

export const useCachedMeasureGroupOptions = () => {
    const context = useContext(UnitGroupContext);
    if(!context || !context.data) {
        throw new Error('Missing MeasureGroupOptions Provider');
    }
    return [
        context.data,
        context.status,
    ];
}