import React from 'react';
import DefaultLayout from "templates/public/L_Default";
import styled from 'styled-components';
import { route } from 'app/config/routes/route';
import {Link, Redirect} from 'react-router-dom';

const UnaithorizedContainer = styled.div`
    min-height: calc(100vh - 20em);
`;

const Error401 = () => {



    return (
        <DefaultLayout>
            {/*<Redirect to={route['auth.login']}/>*/}
            <UnaithorizedContainer className="d-flex flex-column align-items-center justify-content-center text-center p-10 pb-lg-30">
            <h3 className="display-3 font-weight-bold">Przepraszamy!</h3>
            <h5 className="display-5 mb-10">Nie masz uprawnień do tej strony</h5>
            <Link to={route['index']}>
                <button type="button" className="btn btn-primary btn-lg">Strona główna</button>
            </Link>
            </UnaithorizedContainer>
        </DefaultLayout>
    );
};

export default Error401;
