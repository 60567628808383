
export const SET_LAYOUT_MODE = 'layout.setLayoutMode';

export const SET_ASIDE_MINIMIZE = 'layout.setAsideMinimize';

export const TOGGLE_ASIDE_MOBILE_OPEN = 'layout.toggleAsideMobileOpen';


//////////
export const LAYOUT_MODE_PUBLIC  = 'layout.public';
export const LAYOUT_MODE_PLAYER  = 'layout.player';
export const LAYOUT_MODE_TRAINER = 'layout.trainer';
export const LAYOUT_MODE_CLUB = 'layout.club';
//
export const dispatchSetLayoutMode = (layoutMode) => ({
    type: SET_LAYOUT_MODE,
    payload: {
        layoutMode,
    },
});

export const dispatchSetAsideMinimize = (mode) => ({
    type: SET_ASIDE_MINIMIZE,
    payload: mode,
});

export const dispatchToggleAsideMobileOpen = () => ({
    type: TOGGLE_ASIDE_MOBILE_OPEN,
});
