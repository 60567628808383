import {prefix} from "app/config/routes/cp/_cfg";

export const routeCP = {
    'cp': `${prefix}`,

    'cp.dashboard': `${prefix}`,
    'cp.trainers': `${prefix}/trainers`,
    'cp.trainer':  (tId=':trainerId') => `${prefix}/trainers/${tId}`,
    'cp.players':  `${prefix}/players`,
    'cp.player':   (pId=':playerId') => `${prefix}/players/${pId}`,
    'cp.profile':  `${prefix}/profile`,
}
