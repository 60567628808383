import {errorHandler} from "app/core/errorHandler";
import {unsavedTrainings} from "app/redux/configurator/utils/configuratorHelpers";

export const reducerSeriesSetSerieValue = (state, { sectionIndex, exerciseIndex, serieIndex, measureUnitId, newValue}) => {
    if(!state.exercises?.[sectionIndex]?.exercises?.[exerciseIndex]?.series?.serieRows?.[serieIndex]?.items?.[measureUnitId]) {
        errorHandler({
            expected: `state.exercises?.[sectionIndex]?.exercises?.[exerciseIndex]?.series?.serieRows?.items?.`
                +`[serieIndex]?.[measureUnitId]`,
            got: state.exercises?.[sectionIndex]?.exercises?.[exerciseIndex]?.series?.serieRows?.[serieIndex]?.items?.[measureUnitId],
            file: 'reducerSeriesEditByKey.js',
            json: { sectionIndex, exerciseIndex, serieIndex, measureUnitId, newValue},
        });
        return state;
    }
    if(newValue.replaceAll(',','.').split('.').length > 2) {
        // console.log(newValue.replaceAll(',','.').split('.').length)
        return state;
    }
    let countStr;
    // if(typeof newValue === 'string' && ['m','ma','max'].indexOf(newValue.toLowerCase())!==-1) {
    //     countStr = newValue.toUpperCase();
    // } else {
        countStr = newValue.replace(/[^0-9\.\,]/g,'');
    // }
    return {
        ...state,
        exercises: {
            ...state.exercises,
            [sectionIndex]: {
                ...state.exercises[sectionIndex],
                exercises: [
                    ...state.exercises[sectionIndex].exercises.slice(0, exerciseIndex),
                    {
                        ...state.exercises[sectionIndex].exercises[exerciseIndex],
                        series: {
                            ...state.exercises[sectionIndex].exercises[exerciseIndex].series,
                            serieRows: [
                                ...state.exercises[sectionIndex].exercises[exerciseIndex].series.serieRows.slice(0, serieIndex),
                                {
                                    ...state.exercises[sectionIndex].exercises[exerciseIndex].series.serieRows[serieIndex],
                                    items: {
                                        ...state.exercises[sectionIndex].exercises[exerciseIndex].series.serieRows[serieIndex].items,
                                        [measureUnitId]: {
                                            ...state.exercises[sectionIndex].exercises[exerciseIndex].series.serieRows[serieIndex].items[measureUnitId],
                                            countStr,
                                        },
                                    },
                                },
                                ...state.exercises[sectionIndex].exercises[exerciseIndex].series.serieRows.slice(serieIndex+1),
                            ],
                        },
                    },
                    ...state.exercises[sectionIndex].exercises.slice(exerciseIndex+1),
                ],
            },
        },
        helpers: {
            ...state.helpers,
            unsavedTrainings: unsavedTrainings(state.helpers).add([state.sections[sectionIndex].trainingIndex])
        }
    };
};