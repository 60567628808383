import {createGlobalStyle} from "styled-components";

export const MessengerStyles = createGlobalStyle`
  .fb_customer_chat_icon,
  iframe.fb_customer_chat_bounce_in_v2,
  iframe.fb_customer_chat_bounce_out_v2 {
    transition: transform 0.25s ease;
    transform: ${({ mobile }) => !mobile && 'translateY(-4em)'};

    @media only screen and (max-width: 767px) {
      transform: translateY(-4em);  
    }
  }
`
