import React from 'react';
import {AppSwitch} from "app/core/router/components/AppSwitch";
import {AppRoute} from "app/core/router/components/AppRoute";
import {route} from "app/config/routes/route";
import {defaultTitle} from "app/config/seo";

const SportClubs    = React.lazy(() => import('views/pages/public/sportClubs/SportClubs'));
const SportClubItem = React.lazy(() => import('views/pages/public/sportClubs/SportClub'));

export const ClubsRoutes = () => {
    return (
        <AppSwitch>
            <AppRoute
                exact
                path={route['p.clubs']}
                component={SportClubs}
                meta={{
                    title: defaultTitle('Kluby sportowe'),
                }}
            />
            <AppRoute
                exact
                path={route['p.club']()}
                component={SportClubItem}
                meta={{
                    title: defaultTitle('Klub sportowy'),
                }}
            />
        </AppSwitch>
    );
};
