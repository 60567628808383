import React from 'react';
import { Dropdown } from "react-bootstrap";
import { DropdownTopbarItemToggler } from "_metronic/_partials/dropdowns";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { route } from "app/config/routes/route";
import { role } from "app/config/roles";
import useAuth from "app/hooks/useAuth";
import SymbolImage from "components/SymbolImage";
import { toAbsoluteUrl } from '_metronic/_helpers';
import {ClubIconAsset, PlayerIconAsset, TrainerIconAsset} from 'components/Assets';
import {countWithText3} from "app/utils/text";
import {name} from '_metronic/layout/components/extras/QuickUserToggler';

const DropdownUserNavigation = ({ children }) => {
    const { name:firstName, surname, username, profilePhotoUrl, subscription } = useSelector(({ auth }) => auth?.user?.data ?? {});
    const { hasRole } = useAuth();

    return (<Dropdown drop="down" alignRight>
        <Dropdown.Toggle as={DropdownTopbarItemToggler}>
            {children}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <Link to={hasRole(role['profileType.trainer']) ? route['tp.editProfile'] : route['pp.profile']} className="d-flex flex-column p-8 rounded-top">
                <div className="d-flex align-items-center">
                    <div className="symbol symbol-md mr-3 flex-shrink-0">
                        {profilePhotoUrl && <SymbolImage maxWidth={50} src={profilePhotoUrl} alt=""/>}
                    </div>
                    <div className="m-0 flex-grow-1 mr-3 minw-0">
                        <span className="text-dark font-size-h5 ellipsis">
                            {name(firstName, surname, username)}
                        </span>
                        {!!subscription && (
                            <span className={`d-flex align-items-center mt-1 font-weight-bold text-dark-50 ${subscription.isExpired ? 'opacity-50' : ''}`}>
                                <SymbolImage classNameOuter="m-0 mr-1" maxWidth={20} src={subscription.photo} alt="ikona subskrypcji" />
                                {subscription.name}
                            </span>
                        )}
                    </div>
                </div>
                {subscription?.isShowInfo && !subscription?.isAlwaysValid && (
                    <>
                        <span className="text-dark-50 mt-4">
                            {subscription.isExpired && <>Pakiet wygasł</>}
                            {!subscription.isExpired && !!subscription.daysLeft && (
                                <>
                                    <b>{countWithText3(subscription.daysLeft, 'dzień', 'dni')}</b> do końca pakietu
                                </>
                            )}
                            {!subscription.isExpired && subscription.daysLeft === 0 && (
                                <>
                                    <b>ostatni dzień</b> ważności pakietu
                                </>
                            )}
                        </span>
                        <div className="progress progress-xs mt-1">
                            <div className="progress-bar bg-danger" style={{width: `${subscription.isExpired? 0 : subscription.usagePercentage}%`}}/>
                        </div>
                    </>
                )}
            </Link>

            <div className="separator separator-solid"/>

            <div className="navi navi-spacer-x-0 pt-5">

                {/* TRAINER PANEL */}
                {hasRole(role['profileType.trainer']) && <Link
                    to={route['tp']}
                    className="navi-item px-8"
                >
                    <div className="navi-link">
                        <div className="navi-icon mr-4">
                            <SymbolImage src={TrainerIconAsset} />
                        </div>
                        <div className="navi-text">
                            <div className="font-weight-bold">
                                Panel trenera
                            </div>
                            <div className="text-muted">
                                Korzystaj z portalu jako trener
                            </div>
                        </div>
                    </div>
                </Link>}

                {/* PLAYER PANEL */}
                {(hasRole(role['profileType.player']) || hasRole(role['profileType.trainer']))
                && <Link
                    to={route['pp']}
                    className="navi-item px-8"
                >
                    <div className="navi-link">
                        <div className="navi-icon mr-4">
                            <SymbolImage src={PlayerIconAsset} />
                        </div>
                        <div className="navi-text">
                            <div className="font-weight-bold">
                                Panel sportowca
                            </div>
                            <div className="text-muted">
                                Korzystaj z portalu jako sportowiec
                            </div>
                        </div>
                    </div>
                </Link>}

                {hasRole(role['profileType.club']) && (
                    <Link
                        to={route['cp']}
                        className="navi-item px-8"
                    >
                        <div className="navi-link">
                            <div className="navi-icon mr-4">
                                <SymbolImage src={ClubIconAsset} />
                            </div>
                            <div className="navi-text">
                                <div className="font-weight-bold">
                                    Panel Klubu
                                </div>
                                <div className="text-muted">
                                    Zarządzaj swoim klubem
                                </div>
                            </div>
                        </div>
                    </Link>
                )}

                <div className="navi-separator my-3"/>

                <Link
                    to={route['index']}
                    className="navi-item px-8"
                >
                    <div className="navi-link">
                        <div className="navi-icon mr-4">
                            <SymbolImage src={toAbsoluteUrl('/assets/logo/dark/logo_traineron_notext.png')} />
                        </div>
                        <div className="navi-text">
                            <div className="font-weight-bold">
                                traineron.com
                            </div>
                            <div className="text-muted">
                                Przejdź do strony głównej portalu
                            </div>
                        </div>
                    </div>
                </Link>

                <div className="navi-footer px-8 py-5">
                    <Link
                        to={route['auth.logout']}
                        className="btn btn-light-primary font-weight-bold ml-auto"
                    >
                        Wyloguj
                    </Link>
                </div>
            </div>
        </Dropdown.Menu>
    </Dropdown>);
};

export default DropdownUserNavigation;
