import React from 'react';

export const getIcon = (name, type = 'fas') => ({ className = '', as: Container = 'i' }) =>
    <Container className={`${type} fa-${name} ${className}`} />;

export const ProfileIcon = getIcon('user-alt');
export const PlansIcon = getIcon('list-alt');
export const UserPlansIcon = getIcon('address-card');
export const UserTrainingsIcon = getIcon('running');
export const ScoreIcon = getIcon('chart-line');
export const LimitIcon = getIcon('stethoscope');
export const MeasureIcon = getIcon('ruler');
export const ProgressIcon = getIcon('tasks');

export const PanelIcon = getIcon('house-user');
export const ClientsIcon = getIcon('user-friends');
export const GroupIcon = getIcon('users');
export const TrainingIcon = getIcon('fire-alt');
export const ExerciseIcon = getIcon('dumbbell');
export const TestExerciseIcon = getIcon('stopwatch');
export const SettingsIcon = getIcon('cog');
export const TrainerIcon = getIcon('chalkboard-teacher');

export const InviteIcon = getIcon('envelope');
export const MessagesIcon = getIcon('comments');
export const NotificationIcon = getIcon('bell');
export const ModuleIcon = getIcon('columns');
export const DayIcon = getIcon('calendar-alt');

export const RestIcon = getIcon('couch');
export const DollarIcon = getIcon('dollar-sign');

export const ClubIcon = getIcon('users');
export const AchievementIcon = getIcon('trophy');
export const CertificateIcon = getIcon('certificate');
export const GalleryIcon = getIcon('th-large');
export const RateIcon = getIcon('star');
export const BlogIcon = getIcon('newspaper');
export const PlusIcon = getIcon('plus');

export const ProblemIcon = getIcon('exclamation-triangle');

export const AngryIcon = getIcon('angry');
export const SadIcon = getIcon('frown');
export const MehIcon = getIcon('meh');
export const HappyIcon = getIcon('smile');
export const GrinIcon = getIcon('grin-beam');

export const DescendingIcon = getIcon('long-arrow-alt-down')
export const AscendingIcon = getIcon('long-arrow-alt-up')
