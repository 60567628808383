

export const sortBy = (field, reverse, primer) => {
    const key = primer ?
        function(x) {
            return primer(x[field])
        } :
        function(x) {
            return x[field]
        };

    reverse = !reverse ? 1 : -1;

    return function(a, b) {
        const aa = key(a);
        const bb = key(b);
        return reverse * ((aa > bb) - (bb > aa));
    }
}