import {
    CLIPBOARD_CANCEL,
    CLIPBOARD_DAY_COPY,
    CLIPBOARD_DAY_PASTE,
    CONFIGURATOR_SET_ENTIRE_MODULE,
    CONFIGURATOR_SET_TYPE,
    DAY_CREATE,
    DAY_EDIT_BY_KEY,
    DAY_EDIT_NOTE,
    DAY_TOGGLE_DAY_TYPE,
    EXERCISE_CREATE_AT_INDEX,
    EXERCISE_DELETE,
    EXERCISE_EDIT_BY_KEY,
    EXERCISE_MOVE,
    EXERCISE_REORDER,
    EXERCISE_SERIES_SET_SERIE_VALUE,
    EXERCISE_SERIES_SET_COUNT,
    EXERCISE_SERIES_TOGGLE_ARE_THE_SAME,
    EXERCISE_TOGGLE_COLLAPSE,
    FOLDER_ALL,
    SECTION_CHANGE_DISPLAY_TYPE,
    SECTION_CREATE,
    SECTION_DELETE,
    SECTION_EDIT_BY_KEY,
    SECTION_REORDER,
    SECTION_TOGGLE_COLLAPSE,
    SET_SEARCH_EXERCISE_FOLDER,
    SET_TOOLBAR_TAB,
    SUPERSERIE_CREATE,
    SUPERSERIE_REMOVE,
    TRAINING_CREATE,
    TRAINING_DELETE,
    TRAINING_EDIT_BY_KEY,
    TRAINING_SET_MODAL_ID,
    TRAINING_TOGGLE_COLLAPSE,
    TRAINING_UPDATE_FORCE_FROM_API,
    WEEK_ADD,
    EXERCISE_SERIES_SET_SERIE_MULTIPLIER,
    TRAINING_ADD_FROM_TEMPLATE,
    EXERCISE_TOGGLE_TEST_EXERCISE,
    EXERCISE_SET_TEST_EXERCISE, EXERCISE_SERIES_SET_SERIE_MODE, EXERCISE_SERIES_SET_MATH_MODE, WEEK_REMOVE
} from "app/redux/configurator/configuratorTypes";
import {reducerSectionCreate} from "app/redux/configurator/reducers/3.section/reducerSectionCreate";
import {reducerDayCreate} from "app/redux/configurator/reducers/1.day/reducerDayCreate";
import {reducerTrainingCreate} from "app/redux/configurator/reducers/2.training/reducerTrainingCreate";
import {reducerTrainingEdit} from "app/redux/configurator/reducers/2.training/reducerTrainingEdit";
import {reducerSectionEdit} from "app/redux/configurator/reducers/3.section/reducerSectionEdit";
import {reducerExerciseCreate} from "app/redux/configurator/reducers/4.exercise/reducerExerciseCreate";
import {reducerExerciseEdit} from "app/redux/configurator/reducers/4.exercise/reducerExerciseEdit";
import {reducerExerciseMove} from "app/redux/configurator/reducers/4.exercise/reducerExerciseMove";
import {reducerExerciseReorder} from "app/redux/configurator/reducers/4.exercise/reducerExerciseReorder";
import {reducerExerciseDelete} from "app/redux/configurator/reducers/4.exercise/reducerExerciseDelete";
import {SET_TOOLBAR_SEARCH} from "app/redux/configurator/configuratorTypes";
import {reducerSetToolbarSearch} from "app/redux/configurator/reducers/reducerToolbarSearch";
import {reducerTrainingToggleCollapse} from "app/redux/configurator/reducers/2.training/reducerTrainingToggleCollapse";
import {reducerTrainingSetModal} from "app/redux/configurator/reducers/2.training/reducerTrainingSetModalId";
import {reducerExerciseToggleCollapse} from "app/redux/configurator/reducers/4.exercise/reducerExerciseToggleCollapse";
import {reducerSectionReorder} from "app/redux/configurator/reducers/3.section/reducerSectionReorder";
import {reducerTrainingDelete} from "app/redux/configurator/reducers/2.training/reducerTrainingDelete";
import {reducerSectionDelete} from "app/redux/configurator/reducers/3.section/ReducerSectionDelete";
import {reducerModuleSetEntire} from "app/redux/configurator/reducers/0.configurator/reducerModuleSetEntire";
import {reducerTrainingForceUpdateFromApi} from "app/redux/configurator/reducers/2.training/reducerTrainingForceUpdateFromApi";
import {reducerSeriesToggleAreTheSame} from "app/redux/configurator/reducers/5.series/reducerSeriesToggleAreTheSame";
import {reducerSeriesSetCount} from "app/redux/configurator/reducers/5.series/reducerSeriesSetCount";
import {reducerSeriesSetSerieValue} from "app/redux/configurator/reducers/5.series/reducerSeriesSetSerieValue";
import {reducerWeekAdd} from "app/redux/configurator/reducers/0.configurator/reducerWeekAdd";
import {reducerDayToggleType} from "app/redux/configurator/reducers/1.day/reducerDayToggleType";
import {TOOLBAR_EXERCISES} from "app/configurator/components/aside/asideUtils";
import {reducerSetToolbarTab} from "app/redux/configurator/reducers/reducerSetToolbarTab";
import {reducerDayEditNote} from "app/redux/configurator/reducers/1.day/reducerDayEditNote";
import {CONFIGURATOR_DEFAULT} from "app/config/configurator";
import {reducerSuperserieCreate} from "app/redux/configurator/reducers/4.exercise/superserie/reducerSuperserieCreate";
import {reducerSuperserieSeparate} from "app/redux/configurator/reducers/4.exercise/superserie/reducerSuperserieSeparate";
import {reducerSectionCollapseAllExercises} from "app/redux/configurator/reducers/3.section/reducerSectionCollapseAllExercises";
import {reducerSectionChangeDisplayType} from "app/redux/configurator/reducers/3.section/reducerSectionChangeDisplayType";
import {reducerClipboardDayCopy} from "app/redux/configurator/reducers/.clipboard/reducerClipboardDayCopy";
import {reducerClipboardDayPaste} from "app/redux/configurator/reducers/.clipboard/reducerClipboardDayPaste";
import {reducerClipboardCancel} from "app/redux/configurator/reducers/.clipboard/reducerClipboardCancel";
import {reducerSetSearchExerciseFolder} from "app/redux/configurator/reducers/0.configurator/reducerSetSearchExerciseFolder";
import {reducerSeriesSetSerieMultiplier} from "app/redux/configurator/reducers/5.series/reducerSeriesSetSerieMultiplier";
import {reducerTrainingAddFromTemplate} from "app/redux/configurator/reducers/2.training/reducerTrainingAddFromTemplate";
import {reducerToggleExerciseTextExercise} from "app/redux/configurator/reducers/4.exercise/reducerExerciseToggleTestExercise";
import {reducerExerciseSetTestExercise} from "app/redux/configurator/reducers/4.exercise/reducerExerciseSetTestExercise";
import {reducerSeriesSetMode} from "app/redux/configurator/reducers/5.series/reducer_SeriesToggleMode";
import {reducerSeriesMathMode} from "app/redux/configurator/reducers/5.series/reducer_SeriesMathMode";
import {reducerWeekRemove} from "app/redux/configurator/reducers/0.configurator/reducerWeekRemove";

export const initialState = () => ({
    module: {},
    //
    weeks: 2,
    days: {},
    trainings: {},
    sections: {},
    exercises: {},
    //
    errors: [],
    config: {
        duplicate: null,
        trainingModal: {
            show: false,
            // dayNo: null,
            trainingIndex: null,
        },
        isToolbarOpen: true,
        toolbarTab: TOOLBAR_EXERCISES,
        type: CONFIGURATOR_DEFAULT,
    },
    helpers: {
        itemsCount: 0,
        unsavedTrainings: [],
        trainingPlanId: null,
        moduleId: null,
        // clipboard: {
        //     scope: CLIPBOARD_COPY_SCOPE_DAY,
        //     dayNo: 2,
        // },
        clipboard: null,
    },
    toolbarSearch: '',
    filters: {
        exerciseFolder: FOLDER_ALL,
    }
});


export const configuratorReducer = ( state = initialState(), { type, payload } ) => {

    switch( type ) {
        /* ----------------
        *        MODULE
        * ----------------- */
        case CONFIGURATOR_SET_ENTIRE_MODULE:
            return reducerModuleSetEntire(state, payload);

        case CONFIGURATOR_SET_TYPE:
            return {
                ...state,
                config: {
                    ...state.config,
                    type: payload.type,
                }
            }

        case WEEK_ADD:
            return reducerWeekAdd(state);
        case WEEK_REMOVE:
            return reducerWeekRemove(state);

        /* ----------------
        *        DAY
        * ----------------- */
        case DAY_CREATE:
            return reducerDayCreate(state, payload);

        case DAY_EDIT_BY_KEY:
            return state;//TODO

        case DAY_TOGGLE_DAY_TYPE:
            return reducerDayToggleType(state, payload);

        case DAY_EDIT_NOTE:
            return reducerDayEditNote(state, payload);

        /* ----------------
        *     TRAINING
        * ----------------- */
        case TRAINING_UPDATE_FORCE_FROM_API:
            return reducerTrainingForceUpdateFromApi(state, payload);

        case TRAINING_CREATE:
            return reducerTrainingCreate(state, payload);

        case TRAINING_EDIT_BY_KEY:
            return reducerTrainingEdit(state, payload);

        case TRAINING_TOGGLE_COLLAPSE:
            return reducerTrainingToggleCollapse(state, payload);

        case TRAINING_SET_MODAL_ID:
            return reducerTrainingSetModal(state, payload);

        case TRAINING_DELETE:
            return reducerTrainingDelete(state, payload);

        case TRAINING_ADD_FROM_TEMPLATE:
            return reducerTrainingAddFromTemplate(state, payload);

        /* ----------------
        *      SECTION
        * ----------------- */
        case SECTION_CREATE:
            return reducerSectionCreate(state, payload);

        case SECTION_EDIT_BY_KEY:
            return reducerSectionEdit(state, payload);

        case SECTION_REORDER:
            return reducerSectionReorder(state, payload);

        case SECTION_DELETE:
            return reducerSectionDelete(state, payload);

        case SECTION_TOGGLE_COLLAPSE:
            return reducerSectionCollapseAllExercises(state, payload);

        case SECTION_CHANGE_DISPLAY_TYPE:
            return reducerSectionChangeDisplayType(state, payload);

        /* ----------------
        *     EXERCISE
        * ----------------- */
        case EXERCISE_CREATE_AT_INDEX:
            return reducerExerciseCreate(state, payload);

        case EXERCISE_EDIT_BY_KEY:
            return reducerExerciseEdit(state, payload);

        case EXERCISE_MOVE:
            return reducerExerciseMove(state, payload);

        case EXERCISE_REORDER:
            return reducerExerciseReorder(state, payload);

        case EXERCISE_DELETE:
            return reducerExerciseDelete(state, payload);

        case EXERCISE_TOGGLE_COLLAPSE:
            return reducerExerciseToggleCollapse(state, payload);

        case EXERCISE_TOGGLE_TEST_EXERCISE:
            return reducerToggleExerciseTextExercise(state, payload);

        case EXERCISE_SET_TEST_EXERCISE:
            return reducerExerciseSetTestExercise(state, payload);

        /* ----------------
        *     EXERCISE SUPERSERIES
        * ----------------- */

        case SUPERSERIE_CREATE:
            return reducerSuperserieCreate(state, payload);

        case SUPERSERIE_REMOVE:
            return reducerSuperserieSeparate(state, payload);

        /* ----------------
        *   EXERCISE SERIES
        * ----------------- */

        case EXERCISE_SERIES_TOGGLE_ARE_THE_SAME:
            return reducerSeriesToggleAreTheSame(state, payload);

        case EXERCISE_SERIES_SET_COUNT:
            return reducerSeriesSetCount(state, payload);

        case EXERCISE_SERIES_SET_SERIE_VALUE:
            return reducerSeriesSetSerieValue(state, payload);

        case EXERCISE_SERIES_SET_SERIE_MULTIPLIER:
            return reducerSeriesSetSerieMultiplier(state, payload);

        case EXERCISE_SERIES_SET_SERIE_MODE:
            return reducerSeriesSetMode(state, payload);

        case EXERCISE_SERIES_SET_MATH_MODE:
            return reducerSeriesMathMode(state, payload);

        /* ----------------
        *     TOOLBAR
        * ----------------- */

        case SET_TOOLBAR_SEARCH:
            return reducerSetToolbarSearch(state, payload);

        case SET_TOOLBAR_TAB:
            return reducerSetToolbarTab(state, payload);

        case SET_SEARCH_EXERCISE_FOLDER:
            return reducerSetSearchExerciseFolder(state, payload);

        /* ----------------
        *     CLIPBOARD
        * ----------------- */

        case CLIPBOARD_DAY_COPY:
            return reducerClipboardDayCopy(state, payload);

        case CLIPBOARD_DAY_PASTE:
            return reducerClipboardDayPaste(state, payload);

        case CLIPBOARD_CANCEL:
            return reducerClipboardCancel(state);

        /* ----------------
        *     DEFAULT
        * ----------------- */
        default:
            return state;

    }

}