import {reducerError} from "app/redux/configurator/reducers/reducerError";
import {joinExercises} from "app/configurator/utils/superseries/addSuperserie";
import {separateExercises} from "app/configurator/utils/superseries/removeSuperserie";

export const reducerSuperserieSeparate = (state, { sectionIndex, exerciseIndex, }) => {
    // console.log(exerciseIndex)

    const sectionExercises = state?.exercises?.[sectionIndex]?.exercises;
    if(!sectionExercises) {
        return reducerError(state, 'superserie.remove.sectionNotExist');
    }
    if(!sectionExercises?.[exerciseIndex]) {
        return reducerError(state, 'superserie.remove.exerciseNotExist');
    }

    const exercises = separateExercises(sectionExercises, exerciseIndex);
    // console.log(exercises)

    return {
        ...state,
        exercises: {
            ...state.exercises,
            [sectionIndex]: {
                ...state.exercises[sectionIndex],
                exercises,
            }
        }
    };
};