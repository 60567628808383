export const getInputClasses = (meta, result = 'form-control form-control-solid h-auto py-5 px-6 ') => {
    if (meta.touched && meta.error) {
        result += " is-invalid"
    }

    if (meta.touched && !meta.error) {
        result += " is-valid"
    }

    return result;
}

export const getTextareaClasses = (meta) => {
    let result = "form-control form-control-solid py-5 px-6 ";
    if (meta.touched && meta.error) {
        result += " is-invalid"
    }

    if (meta.touched && !meta.error) {
        result += " is-valid"
    }

    return result;
}