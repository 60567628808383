const allowedSectionKeys = ['name', 'description'];

export const isSectionKeyAllowedToEdit = (key) => {
    return allowedSectionKeys.indexOf(key) !== -1;
}


export const createSectionObj = ({
    id = null,
    name = 'Trening',
    trainingIndex = null,
    description = null,
    idLocal = null,
    sectionDisplayType = 0,
}={}) => ({
    id,
    idLocal: idLocal ?? id,
    name,
    sectionDisplayType: typeof sectionDisplayType === 'object' ? sectionDisplayType?.id : sectionDisplayType,
    trainingIndex,
    description,
    allCollapseState: true,
})