import {createTrainingObj} from "app/configurator/utils/utilsTraining";
import {createSectionObj} from "app/configurator/utils/utilsSection";
import {createExerciseObj, createReducerExerciseObj} from "app/configurator/utils/utilsExercise";
import {NO_CHANGES, UPDATE} from "components/form/enums/changeStatus";
import {reducerError} from "app/redux/configurator/reducers/reducerError";
import {reducerDayCreate} from "app/redux/configurator/reducers/1.day/reducerDayCreate";
import {reorderNo} from "app/configurator/utils/superseries/reorderNo";
import {unsavedTrainings} from "app/redux/configurator/utils/configuratorHelpers";
import {removeEmptySeries} from "app/redux/configurator/reducers/0.configurator/helpers/removeEmptySeries";
import {parseSeries} from "app/redux/configurator/reducers/0.configurator/helpers/parseSeries";

export const reducerTrainingAddFromTemplate = (state, {dayNo, training: trainingToCopy}, alreadyTriedDayCreate = false) => {
    // validation
    if(!state.days[dayNo]) {
        if(alreadyTriedDayCreate){
            return reducerError(state, 'training.create.dayNotExist.createDayFailed');
        }
        const stateWithNewlyCreatedDay = reducerDayCreate(state, { dayNo });
        return reducerTrainingAddFromTemplate(stateWithNewlyCreatedDay, { dayNo, training: trainingToCopy }, true);
    }


    let newItemsCount = state.helpers.itemsCount+1;
    const newTrainingId = `${newItemsCount}.c`;
    newItemsCount++
    // console.log(trainingToCopy)

    const training = createTrainingObj({
        id: null,
        dayNo,
        description: trainingToCopy?.description,
        name: trainingToCopy?.name,
        trainingIndex: newTrainingId,
        displayType: trainingToCopy?.trDisplayType?.id,
    });

    let sections = {};
    let sectionExercises = {};

    if(trainingToCopy?.trainingSectionTemplates && trainingToCopy?.trainingSectionTemplates.length) {
        trainingToCopy.trainingSectionTemplates.forEach(({ name, sectionDisplayType, description, trainingTemplateExercises: exercises }) => {
            const newSectionId = `${newItemsCount}.c`;
            newItemsCount++
            training.sections.push(newSectionId);
            sections = {
                ...sections,
                [newSectionId]: createSectionObj({
                    name,
                    trainingIndex:newTrainingId,
                    idLocal: newSectionId,
                    description,
                    sectionDisplayType: sectionDisplayType.id,
                }),
            };
            // console.log(
            //     { name, trainingIndex:newTrainingId, idLocal: newSectionId },
            //     createSectionObj({ name, trainingIndex:newTrainingId, idLocal: newSectionId }),
            //     sections,
            // )
            sectionExercises = {
                ...sectionExercises,
                [newSectionId]: createReducerExerciseObj(),
            };
            if(exercises && exercises.length) {
                exercises.forEach(exercise => {
                    const newExerciseId = `${newItemsCount}.c`;
                    newItemsCount++
                    const {exercise:originalExercise, series:seriesToParse, testExercise, ...exerciseParams } = exercise;
                    const {
                        id:exerciseId,
                        name:_name,
                        nameENG:_nameENG,
                        photoUrl:_photoUrl,
                        measureUnitGroup,
                        hasTestExercises,
                    } = originalExercise || {};
                    const newExercise = createExerciseObj({
                        exerciseId,
                        _name,
                        _nameENG,
                        _photoUrl,
                        _key: newExerciseId,
                        testExercise,
                        hasTestExercises,
                        changeStatus: UPDATE,
                        series: removeEmptySeries(parseSeries(measureUnitGroup,seriesToParse)),
                        ...exerciseParams,
                        id: null,
                    });
                    sectionExercises[newSectionId].exercises.push(newExercise);
                })
                sectionExercises[newSectionId].exercises = reorderNo(sectionExercises[newSectionId].exercises);
            }
            // console.log(
            //     { name, trainingIndex:newTrainingId, idLocal: newSectionId },
            //     createSectionObj({ name, trainingIndex:newTrainingId, idLocal: newSectionId }),
            //     sections,
            // )
        });
    }

    return {
        ...state,
        days: {
            ...state.days,
            [dayNo]: {
                ...state.days[dayNo],
                trainings: [
                    ...state.days[dayNo].trainings,
                    newTrainingId,
                ],
            },
        },
        trainings: {
            ...state.trainings,
            [newTrainingId]: training,
        },
        sections: {
            ...state.sections,
            ...sections,
        },
        exercises: {
            ...state.exercises,
            ...sectionExercises,
        },
        helpers: {
            ...state.helpers,
            unsavedTrainings: unsavedTrainings(state.helpers).add([ newTrainingId ]),
            itemsCount: newItemsCount,
        }
    }
}