import React from "react";
import { MessagesIcon } from "components/Icons";
import { route } from "app/config/routes/route";
import useQuery2 from "app/hooks/useQuery2";
import { PP_Messages_Info } from "app/core/api/player/messages";
import { TP_Messages_Info } from "app/core/api/trainer/messages";
import useMatchPathname from "app/hooks/useMatchPath";

const TopbarChatsUnreadBtn = ({ count, onClick }) => (
    <div className="topbar-item mr-1 cursor-pointer text-hover-dark">
        <div onClick={onClick} className="position-relative p-2">
            <MessagesIcon className="fa-2x p-0 text-dark-50" />
            {!!count && (
                <span className="badge badge-danger badge-pill py-1 px-2 position-absolute top-0 right-0">
                    {count}
                </span>
            )}
        </div>
    </div>
);
export default TopbarChatsUnreadBtn;
