import {BASE_URL} from "app/config/env";

export const routePublic = {

    // app
    'app.terms':             `${BASE_URL}/rules`,
    'app.faq':               `${BASE_URL}/faq`,
    'app.contact':           `${BASE_URL}/contact`,
    'app.privacy':           `${BASE_URL}/privacy`,
    'app.euProject':         `${BASE_URL}/eu`,
    'app.pricing':           `${BASE_URL}/pricing`,
    'app.investorRelations': `${BASE_URL}/investor-relations`,

    // training plans
    'p.trainingPlans':    `${BASE_URL}/training-plans`,
    'p.trainingPlan':     (id = ':trainingPlanId', seo = ':seo') => `${BASE_URL}/training-plans/${id}-${seo}`,
    'p.trainingPlan.try': (id = ':trainingPlanId', seo = ':seo') => `${BASE_URL}/training-plans/${id}-${seo}/try`,
    'p.trainingPlan.buy': (id = ':trainingPlanId', seo = ':seo') => `${BASE_URL}/training-plans/${id}-${seo}/buy`,
    'p.trainingPlan.buy.offer': (id = ':trainingPlanId', offerId = ':offerId') => `${BASE_URL}/training-plans/${id}/buy/${offerId}`,

    // trainers
    'p.trainers': `${BASE_URL}/trainers`,
    'p.trainer':  (id = ':trainerId', seo = ':seo') => `${BASE_URL}/trainers/${id}-${seo}`,

    // players
    'p.players': `${BASE_URL}/players`,
    'p.player':  (id = ':id', seo = ':seo') => `${BASE_URL}/players/${id}-${seo}`,

    // clubs
    'p.clubs': `${BASE_URL}/sport-clubs`,
    'p.club':  (id = ':id', seo = ':seo') => `${BASE_URL}/sport-clubs/${id}-${seo}`,

    // articles
    'p.articles': `${BASE_URL}/blog`,
    'p.article': (id = ':id', seo = ':seo') => `${BASE_URL}/blog/${id}-${seo}`,

}
