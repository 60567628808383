import React from 'react';
import PropTypes from 'prop-types';
import { getInputClasses } from "app/formik/getInputClasses";
import {useField, useFormikContext} from "formik";
import InputMask from 'react-input-mask';

const FormikField = ({ classNameOuter = '', ...rest }) => (
    <div className={`form-group ${classNameOuter}`}>
        <div>
            <FormikFieldInner {...rest} />
        </div>
    </div>
);

export const FormikFieldInner = ({
    name,
    type,
    label,
    placeholder: passedPlaceholder,
    peek,
    required,
    prefix,
    suffix,
    onEnter: handleEnter = () => {},
    onEnterSubmit,
    mask,
    className,
    ...rest
}) => {
    const [field, meta] = useField(name);
    const {submitForm, isSubmitting} = useFormikContext();

    const Input = mask ? InputMask : 'input';

    const placeholder = passedPlaceholder || label;

    const handleInputEnter = ({ key }) => {
        if(key === 'Enter') {
            handleEnter();
            if(onEnterSubmit && !isSubmitting){
                // noinspection JSIgnoredPromiseFromCall
                submitForm();
            }
        }
    }

    return (
        <>
            {label && (
                <strong>
                    {label}
                    {required && (
                        <span className="text-danger">
                            *
                        </span>
                    )}
                </strong>
            )}
            <div className="input-group">
                {prefix && (
                    <div className="input-group-prepend">
                        <span className="input-group-text">{prefix}</span>
                    </div>
                )}
                <Input
                    type={type}
                    {...field}
                    {...rest}
                    onKeyDown={handleInputEnter}
                    placeholder={placeholder || ''}
                    className={`${getInputClasses(meta)} ${className}`}
                />
                {suffix && (
                    <div className="input-group-append">
                        <span className="input-group-text">{suffix}</span>
                    </div>
                )}
                {meta.touched && meta.error && <div className="error invalid-feedback">{meta.error}</div>}
            </div>
        </>
    );
};


FormikField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    type: PropTypes.string,
};

FormikField.defaultProps = {
    type: 'text',
};

export default FormikField;
