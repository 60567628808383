import React, {useMemo} from "react";
import {AsideMenuList} from "./AsideMenuList";
import {useHtmlClassService} from "_metronic/layout/_core/MetronicLayout";
import PerfectScrollbar from 'react-perfect-scrollbar'
import styled from "styled-components";
import 'react-perfect-scrollbar/dist/css/styles.css';
import {useBuildVersion} from "app/providers/BuildVersionProvider";

const StyledAsideMenu = styled.div`
    max-height: calc(100vh - 68px);
    > div {
        height: calc(100vh - 68px) !important;
    }
`;

export const AsideMenu = () => {
  const uiService = useHtmlClassService();
  const buildVersion = useBuildVersion()
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true)
    };
  }, [uiService]);
  return (
    <>
      {/* begin::Menu Container */}
      <StyledAsideMenu
        className={`aside-menu ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        <PerfectScrollbar>
          <AsideMenuList layoutProps={layoutProps} />
          <div className="small text-black text-center">
            v{buildVersion}
          </div>
        </PerfectScrollbar>
      </StyledAsideMenu>
      {/* end::Menu Container */}
    </>
  );
}
