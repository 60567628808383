import { useQuery } from "react-query";
import { extractData } from "app/utils/query";
import { useHistory, useLocation } from "react-router-dom";
import useAllLocationParams from "app/hooks/useAllLocationParams";
import { route } from "app/config/routes/route";
import {useEffect} from "react";
import useObjectState from "app/hooks/useObjectState";

const usePaginatedQuery2 = ({
    queryKey,
    queryFn,
    config,
    defaultPageSize = 10,
    extractLevel = 2,
}) => {

    const history = useHistory();
    const params = useAllLocationParams();
    const location = useLocation();
    const [{forcePage, forceResults}, setForcePage] = useObjectState({forcePage: 1, forceResults: 1});

    const noUrlPagination = config?.noUrlPagination;

    const passedPage = params[route['get.page']] !== undefined
        ? parseInt(params[route['get.page']])
        : null;
    const page = (passedPage && passedPage > 0) ? passedPage : 1;
    const passedResults = params[route['get.resultsPerPage']] !== undefined
        ? parseInt(params[route['get.resultsPerPage']])
        : null;
    const results = (passedResults && passedResults > 0) ? passedResults : defaultPageSize;

    const setPage = ( newPage, _results = null ) => {
        const resultsCount = _results || results;
        if(noUrlPagination) {
            setForcePage({forcePage: newPage, forceResults: resultsCount});
        } else {
            const newSearch = new URLSearchParams(location.search);
            newSearch.set(route['get.page'], newPage);
            newSearch.set(route['get.resultsPerPage'], resultsCount);
            history.push({
                search: newSearch.toString(),
            });
        }
    };

    const {
        data:queryData,
        error,
        isFetching,
        status,
        latestData,
        refetch,
        resolvedData,
        isError,
        isSuccess: passedIsSuccess,
        isLoading: passedIsLoading,
        isIdle,
    } = useQuery([
            ...queryKey,
            noUrlPagination ? forcePage : page,
            noUrlPagination ? forceResults : results,
        ],
        queryFn,
        {
            ...config,
            keepPreviousData : true,
        },
    );

    const isLoading = passedIsLoading ?? isFetching;
    const isSuccess = passedIsSuccess && !isFetching;
    const loadingInfo = { isLoading, isError, error };

    const { data = [], pagedData = {}, ...rest } = extractData(queryData, extractLevel);

    useEffect(() => {
        if ((passedPage !== null && (!passedPage || passedPage < 1)) ||
            (passedResults !== null && (!passedResults || passedResults < 1))) {
            setPage(page, results);
            return;
        }
        if (pagedData?.pageNumber && pagedData?.pages && pagedData.pageNumber > pagedData.pages) {
            setPage(pagedData.pages);
            return;
        }
    }, []);

    return {
        data,
        restData: rest,
        pagedData,
        setPage,
        latestData,
        resolvedData,
        loadingInfo,
        error,
        status,
        refetch,
        isError,
        isSuccess,
        isLoading,
        isFetching,
        passedIsSuccess,
        passedIsLoading,
        isIdle,
    };
}

export default usePaginatedQuery2;
