import {errorHandler} from "app/core/errorHandler";

export const validateSeries = (measureUnitGroup, series) => {
    // measureUnitGroup not exist
    if(!measureUnitGroup) {
        errorHandler({
            expected: 'measureUnitGroup != null',
            got: measureUnitGroup,
            file: '0.configurator/helpers/validateSeries.js',
        });
        return false;
    }
    // measureUnitGroup.items not exist
    if(!measureUnitGroup?.items) {
        errorHandler({
            expected: 'measureUnitGroup.items != null',
            got: measureUnitGroup?.items,
            file: '0.configurator/helpers/validateSeries.js',
        });
        return false;
    }
    // get ids from measureUnitGroup.items
    const ids = measureUnitGroup.items.map(({id}) => id);

    // series not exist
    if(!series) {
        errorHandler({
            expected: 'series != null',
            got: series,
            file: '0.configurator/helpers/validateSeries.js',
        });
        return false;
    }
    // list of not matching ids (series -> measureUnitGroup)
    let errors = [];
    // get every item from series
    for(let i=0;i<series.length; i++){
        // if series not have items -> error
        if(!series[i]?.items) {
            errorHandler({
                expected: `series[${i}].items != null`,
                got: series[i]?.items,
                file: '0.configurator/helpers/validateSeries.js',
            });
            return false;
        }
        // check every series ever row item if match ids
        for(let j=0; j<series[i].items.length; j++) {
            if(ids.indexOf(series[i].items[j]?.measureUnitOption?.id) === -1) {
                // errors.push({serieNo: i, rowNo: j, idSerieRow: series[i].items[j]?.id});
            }
        }
    }
    if(errors.length) {
        console.error(errors)
        errorHandler({
            expected: 'series[i].items[j]?.id to be in measureUnitGroup.items[k].id',
            got: null,
            file: '0.configurator/helpers/validateSeries.js',
            json: {
                measureUnitGroupItemsIds: ids,
                errors,
            },
        });
    }
    return true;
}