import React from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {route} from "app/config/routes/route";
import DropdownUserNavigation from "templates/includes/I_DropdownUserNavigation";
import useAuth from "app/hooks/useAuth";
import SymbolImage from "components/SymbolImage";

export const name = (name, surname, username) => {
    if(name) return `${name??''} ${surname??''}`;
    if(username && username.indexOf('@')<username.indexOf('.') && username.indexOf('.')>0) {
        return username.substr(0, username.indexOf('@'));
    }
    return username;
}
const sizeClasses = ['wcag-xsmall', 'wcag-small', 'wcag-normal', 'wcag-large', 'wcag-xlarge'];
const getLvlOfFontSize = () => {
    for(let i=0; i<sizeClasses.length; i++) {
        // console.log(i, sizeClasses[i], document.documentElement.classList.contains(sizeClasses[i]))
        if(document.documentElement.classList.contains(sizeClasses[i])) {
            return i;
        }
    }
    return 2;
}

const QuickUserToggler = () => {
    const { user } = useSelector(({ auth }) => auth);
    const { isLoggedIn } = useAuth();
    const handleClickFontSmall = () => {
        const lvl = getLvlOfFontSize();
        console.log(lvl)
        if(lvl-1>=0) {
            document.documentElement.classList.remove(sizeClasses[lvl]);
            document.documentElement.classList.add(sizeClasses[lvl-1]);
        }
    }
    const handleClickFontBig = () => {
        const lvl = getLvlOfFontSize();
        console.log(lvl)
        if(lvl+1<sizeClasses.length) {
            document.documentElement.classList.remove(sizeClasses[lvl]);
            document.documentElement.classList.add(sizeClasses[lvl+1]);
        }
    }
    const handleClickNormalSize = () => {
        const lvl = getLvlOfFontSize();
        document.documentElement.classList.remove(sizeClasses[lvl]);
    }
    const handleClickHighContrast = () => {
        document.documentElement.classList.toggle('wcag-high-contrast');
    }

    return (<>
        <div className="topbar-item">
            {isLoggedIn() && <>
                <DropdownUserNavigation>
                    <div className="w-auto d-flex align-items-center px-2 btn btn-clean">
                        <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
                            {name(user?.data?.name, null, user?.data?.username)}
                        </span>
                        <span className="symbol symbol-35">
                            <span className="font-size-h5 font-weight-bold">
                                {user.data.profilePhotoUrl && <SymbolImage maxWidth={35} className="rounded" src={user.data.profilePhotoUrl} alt=""/>}
                            </span>
                        </span>
                    </div>
                </DropdownUserNavigation>
            </>}
            <div>
                <span onClick={handleClickNormalSize} className="cursor-pointer">
                    <span className="badge badge-sm d-none badge-secondary small-60 wcag-xsmall">80%</span>
                    <span className="badge badge-sm d-none badge-secondary small-60 wcag-small">90%</span>
                    <span className="badge badge-sm d-none badge-secondary small-60 wcag-large">110%</span>
                    <span className="badge badge-sm d-none badge-secondary small-60 wcag-xlarge">120%</span>
                </span>
                <span onClick={handleClickFontSmall} className="font-size-h5 px-1 cursor-pointer">A</span>
                <span onClick={handleClickFontBig} className="font-size-h1 px-1 cursor-pointer">A</span>
                <i onClick={handleClickHighContrast} className="fas fa-adjust mx-1 fa-2x cursor-pointer"/>
            </div>
            {!isLoggedIn() && <>
                <Link to={route['auth.login']} className="btn btn-icon w-auto btn-light d-flex align-items-center px-5 mx-1">
                    Zaloguj
                </Link>
                <Link to={route['auth.register']} className="btn btn-icon w-auto btn-primary d-flex align-items-center px-5 mx-1">
                    Zarejestruj
                </Link>
            </>}
        </div>
    </>);
}

export default QuickUserToggler;
