import {reorderNo} from "app/configurator/utils/superseries/reorderNo";

export const joinExercises = (passedExercises, index) => {
    const exercises = [...passedExercises];
    const exercisesToJoin = exercises.splice(index, 2);
    const joinMethod = canBeJoin(exercisesToJoin);
    if(!joinMethod) {
        return passedExercises;
    }
    let [prev, next] = exercisesToJoin;
    const basePrev = Math.floor(prev.orderNo/10)*10;
    const baseNext = Math.floor(next.orderNo/10)*10;
    //
    // console.log(basePrev, baseNext, prev.orderNo, prev.orderNo/10, Math.floor(prev.orderNo/10), next.orderNo, next.orderNo/10, Math.floor(next.orderNo/10))
    // console.log(joinMethod)
    switch (joinMethod) {
        case 'singleNext':
            next.orderNo = basePrev+9;
            prev._superseries.isLast = false;
            console.log(basePrev, next.orderNo)
            break;
        case 'singlePrev':
            prev.orderNo = baseNext+1;
            prev._superseries.isLast = false;
            console.log(baseNext, prev.orderNo)
            break;
        case 'singleBoth':
            prev.orderNo = basePrev+1;
            prev._superseries.isLast = false;
            next.orderNo = basePrev+2;
            // console.log(prev.orderNo)
            // console.log(next.orderNo)
            break;
        case 'merge':
        default:
            alert('Nieznany błąd: funkcja niedostępna');
            break;
    }
    // console.log(exercises.map(({orderNo:a})=>a))
    exercises.splice(index, 0, prev, next);
    // console.log(exercises.map(({orderNo:a})=>a))
    // const a = reorderNo(exercises);
    // console.log(a.map(({orderNo:d})=>d));
    return reorderNo(exercises);
};


const canBeJoin = (e) => {
    if(e.length!==2) {
        return false;
    }
    const [prev, next] = e;
    const sizePrev = prev?._superseries?.superserieSize;
    const sizeNext = next?._superseries?.superserieSize;
    //
    if(sizePrev+sizeNext > 9) {
        return false;
    }
    //
    if(sizePrev>1 && sizeNext>1) {
        return 'merge';
    }
    if(sizePrev>1) {
        return 'singleNext';
    }
    if(sizeNext>1) {
        return 'singlePrev';
    }
    return 'singleBoth';
}