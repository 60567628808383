import React, {useContext, useEffect, useMemo, useRef} from "react";
import {useSelectToken} from "app/redux/auth/selectors";
import {messagesHub} from "app/providers/signalR/hubs/messagesHub";


const SignalRContext = React.createContext({
  messagesHub,
});

export const SignalRProvider = ({children}) => {

  const connectionsRef = useRef({});
  const tokenRef = useRef(null);

  // -- TOKEN --
  const token = useSelectToken();
  useEffect(() => {
    tokenRef.current = token;
  }, [token]);

  // ----- create connection hubs -----
  useMemo(() => {// as componentWillMount
    // -- messages Hub --
    try {
      connectionsRef.current.messagesHub = messagesHub(tokenRef, token);
    } catch(e) {
      // console.log('err,', 1);
    }
  }, []);

  // ----- destroy connection hubs -----
  useEffect(() => () => {// as componentWillUnmount
    try {
      connectionsRef.current.messagesHub.stop().then(() => console.log('messageHub connection stopped'))
    } catch (e) {
      // console.log('err,', 2);
    }
  }, []);

  return (
    <SignalRContext.Provider value={{connectionsRef}}>
      {children}
    </SignalRContext.Provider>
  );
}

export const useSignalRConnections = () => {
  const {connectionsRef} = useContext(SignalRContext);
  return connectionsRef;
}
