import {takeEvery, put, select} from "redux-saga/effects";
import {
    EXERCISE_CREATE_AT_INDEX,
    EXERCISE_DELETE,
    EXERCISE_EDIT_BY_KEY,
    EXERCISE_MOVE,
    EXERCISE_REORDER,
    SECTION_CREATE,
    SECTION_DELETE,
    SECTION_EDIT_BY_KEY,
    SECTION_REORDER, TRAINING_CREATE, TRAINING_DELETE, TRAINING_EDIT_BY_KEY
} from "app/redux/configurator/configuratorTypes";
import {
    dispatchTrainingForceUpdateFromApi,
    dispatchTrainingSetIsUpdating
} from "app/redux/configurator/actions/actions.2.Training";
import {
    selectAllExercises,
    selectAllSections,
    selectTraining, selectTrainingPlanInfo,
    selectUnsavedTrainings
} from "app/configurator/utils/utilsReduxUseSelector";
import {storeTrainingToApi} from "app/configurator/utils/utilsApi";
import {SaveConfiguratorTraining} from "app/core/api/trainer/configurator";

function* forceUpdateTraining( trainingIndex ) {
    // const training  = yield select(selectTraining(trainingIndex));
    // const sections  = yield select(selectAllSections());
    // const exercises = yield select(selectAllExercises());
    // const { trainingPlanId, moduleId } = yield select(selectTrainingPlanInfo());
    // if(!training) {
    //     console.error('Training not found'); //TODO: add error message to user
    //     return
    // }
    // if(!training.isUpdating) {
    //     yield put(dispatchTrainingSetIsUpdating(trainingIndex, true));
    //     const objToApi = yield storeTrainingToApi( training, sections, exercises );
    //     try {
    //         const response = yield PostConfiguratorDay(objToApi, trainingPlanId, moduleId, training.dayNo);
    //         yield put(dispatchTrainingForceUpdateFromApi(training.dayNo, trainingIndex, response.data));
    //     } catch (e) {
    //         console.error(e); // TODO: add error to user
    //     }
    //     console.log(trainingIndex,training);
    // }
}

function* updateTrainings() {
    // try {
    //     const unsavedTrainings = yield select(selectUnsavedTrainings());
    //     for(let i=0 ; unsavedTrainings.length-i>2 ; i++){
    //         const firstTrainingIndexToChange = unsavedTrainings[i];
    //         yield forceUpdateTraining(firstTrainingIndexToChange);
    //     }
    // } catch (e) {
    //     console.error('update trainings failed', e);
    // }

    //TODO: add isUpdating
    //TODO: send training to backend
    //TODO: run force update training
}

export function* updateTrainingSaga() {
    const pattern = [
        EXERCISE_CREATE_AT_INDEX, EXERCISE_DELETE, EXERCISE_EDIT_BY_KEY, EXERCISE_MOVE, EXERCISE_REORDER,
        SECTION_CREATE, SECTION_DELETE, SECTION_EDIT_BY_KEY, SECTION_REORDER,
        TRAINING_CREATE, TRAINING_DELETE, TRAINING_EDIT_BY_KEY,
    ];
    yield takeEvery( pattern, updateTrainings );
}