import PropTypes from 'prop-types';
import { useQuery } from "react-query";
import { extractData } from "app/utils/query";
import {useEffect, useState} from "react";

const useQuery2 = ({
    queryKey,
    queryFn,
    config,
    extractLevel = 2,
    isArray = false,
    isHTML,
}) => {

    const {
        data: queryData,
        error,
        status,
        refetch,
        isError,
        isSuccess,
        isLoading,
        isFetching,
        isPreviousData,
    } = useQuery(
        queryKey,
        queryFn,
        config,
    );

    const loadingInfo = { isLoading, isError, isSuccess, error };

    const extractedData = isHTML
        ? queryData
        : extractData(queryData, extractLevel, isArray ? [] : {});

    const data = isArray
        ? extractedData
        : isHTML
            ? extractedData?.data
            : { ...extractedData, loadingInfo, isLoading };

    return {
        data,
        error,
        status,
        refetch,
        loadingInfo,
        isError,
        isSuccess,
        isLoading,
        isFetching,
        isPreviousData,
    };
}

useQuery2.propTypes = {
    queryFn: PropTypes.func.isRequired,
    queryKey: PropTypes.array.isRequired,
}

export default useQuery2;
