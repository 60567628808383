import {reducerError} from "app/redux/configurator/reducers/reducerError";
import {allowedKeys} from "app/configurator/utils/utilsExercise";
import {unsavedTrainings} from "app/redux/configurator/utils/configuratorHelpers";


export const reducerExerciseEdit = (state, { sectionIndex, exerciseIndex, property, newValue }) => {
    if(!state.exercises?.[sectionIndex]?.exercises?.[exerciseIndex]) {
        return reducerError(state, 'exercise.edit.exerciseNotExist');
    }
    if(allowedKeys.indexOf(property) === -1) {
        return reducerError(state, 'exercise.edit.propertyNotAllowed');
    }
    return {
        ...state,
        exercises: {
            ...state.exercises,
            [ sectionIndex ]: {
                ...state.exercises[sectionIndex],
                exercises: [
                    ...state.exercises[sectionIndex].exercises.slice(0, exerciseIndex),
                    {
                        ...state.exercises[sectionIndex].exercises[exerciseIndex],
                        [property]: newValue,
                    },
                    ...state.exercises[sectionIndex].exercises.slice(exerciseIndex+1),
                ],
            },
        },
        helpers: {
            ...state.helpers,
            unsavedTrainings: unsavedTrainings(state.helpers).add([state.sections[sectionIndex].trainingIndex]),
        },
    }
}