import {
    LAYOUT_MODE_PUBLIC,
    SET_LAYOUT_MODE,
    SET_ASIDE_MINIMIZE,
    TOGGLE_ASIDE_MOBILE_OPEN
} from "app/redux/app/appActionTypes";

export const ASIDE_MAXIMIZED = 1;
export const ASIDE_MAXIMIZED_TMP = 2;
export const ASIDE_MINIMIZED = -1;
export const ASIDE_MINIMIZED_TMP = -2;

const initialState = {
    layout: {
        layoutMode: LAYOUT_MODE_PUBLIC,
        isAsideMinimized: ASIDE_MAXIMIZED,
        isAsideMobileOpen: false,
    },
}


export const appReducer = ( state = initialState, {type, payload}) => {

    switch(type) {
        case SET_LAYOUT_MODE:
            return {
                ...state,
                layout: {
                    ...state.layout,
                    layoutMode: payload.layoutMode,
                },
            };
        case SET_ASIDE_MINIMIZE: {
            return {
                ...state,
                layout: {
                    ...state.layout,
                    isAsideMinimized: payload,
                },
            };
        }
        case TOGGLE_ASIDE_MOBILE_OPEN: {
            return {
                ...state,
                layout: {
                    ...state.layout,
                    isAsideMobileOpen: !state.layout.isAsideMobileOpen,
                },
            };
        }
        default:
            return state;
    }

}
