
export const addSlashesToUrl = (url) => {
    // eslint-disable-next-line no-useless-escape
    if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
        url = "//" + url;
    }
    return url;
}

export const addHttpsToUrl = (url) => {
    // eslint-disable-next-line no-useless-escape
    if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
        url = "https://" + url;
    }
    return url;
}