import React from 'react';
import PropTypes from 'prop-types';
import { useField } from "formik";
import { getTextareaClasses } from "app/formik/getInputClasses";

export const FormikTextareaInner = ({ label, name, required, className, ...props }) => {
    const [field, meta] = useField(name);
    return (
        <>
            {label && <strong>
                {label}
                {required && <span className="text-danger">*</span>}
            </strong>}
            <textarea
                {...field}
                className={`${getTextareaClasses(meta)} ${className}`}
                placeholder={label}
                {...props}
            />
            {meta.touched && meta.error && <div className="error invalid-feedback">{meta.error}</div>}
        </>
    );
}

const FormikTextarea = ({ name, label, required, className, innerClassName, ...props }) => (
    <div className={`form-group ${className || ''}`}>
        <div>
            <FormikTextareaInner
                {...{name, label, required, className: innerClassName, ...props,}}
            />
        </div>
    </div>
);

FormikTextarea.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
};

export default FormikTextarea;
