import React from 'react';
import {AppSwitch} from "app/core/router/components/AppSwitch";
import {AppRoute} from "app/core/router/components/AppRoute";
import {route} from "app/config/routes/route";
import {defaultTitle} from "app/config/seo";
import AuthRoute from "app/core/router/components/AuthRoute";

const Login          = React.lazy(() => import('views/pages/core/auth/Login'));
const Register       = React.lazy(() => import('views/pages/core/auth/Register'));
const ForgotPassword = React.lazy(() => import('views/pages/core/auth/ForgotPassword'));
const ResetPassword  = React.lazy(() => import('views/pages/core/auth/ResetPassword'));
const Logout         = React.lazy(() => import('views/pages/core/auth/Logout'));

export const Auth = () => {
    return (
        <AppSwitch>
            <AuthRoute
                exact
                path={route['auth.login']}
                component={Login}
                meta={{
                    title: defaultTitle('Logowanie')
                }}
            />
            <AuthRoute
                exact
                path={[
                    route['auth.register'],
                    route['auth.register.player'],
                    route['auth.register.trainer'],
                ]}
                component={Register}
                meta={{
                    title: defaultTitle('Rejestracja')
                }}
            />
            <AuthRoute
                exact
                path={route['auth.password.forgot']}
                component={ForgotPassword}
                meta={{
                    title: defaultTitle('Przypomnienie hasła')
                }}
            />
            <AuthRoute
                exact
                path={route['auth.password.reset']}
                component={ResetPassword}
                meta={{
                    title: defaultTitle('Resetowanie hasła')
                }}
            />
            <AppRoute
                exact
                path={route['auth.logout']}
                component={Logout}
                meta={{
                    title: defaultTitle('Wylogowanie')
                }}
            />
        </AppSwitch>
    );
};