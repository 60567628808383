import axios from "axios";
import {BASE_URL_API} from "app/config/env";

//export const REQUEST_PASSWORD_URL = `/auth/forgot-password`;//TODO REQUEST_PASSWORD_URL

export const loginPromise = (data) => axios({
    method: 'POST',
    url: `${BASE_URL_API}/v1/auth/login`,
    data,
});

export const registerPromise = (data, type) => axios({
    method: 'POST',
    url: type === 'players'
        ? `${BASE_URL_API}/v1/auth/register-player`
        : `${BASE_URL_API}/v1/auth/register-trainer`,
    data,
});

export const fetchLoggedUserPromise = () => axios({
    method: 'GET',
    url: `${BASE_URL_API}/v1/auth/me`,
});
