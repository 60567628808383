
export const reducerError = (state, errorMsg) => {
    // console.log(errorMsg)
    return {
        ...state,
        errors:[
            ...state.errors,
            errorMsg,
        ],
    }
}