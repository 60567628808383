import {DISPLAY_TYPE_EXERCISES, isTrainingKeyAllowedToEdit} from "app/configurator/utils/utilsTraining";
import {reducerError} from "app/redux/configurator/reducers/reducerError";
import {unsavedTrainings} from "app/redux/configurator/utils/configuratorHelpers";

export const reducerTrainingEdit = (state, { trainingIndex, property, newValue }) => {
    if(!state.trainings[trainingIndex]) {
        return reducerError(state, 'training.edit.trainingNotExist');
    }
    if(!isTrainingKeyAllowedToEdit(property)) {
        return reducerError(state, 'training.edit.propertyNotAllowed');
    }

    return {
        ...state,
        trainings: {
            ...state.trainings,
            [trainingIndex]: {
                ...state.trainings[trainingIndex],
                [property]: newValue,
            },
        },
        helpers: {
            ...state.helpers,
            unsavedTrainings: unsavedTrainings(state.helpers).add([ trainingIndex ]),
        }
    }
};