import {route} from "app/config/routes/route";

const COMMON_MY_ACCOUNT           = 1;
const COMMON_TRAINING_PLAN        = 2;
const COMMON_TRAINING_PLAN_MODULE = 3;
const PP_TRAINER_INVITE = 20;
const TP_CLIENT_INVITE  = 21;
const TP_SUBSCRIPTION   = 30;
const COMMON_ORDER      = 40;
const SHOW_PROFILE      = 50;

export const useNotificationRedirect = (panelType) => {
    const isPlayerPanelType = panelType==='pp';
    const notificationRedirect = ({id}, entityId, entity1Id) => {
        switch (id) {

            case COMMON_MY_ACCOUNT:
                if(isPlayerPanelType) return route['pp.account'];
                return route['tp.account'];

            case COMMON_TRAINING_PLAN:
                if(isPlayerPanelType) return route['pp.myTrainingPlan'](entityId);
                return route['tp.clientTrainingPlan'](entityId);

            case COMMON_TRAINING_PLAN_MODULE:
                if(isPlayerPanelType) return route['pp.myTrainingPlan.module'](entity1Id, entityId);
                return route['tp.clientTrainingPlan.module'](entity1Id, entityId);

            case COMMON_ORDER:
                if(isPlayerPanelType) return route['pp.order'](entityId);
                return route['tp.order'](entityId);

            case PP_TRAINER_INVITE:
                return route['pp.playerTrainers.invites'];

            case TP_SUBSCRIPTION:
                return route['tp.account.basicInfo'];

            case SHOW_PROFILE:
                if(isPlayerPanelType) return route['pp.profile'];
                return route['tp.editProfile'];

            default:
                if(isPlayerPanelType) return route['pp.dashboard'];
                return route['tp.dashboard'];
        }
    }
    return notificationRedirect;
}
