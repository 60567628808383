import {reducerError} from "app/redux/configurator/reducers/reducerError";

export const reducerSectionCollapseAllExercises = ( state, { sectionIndex } ) => {
    if(!state.sections[sectionIndex]) {
        return reducerError(state, 'section.toggleCollapseAll.sectionNotExist');
    }
    if(!state.exercises[sectionIndex]) {
        return reducerError(state, 'exercises.toggleCollapseAll.sectionNotExist');
    }

    const newCollapseState = !state.sections[sectionIndex].allCollapseState;

    return {
        ...state,
        sections: {
            ...state.sections,
            [sectionIndex]: {
                ...state.sections[sectionIndex],
                allCollapseState: newCollapseState,
            }
        },
        exercises: {
            ...state.exercises,
            [sectionIndex]: {
                ...state.exercises[sectionIndex],
                exercises: [
                    ...state.exercises[sectionIndex].exercises.map(
                        ({isCollapsed, ...exercise}) => ({...exercise, isCollapsed: newCollapseState})
                    )
                ],
            },
        },
    };
}