export const noRefresh = {
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
};

export const extractData = (data, level=2, defaultData = {}) => {
    if (!data || level < 1) return defaultData;
    if (level === 1) return data;
    return extractData(data?.data, level - 1, defaultData);
};

