import {SERIE_MODE_DEFAULT, SERIE_MODE_MATH} from "app/redux/configurator/reducers/0.configurator/helpers/parseSeries";

export const createEmptySerieObj = () => {
    return {
        header: {},
        serieRows: [],
        serieRowsDeletedIds: [],
        areSerieRowsTheSame: false,
        serieRowsCount: 0,
        missingTestExercises: {},
    }
}

const isSeriesSameAsFirst = (first, current) => {
    if(first.countStr != current.countStr) return false;
    if(first.unitId   != current.unitId)   return false;
    if(first.mode     != current.mode)     return false;
    if(first.mode === SERIE_MODE_MATH) {
        if(first.mathMode.testExerciseId      != current.mathMode.testExerciseId)      return false;
        if(first.mathMode.dynamicOperationStr != current.mathMode.dynamicOperationStr) return false;
    }
    return true
}

export const checkSeriesTheSame = (seriesObj) => {
    if(seriesObj.serieRows.length<=1){
        return {
            ...seriesObj,
            areSerieRowsTheSame: true,
        };
    }
    for(let i=0; i<seriesObj?.headerOrder?.length; i++) {
        let first = seriesObj?.serieRows?.[0]?.items[seriesObj?.headerOrder?.[i]];
        for(let j=1;j<seriesObj?.serieRowsCount; j++) {
            // noinspection EqualityComparisonWithCoercionJS
            let current = seriesObj?.serieRows?.[j]?.items[seriesObj.headerOrder?.[i]];
            if(!isSeriesSameAsFirst(first, current)){
                return {
                    ...seriesObj,
                    areSerieRowsTheSame: false,
                };
            }
        }
    }
    return {
        ...seriesObj,
        areSerieRowsTheSame: true,
    };
}

export const createEmptySerieRow = (
    rowIds = [],
    defaultUnits = [],
    defaultMultiplier = [],
    id = null,
    description = '',
    defaultUnitCode = []
) => {
    let items = {};
    rowIds.forEach((id, index) => {
        items[id] = createEmptySerieRowItem(defaultUnits?.[index], defaultMultiplier?.[index], defaultUnitCode[index]);
    });
    return {
        id,
        description,
        items,
    }
}

export const createEmptySerieRowItem = (defaultUnitId = null, defaultMultiplier = 1, unitCode=null) => {
    return {
        id: null,
        countStr: null,
        multiplier: defaultMultiplier,
        unitId: defaultUnitId,
        unitCode,
        mode: SERIE_MODE_DEFAULT,
        mathMode: {},
    }
}
