import {useEffect} from "react";

export const useSignalREvent = (connection, event, callback) => {

  useEffect(() => {
    if(connection.connectionState === 'Disconnected') {
      connection.start().then(()=>console.log('connection started'));
    }
    // register event
    connection.on(event, callback);
    return () => {
      // unregister event
      connection.off(event, callback);
    }
  }, []);
}
