import React, {useEffect, useState} from "react";
import useQuery2 from "app/hooks/useQuery2";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {FetchConfiguratorModule} from "app/core/api/trainer/configurator";
import {validateApiToStore} from "app/configurator/utils/utilsApi";
import {dispatchSetEntireModule} from "app/redux/configurator/actions/actions.0.Configurator";
import Loading from "components/Loading";
import SaveTrainingButton from "app/configurator/components/trainingModal/0.base/ModalTrainingSave";
import {EditModule} from "app/configurator/components/calendar/0.base/EditModule";
import {CalendarSaveModuleTrainings} from "app/configurator/components/calendar/0.base/CalendarSaveModuleTrainings";
import QueryError from "components/QueryError";
import {useBreadcrumb} from "app/providers/BreadcrumbProvider";
import {CONFIGURATOR_SINGLE_TRAINING} from "app/config/configurator";


const GetModuleContext = React.createContext();

const DNDBreadcrumbHeader = ({trainingId, trainingPlanId, moduleId, refetch}) => {
    if(trainingId) return <SaveTrainingButton trainingId={trainingId} trainingPlanId={trainingPlanId} moduleId={moduleId}/>;
    return <>
        <EditModule trainingPlanId={trainingPlanId} moduleId={moduleId} />
        <CalendarSaveModuleTrainings refetch={refetch} trainingPlanId={trainingPlanId} moduleId={moduleId} />
    </>;
};

const defaultTrainingEmptyModule = (training) => ({
    id: null,
    moduleNo: null,
    name: null,
    days: [
        {
            id: null,
            dayNo: -1,
            trainings: [
                training,
            ],
        },
    ],
});

const renameTrainingProperties = ({trainingSectionTemplates, ...training}) => ({
    ...training,
    sections: trainingSectionTemplates.map(({trainingTemplateExercises, ...section}) => ({
        ...section,
        exercises: trainingTemplateExercises,
    })),
});

//
export const GetModuleProvider = ({
    children,
    isFetchingExercises,
    statusExercises,
    errorExercises,
    breadcrumbItems,
    type,
}) => {

    const { trainingPlanId, moduleId, trainingId } = useParams();
    const dispatch = useDispatch();

    const {
        data,
        error,
        refetch,
        ...rest
    } = useQuery2({
        queryKey: [
            'configurator.entireModule.get',
            type,
            type === CONFIGURATOR_SINGLE_TRAINING ? trainingId : trainingPlanId,
            moduleId
        ],
        queryFn: FetchConfiguratorModule,
        config: {
            onSuccess: ({ data }) => setData(data),
        },
    });
    const [isDataLoaded, setDataIsLoaded] = useState(false); // we can use isFetching but we can add autoRefetch data in the future

    const setData = (passedModule) => {
        // console.log(passedModule)
        let module;
        if(type === CONFIGURATOR_SINGLE_TRAINING) {
            module = defaultTrainingEmptyModule(passedModule);
            // console.log(module)
        } else {
            module = passedModule;
        }
        setDataIsLoaded(true);
        if(validateApiToStore(module)) {
            dispatch(dispatchSetEntireModule(trainingPlanId, moduleId, module));
        } else {
            console.error('Validation failed'); // TODO: add error message to user
        }
    }

    const { setBreadcrumb } = useBreadcrumb(breadcrumbItems(trainingPlanId, moduleId, trainingId));

    useEffect(() => {
        const name = data?.name??undefined;
        const planName = data?.trainingPlan?.name??undefined;
        const buttons = isDataLoaded && statusExercises==='success' ? <DNDBreadcrumbHeader refetch={refetch} trainingPlanId={trainingPlanId} moduleId={moduleId} trainingId={trainingId}/> : null;
        setBreadcrumb({items: breadcrumbItems(trainingPlanId, moduleId, trainingId, name, planName), buttons});
    }, [ data?.name, data?.trainingPlan?.name, trainingId, isDataLoaded, statusExercises])

    return (
        <GetModuleContext.Provider value={{ refetch }}>
            {isDataLoaded ? (
                <>
                    {statusExercises!=='success' ? (
                        isFetchingExercises ? (
                            <Loading/>
                        ) : (
                            <div className="alert alert-danger">{typeof errorExercises === 'object' ? 'Error' : errorExercises}</div>
                        )
                    ) : (
                        children
                    )}
                </>
            ) : (
                error ? (
                    <QueryError error={error} {...rest}/>
                ) : (
                    <Loading/>
                )
            )}
        </GetModuleContext.Provider>
    )
}

export const useGetConfiguratorModule = (strict=true) => {
    const context = React.useContext(GetModuleContext);

    if(context === undefined && strict) {
        throw new Error('useGetConfiguratorModule must be used within ConfiguratorProvider');
    }

    const { refetch } = context||{};

    return {
        refetch,
    };
}
