import React from 'react';
import FormikSearch from "components/form/search/FormikSearch";
import {MODULE_DISPLAY_TYPE_OPTIONS} from "app/enums/trainingPlan/enumModuleDisplayType";
import FormikOptionWithSubtitle from "components/form/search/FormikOptionWithSubtitle";

export const FormikShowAlgorithm = () => {
    return (
        <div className="form-group">
            <FormikSearch
                name="showAlgorithm"
                label="Algorytm wyświetlania"
                placeholder="Wybierz algorytm..."
                options={MODULE_DISPLAY_TYPE_OPTIONS}
                optionFormat={FormikOptionWithSubtitle}
            />
        </div>
    );
};
