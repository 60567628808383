import React, {useEffect} from 'react';
import { Route, Redirect } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { role } from "app/config/roles";
import { route } from "app/config/routes/route";
import useAllLocationParams from "app/hooks/useAllLocationParams";
import useAuth from "app/hooks/useAuth";
import {AppRoute} from "app/core/router/components/AppRoute";
import {actionLogout} from "app/redux/auth/actionTypes";

/*
*
* This type of <Route/> is used only in /login and /register pages
* route detects userType (Trainer, Player, Admin, etc.) and redirect to their panel.
* If "?redirect=" is provided this component will redirect user to this location after login.
*
* */
const AuthRoute = (props) => {
    const { user, authToken } = useSelector(({ auth }) => auth);
    const { redirect } = useAllLocationParams();
    const { hasRole } = useAuth();

    if (!authToken || !user?.data?.roles)
        return <AppRoute {...props} />;

    if (redirect)
        return <Redirect to={redirect} />

    if (hasRole(role['profileType.trainer']))
        return <Redirect to={route['redirect.afterLogin.trainer']} />

    if (hasRole(role['profileType.player']))
        return <Redirect to={route['redirect.afterLogin.player']} />

    return <Redirect to={route['redirect.afterLogin']} />
};

AuthRoute.propTypes = {...Route.propTypes};

export default AuthRoute;
