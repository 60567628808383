import {BASE_URL} from "app/config/env";

export const routeConfig = {
    '/':     `${BASE_URL}/`,
    'index': `${BASE_URL}/`,

    // GET params
    'get.page':           'page',
    'get.resultsPerPage': 'results',

    // errors
    'error.unauthorized': '',
}