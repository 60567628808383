import React, {useEffect, useState} from "react";
import QuickUserToggler from "../extras/QuickUserToggler";
import useAuth from "app/hooks/useAuth";
import { route } from "app/config/routes/route";
import { role } from "app/config/roles";
import useMatchPathname from "app/hooks/useMatchPath";
import TopbarChatsUnreadBtn from "views/pages/common/chats/extra/TopbarChatsUnreadBtn";
import TopbarNotificationUnreadBtn from "views/pages/common/notifications/extra/TopbarNotificationUnreadBtn";
import TopbarNotificationsFlyout from "views/pages/common/notifications/extra/TopbarNotificationsFlyout";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import TopbarChatsFlyout from "views/pages/common/chats/extra/TopbarChatsFlyout";
import {useMenuData} from "app/core/crud/common/menuData/menuData";
import {useDispatch} from "react-redux";
import {requestUserMe} from "app/redux/auth/actionTypes";

const Topbar = () => {
    const dispatch = useDispatch();
    const { hasRole, isLoggedIn } = useAuth();
    const isPlayerPanel = useMatchPathname(route['pp'], { exact: false });
    const isTrainerPanel = useMatchPathname(route['tp'], { exact: false });
    const panelType = isPlayerPanel ? 'pp' : (isTrainerPanel ? 'tp' : null)
    const isInOwnPanel =
        (isPlayerPanel && hasRole(role['profileType.player'])) ||
        (isTrainerPanel && hasRole(role['profileType.trainer']));

    const { data } = useMenuData(panelType, isInOwnPanel);

    const [isNotificationsOpen, setNotificationsOpen] = useState(false);
    const [isMessagesOpen, setMessagesOpen] = useState(false);

    const handleMessageClickAway = (e) => {
        if (isMessagesOpen) {
            e.preventDefault();
            setMessagesOpen(false);
        }
    };
    const handleNotificationClickAway = (e) => {
        if (isNotificationsOpen) {
            e.preventDefault();
            setNotificationsOpen(false);
        }
    };

    const isInMessages = useMatchPathname(
        route[`common.chats`](panelType),
        { exact: false }
    );

    useEffect(() => {
        if (!isLoggedIn()) return;
            dispatch(requestUserMe());
    }, []);

    return (
        <div className="topbar ml-auto">
            {/*<SearchDropdown/> TODO: NAVBAR: add search*/}
            {/*{layoutProps.viewLanguagesDisplay && (<LanguageSelectorDropdown/>)} TODO: NAVBAR: language selection*/}
            {/*<UserNotificationsDropdown/>*/}

            {isInOwnPanel && (
                <>
                    <ClickAwayListener onClickAway={handleNotificationClickAway}>
                        <div className="position-relative d-flex align-items-center justify-content-center">
                            <TopbarNotificationUnreadBtn
                                count={data?.countUnreadNotifications}
                                onClick={() => setNotificationsOpen(!isNotificationsOpen)}
                            />
                            <TopbarNotificationsFlyout
                                isOpen={isNotificationsOpen}
                                onClose={() => setNotificationsOpen(false)}
                                panelType={panelType}
                            />
                        </div>
                    </ClickAwayListener>
                    {!isInMessages && (
                        <ClickAwayListener onClickAway={handleMessageClickAway}>
                            <div className="position-relative d-flex align-items-center justify-content-center">
                                <TopbarChatsUnreadBtn
                                    count={data?.countUnreadChats}
                                    onClick={() => setMessagesOpen(!isMessagesOpen)}
                                />
                                <TopbarChatsFlyout
                                    isOpen={isMessagesOpen}
                                    onClose={() => setMessagesOpen(false)}
                                    panelType={panelType}
                                />
                            </div>
                        </ClickAwayListener>
                    )}
                </>
            )}

            <QuickUserToggler/>
        </div>
    );
}

export default Topbar;
