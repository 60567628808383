import {reducerError} from "app/redux/configurator/reducers/reducerError";
import {reducerSectionDelete} from "app/redux/configurator/reducers/3.section/ReducerSectionDelete";
import {unsavedTrainings} from "app/redux/configurator/utils/configuratorHelpers";

export const reducerTrainingDelete = (state, { dayNo, trainingIndex, force }, lastSectionsLength = 1000 ) => {
    if(!state.days[dayNo]) {
        return reducerError(state, 'training.delete.dayNotExist');
    }
    if(!state.trainings[trainingIndex] || state.days[dayNo].trainings.indexOf(trainingIndex) === -1) {
        return reducerError(state, 'training.delete.trainingNotExist');
    }
    const currentLength = state.trainings[trainingIndex].sections.length;
    if(currentLength === lastSectionsLength) {
        return reducerError(state, 'training.delete.deleteSectionFailed');
    }
    if(state.trainings[trainingIndex].sections.length) {
        if(!force){
            return reducerError(state, 'training.delete.deleteSectionsFirst');
        } else {
            const sectionIndex = state.trainings[trainingIndex].sections[0];
            const stateWithoutDeletedSection = reducerSectionDelete(state, { trainingIndex, sectionIndex, force });
            return reducerTrainingDelete(stateWithoutDeletedSection, { trainingIndex, dayNo, force }, currentLength);
        }
    }
    const { [trainingIndex]: removedTraining, ...filteredTrainings } = state.trainings;
    return {
        ...state,
        days: {
            ...state.days,
            [dayNo]: {
                ...state.days[dayNo],
                trainings: [
                    ...state.days[dayNo].trainings.filter(key => key!==trainingIndex),// remove training from day
                ],
            },
        },
        trainings: {
            ...filteredTrainings,
        },
        helpers: {
            ...state.helpers,
            unsavedTrainings: unsavedTrainings(state.helpers).remove(trainingIndex)
        }
    }
};