import React, {useMemo, useLayoutEffect, useEffect} from "react";
import objectPath from "object-path";
import { Link, useLocation } from "react-router-dom";
import {getBreadcrumbsAndTitle, useSubheader, useHtmlClassService} from "_metronic/layout";
import { route } from "app/config/routes/route";
import { checkIsActive } from "_metronic/_helpers";

export function SubHeader() {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
          uiService.config,
          "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
          "subheader_container",
          true
      )
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    subheader.setBreadcrumbs(aside.breadcrumbs || header.breadcrumbs);
    subheader.setTitle(aside.title || header.title);
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);

  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "btn-light" : "btn-clean";
  }

  const NavLink = ({ url, name }) => <Link to={url} className={`mx-2 btn ${getMenuItemActive(url)}`}>{name}</Link>

  return (
      <div
          id="kt_subheader"
          className={`subheader subheader-solid py-2 py-lg-4 mb-5  ${layoutProps.subheaderCssClasses}`}
      >
        <div
            className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
        >
          {/* Info */}
          <div className="d-flex align-items-center flex-wrap mr-1">
            <NavLink
                url={route['pp.dashboard']}
                name="Pulpit"
            />
            <NavLink
                url={route['pp.profile']}
                name="Mój profil"
            />
            <NavLink
                url={route['pp.training']}
                name="Trening"
            />
            <NavLink
                url={route['pp.myTrainingPlans']}
                name="Moje plany treningowe"
            />
            <NavLink
                url={route['pp.playerTrainers']}
                name="Moi trenerzy"
            />
            <NavLink
                url={route['pp.myScores']}
                name="Wyniki"
            />
            <NavLink
                url={route['pp.myMeasures']}
                name="Pomiary"
            />
            <NavLink
                url={route['pp.myInjuries']}
                name="Ograniczenia"
            />
          </div>

          {/* Toolbar */}
          <div className="d-flex align-items-center"/>
        </div>
      </div>
  );
}
