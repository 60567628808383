import React from 'react';
import styled from "styled-components";
import SymbolImage from "components/SymbolImage";
import {useIsConversationActive} from "views/pages/common/chats/components/Conversations";
import {Link, useLocation} from "react-router-dom";
import {route} from "app/config/routes/route";
import { Badge } from 'react-bootstrap';
import {largestDiffString, translatedUnitShort} from "app/utils/dateTime";
import Status, {CLIENT_STATUS_LIST} from "components/Status";
import Tooltip from "components/Tooltip";

const StyledTextEllipsis = styled.div`
    height: 1.45rem;
    position: relative;
    > span {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
`;

export const ConversationBoxWrapper = ({ chatId, children }) => (
    <div className={`${chatId == null ? 'd-flex' : 'd-none d-sm-flex'} col-12 col-sm-5 col-md-4 mh-100 mb-0`}>
        <div className="card card-custom flex-grow-1">
            {children}
        </div>
    </div>
);

export const StyledConversationSearch = ({ onChange, value }) => (
    <div className="p-4 border-bottom d-flex align-items-center">
        <input
            className="form-control border-0 bg-light"
            placeholder="Szukaj adresata..."
            onChange={onChange}
            value={value}
        />
    </div>
);

export const ConversationListWrapper = ({ children }) => (
    <div className="card-body p-5 overflow-auto position-relative">
        {children}
    </div>
);

const StyledConversationListItem = styled.div`
    &:hover {
        background-color: #0001;
    }
    background-color: ${({ isActive }) => isActive && '#0001'};
    transition: background-color 0.1s ease, color 0.1s ease;
`;

export const ConversationListItemWrapper = ({panelType, id, chatId, to, children, ...props}) => {
    const isActive = useIsConversationActive(chatId);
    const { search } = useLocation();
    return (
        <Link to={{
            pathname: route['common.chat.messages'](panelType, chatId),
            // search,
        }}>
            <StyledConversationListItem
                isActive={isActive}
                className="d-flex align-items-center justify-content-center p-2 my-1 cursor-pointer rounded-lg flex-row"
                {...props}
            >
                {children}
            </StyledConversationListItem>
        </Link>
    );
}

export const StyledProfilePhotoImage = ({ profilePhotoUrl, statusId }) => {
    const badge = CLIENT_STATUS_LIST.find(({ value }) => value === statusId);
    if (badge)
        return (
            <Tooltip tooltip={badge?.label}>
                <SymbolImage
                    src={profilePhotoUrl}
                    alt="profile"
                    maxWidth="40"
                    isCircle
                    classNameOuter="m-0 position-relative"
                >
                    {badge && (
                        <div className={`position-absolute right-0 bottom-0 bg-${badge?.color} ${badge?.additional || ''} px-1 rounded`}>
                            <i className={`fas fa-${badge?.icon ?? 'circle'} fa-sm`} />
                        </div>
                    )}
                </SymbolImage>
            </Tooltip>
        );

    return (
        <SymbolImage
            src={profilePhotoUrl}
            alt="profile"
            maxWidth="40"
            isCircle
            classNameOuter="m-0 position-relative"
        />
    );
}

export const LastMessage = ({ name, unread, message, createdAt, statusId }) => (
    <>
        <div className="ml-4 d-flex flex-grow-1 flex-column">
            <StyledTextEllipsis className={`font-size-lg mb-0 ${unread > 0 ? 'font-weight-bolder text-dark' : 'text-dark-75'}`}>
                <span>{name}</span>
            </StyledTextEllipsis>
            <StyledTextEllipsis className={unread > 0 ? 'font-weight-bolder text-dark-50' : 'text-muted'}>
                <span>{message}</span>
            </StyledTextEllipsis>
        </div>
        <div className="d-flex flex-column ml-auto align-items-end">
            <UnreadBadge number={unread} />
            <LastMessageTime time={createdAt} />
        </div>
    </>
);

export const LastMessageTime = ({ time }) => {
    if (!time) return null;
    const { diff, unit } = largestDiffString(time);
    return (
        <div className="d-flex text-dark-50">
            {diff || ''}
            &nbsp;
            <span className="small opacity-75">
                {translatedUnitShort(unit)}
            </span>
        </div>
    );
};

export const UnreadBadge = ({ number }) => {
    if (!number) return null;
    return (
        <Badge pill variant="danger">
            {number}
        </Badge>
    );
};
