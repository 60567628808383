import {HttpTransportType, HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import {BASE_URL_API} from "app/config/env";

export const messagesHub = (tokenRef, token) => {
  return new HubConnectionBuilder()
    .withUrl(
      `${BASE_URL_API}/hubs/chats`,
      {
        accessTokenFactory: () => tokenRef.current??token,
        withCredentials: false,
        transport: HttpTransportType.LongPolling,
      }
    )
    .configureLogging(LogLevel.Information)
    .build();
};

export const HUB_MESSAGES_RECEIVE_CONVERSATION_MESSAGE = 'ReceiveConversationMessage';
export const HUB_MESSAGES_SEND_CONVERSATION_MESSAGE = 'SendConversationMessage';
export const hubMessagesSendConversationMessage = (message) => ({ message });
