import {prefix} from "app/config/routes/tp/_cfg";

export const routeTPClientTrainingPlans = {
    'tp.clientTrainingPlans':            `${prefix}/client-training-plans`,
    'tp.clientTrainingPlans.inProgress': `${prefix}/client-training-plans/in-progress`,
    'tp.clientTrainingPlans.done':       `${prefix}/client-training-plans/completed`,
    //
    'tp.clientTrainingPlan':           (id = ':trainingPlanId') => `${prefix}/client-training-plans/${id}`,
    'tp.clientTrainingPlan.basicInfo': (id = ':trainingPlanId') => `${prefix}/client-training-plans/${id}/info`,
    'tp.clientTrainingPlan.stats':     (id = ':trainingPlanId') => `${prefix}/client-training-plans/${id}/stats`,
    'tp.clientTrainingPlan.settings':  (id = ':trainingPlanId') => `${prefix}/client-training-plans/${id}/settings`,
    'tp.clientTrainingPlan.recommendations': (id = ':trainingPlanId') => `${prefix}/client-training-plans/${id}/recommendations`,
    //
    'tp.clientTrainingPlan.module':              (id = ':trainingPlanId', mId=':moduleId') => `${prefix}/client-training-plans/${id}/module/${mId}`,
    'tp.clientTrainingPlan.module.calendarView': (id = ':trainingPlanId', mId=':moduleId') => `${prefix}/client-training-plans/${id}/module/${mId}/calendar`,
    'tp.clientTrainingPlan.module.planView':     (id = ':trainingPlanId', mId=':moduleId') => `${prefix}/client-training-plans/${id}/module/${mId}/list`,
    'tp.clientTrainingPlan.module.clientStats':  (id = ':trainingPlanId', mId=':moduleId') => `${prefix}/client-training-plans/${id}/module/${mId}/stats`,
    'tp.clientTrainingPlan.module.clientScores': (id = ':trainingPlanId', mId=':moduleId') => `${prefix}/client-training-plans/${id}/module/${mId}/scores`,

}
