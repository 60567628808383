import {reducerError} from "app/redux/configurator/reducers/reducerError";
import {unsavedTrainings} from "app/redux/configurator/utils/configuratorHelpers";

export const reducerSectionReorder = ( state, { trainingIndex, sectionIndexFrom, sectionIndexTo }) => {
    if(!state.trainings[trainingIndex]) {
        return reducerError(state, 'section.reorder.trainingNotExist');
    }
    if(state.trainings[trainingIndex].sections.length < sectionIndexFrom || state.trainings[trainingIndex].sections.length < sectionIndexTo) {
        return reducerError(state, 'section.reorder.destinationNotExist');
    }
    const oldSections = [
        ...state.trainings[trainingIndex].sections.slice(0, sectionIndexFrom),
        //delete from there
        ...state.trainings[trainingIndex].sections.slice(sectionIndexFrom+1),
    ];
    return  {
        ...state,
        trainings: {
            ...state.trainings,
            [trainingIndex]: {
                ...state.trainings[trainingIndex],
                sections: [
                    ...oldSections.slice(0, sectionIndexTo),
                    state.trainings[trainingIndex].sections[sectionIndexFrom],
                    ...oldSections.slice(sectionIndexTo),
                ]
            }
        },
        helpers: {
            ...state.helpers,
            unsavedTrainings: unsavedTrainings(state.helpers).add([ trainingIndex ]),
        }
    }
}