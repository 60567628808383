import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import authReducer from "app/redux/auth/reducer";
import settingsReducer from "app/redux/settings/settingsReducer";
import { configuratorReducer as configurator } from 'app/redux/configurator/configuratorReducer';
import storage from 'redux-persist/lib/storage'
import {persistCombineReducers, persistReducer, persistStore} from "redux-persist";
import createSagaMiddleware from "redux-saga";
import { authSaga } from "app/redux/auth/sagas";
import {updateTrainingSaga} from "app/redux/configurator/sagas/updateTrainingSaga";
import {appReducer as app} from 'app/redux/app/appReducer';
import {chatsReducer as chats} from 'app/redux/chats/chatsReducer';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const settings = persistReducer(
    {
        storage,
        key: "r_settings",
        whitelist: ["cookiesAccepted", "lastMobilePopup"]
    },
    settingsReducer,
);
const auth = persistReducer(
    {
        storage,
        key: "r_auth",
        whitelist: ["user", "authToken"]
    },
    authReducer,
);

const rootReducer = combineReducers(
    {
        settings,
        auth,
        configurator,
        app,
        chats,
    }
);

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    rootReducer,
    composeEnhancer(
        applyMiddleware(sagaMiddleware),
    ),
);

// noinspection JSUnresolvedFunction
sagaMiddleware.run(authSaga);
sagaMiddleware.run(updateTrainingSaga);

const persistor = persistStore(store);

export {
    rootReducer,
    store,
    persistor,
};