import {prefix} from "app/config/routes/tp/_cfg";

export const routeTPTrainingPlans = {
    'tp.template.trainingPlans':                                         `${prefix}/training-plans-templates`,
    //
    'tp.template.trainingPlan.create':                                   `${prefix}/training-plans-templates/add`,
    'tp.template.trainingPlan':         (id=':trainingPlanId') => `${prefix}/training-plans-templates/${id}`,
    'tp.template.trainingPlan.edit':    (id=':trainingPlanId') => `${prefix}/training-plans-templates/${id}/edit`,
    //
    'tp.template.trainingPlan.clients':    (id=':trainingPlanId') => `${prefix}/training-plans-templates/${id}/clients`,
    'tp.template.trainingPlan.stats':      (id=':trainingPlanId') => `${prefix}/training-plans-templates/${id}/stats`,
    'tp.template.trainingPlan.settings':   (id=':trainingPlanId') => `${prefix}/training-plans-templates/${id}/settings`,
    //
    'tp.template.trainingPlan.module':          (id=':trainingPlanId', mId=':moduleId') => `${prefix}/training-plans-templates/${id}/module/${mId}`,
    'tp.template.trainingPlan.module.calendar': (id=':trainingPlanId', mId=':moduleId') => `${prefix}/training-plans-templates/${id}/module/${mId}/calendar`,
    'tp.template.trainingPlan.module.list':     (id=':trainingPlanId', mId=':moduleId') => `${prefix}/training-plans-templates/${id}/module/${mId}/list`,
    'tp.template.trainingPlan.module.stats':    (id=':trainingPlanId', mId=':moduleId') => `${prefix}/training-plans-templates/${id}/module/${mId}/stats`,
}
