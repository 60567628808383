import {reducerError} from "app/redux/configurator/reducers/reducerError";
import {unsavedTrainings} from "app/redux/configurator/utils/configuratorHelpers";
import {moveExerciseSuperserie} from "app/configurator/utils/superseries/moveExercise";
import {reorderNo} from "app/configurator/utils/superseries/reorderNo";

export const reducerExerciseReorder = (state, { sectionIndex, fromExerciseIndex, toExerciseIndex }) => {
    if(fromExerciseIndex === toExerciseIndex) return state;
    if(!state.exercises?.[sectionIndex]?.exercises?.[fromExerciseIndex]){
        return reducerError(state, 'exercise.reorder.sourceNotExist');
    }
    const oldExercises = [
        ...state.exercises[sectionIndex].exercises.slice(0, fromExerciseIndex),
        //delete from there
        ...state.exercises[sectionIndex].exercises.slice(fromExerciseIndex+1),
    ];
    const exercisesCopy = [...state.exercises[sectionIndex].exercises];
    const [removed] = exercisesCopy.splice(fromExerciseIndex, 1);
    let removedDest;
    if(toExerciseIndex<=0){
        removedDest = [null, ...exercisesCopy.splice(0, 1)];
    } else {
        removedDest = exercisesCopy.splice(toExerciseIndex-1,2);
    }
    const [prevToReorder, nextToReorder=null] = removedDest;

    const [prev, orig, next] = moveExerciseSuperserie(prevToReorder, removed, nextToReorder);

    if(prev && next){ // prev and next exists
        exercisesCopy.splice(Math.max(0, toExerciseIndex-1), 0, prev, orig, next);
    } else if(prev) { // only prev exists
        exercisesCopy.splice(Math.max(0, toExerciseIndex-1), 0, prev, orig);
    } else if(next) { // only next exists
        exercisesCopy.splice(Math.max(0, toExerciseIndex-1), 0, orig, next);
    } else {
        exercisesCopy.splice(0, 0, orig);
    }

    const exercises = reorderNo(exercisesCopy);

    return {
        ...state,
        exercises: {
            ...state.exercises,
            [sectionIndex]: {
                ...state.exercises[sectionIndex],
                exercises,
            },
        },
        helpers: {
            ...state.helpers,
            unsavedTrainings: unsavedTrainings(state.helpers).add([state.sections[sectionIndex].trainingIndex]),
        },
    }
}