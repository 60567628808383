import React from 'react';
import PropTypes from 'prop-types';
import Dot from "components/Dot";

export const CLIENT_STATUS_LIST = [
    {
        label: "Brak danych",
        value: 0,
        color: 'light',
        additional: 'text-dark-75',
    },
    {
        label: "Aktywny",
        value: 1,
        color: 'success',
        additional: 'text-light',
        icon: 'check'
    },
    {
        label: "Archiwalny",
        value: 2,
        color: 'secondary',
        additional: 'text-dark-75',
        icon: 'archive'
    },
    {
        label: "Zablokowany",
        value: 3,
        color: 'dark',
        additional: 'text-light',
        icon: 'times-circle',
    },
    {
        label: "Usunięty",
        value: 4,
        color: 'danger',
        additional: 'text-light',
        icon: 'trash',
    },
    {
        label: "Kontuzjowany",
        value: 5,
        additional: 'text-light',
        color: 'primary',
        icon: 'crutch',
    },
    {
        label: "Demo",
        value: 6,
        color: 'warning',
        additional: 'text-dark-75',
        icon: 'tag',
    },
];
export const INVITE_STATUS_LIST = [
    {
        label: "Wysłane",
        value: 1,
        color: 'secondary',
        icon: 'envelope-open-text',
    },
    {
        label: "Usunięte",
        value: 3,
        color: 'danger',
        additional: 'text-light',
        icon: 'trash',
    },
    {
        label: "Zaakceptowane",
        value: 4,
        color: 'success',
        additional: 'text-light',
        icon: 'check',
    },
];
const STATUS_DEFAULT = {
    value: null,
    color: 'light',
};


export const ClientStatusOption = ({ label, additional, color, icon }) => (
    <span className={`mr-1 badge badge-${color} ${additional ?? ''}`}>
        {icon && <i className={`fas fa-${icon} mr-2`} />}
        {label}
    </span>
);

const Status = ({ id, isInvite, className, ...props }) => {
    const { color, label, additional, icon } = (isInvite ? INVITE_STATUS_LIST : CLIENT_STATUS_LIST).find(({ value }) => value === id) || STATUS_DEFAULT;
    return (
        <span className={`mr-1 badge badge-${color} ${additional ?? ''} ${className ?? ''}`} {...props}>
            {icon && <i className={`fas fa-${icon} mr-2`} />}
            {label}
        </span>
    );
};

Status.propTypes = {
    status: PropTypes.string,
};

export default Status;
