import {reducerError} from "app/redux/configurator/reducers/reducerError";

export const reducerTrainingSetModal = ( state, { trainingIndex = null, dayNo = null } ) => {
    if(trainingIndex!== null){
        if(!state.trainings[trainingIndex]) {
            return reducerError(state, 'training.openModal.trainingNotExist');
        }
        if(!state.days[dayNo]) {
            return reducerError(state, 'training.openModal.dayNotExist');
        }
    }
    let trainingModal;
    if(trainingIndex===null && dayNo===null) {
        trainingModal = {
            ...state.config.trainingModal,
            show: false,
        };
    } else {
        trainingModal = {
            ...state.config.trainingModal,
            show: true,
            trainingIndex,
            // dayNo,
        };
    }
    return {
        ...state,
        config: {
            ...state.config,
            trainingModal,
        },
    }
}