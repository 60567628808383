import React, { useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "_metronic/layout";
import { HeaderMenu } from "./HeaderMenu";
import Logo from "components/Logo";
import {useDispatch, useSelector} from "react-redux";
import {dispatchToggleAsideMobileOpen, LAYOUT_MODE_PUBLIC} from "app/redux/app/appActionTypes";

export const HeaderMenuWrapper = () => {
    const dispatch = useDispatch();
    const uiService = useHtmlClassService();
    const { layoutMode, isAsideMobileOpen } = useSelector(({app})=>app.layout)
    const layoutProps = useMemo(() => {
        return {
            config: uiService.config,
            ktMenuClasses: uiService.getClasses("header_menu", true),
            rootArrowEnabled: objectPath.get(
                uiService.config,
                "header.menu.self.root-arrow"
            ),
            menuDesktopToggle: objectPath.get(uiService.config, "header.menu.desktop.toggle"),
            headerMenuAttributes: uiService.getAttributes("header_menu"),
            headerSelfTheme: objectPath.get(uiService.config, "header.self.theme"),
            ulClasses: uiService.getClasses("header_menu_nav", true),
            disabledAsideSelfDisplay:
                objectPath.get(uiService.config, "aside.self.display") === false
        };
    }, [uiService]);

    const handleToggleAsideOpen = () => dispatch(dispatchToggleAsideMobileOpen());

    if (layoutMode !== LAYOUT_MODE_PUBLIC) return null;

    return (
      <>
        {isAsideMobileOpen && (
          <div className="aside-overlay" onClick={handleToggleAsideOpen} />
        )}
        <div className={`header-menu-wrapper header-menu-wrapper-left ${isAsideMobileOpen ? 'header-menu-wrapper-on' : ''}`}>
            <div className="header-logo d-block mr-2">
                <Logo/>
            </div>
            <HeaderMenu layoutProps={layoutProps} />
            {/*{!showMenu && <>*/}
            {/*    <div*/}
            {/*        id="kt_header_menu"*/}
            {/*        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}*/}
            {/*        {...layoutProps.headerMenuAttributes}*/}
            {/*    >*/}
            {/*        <ul className={`menu-nav ${layoutProps.ulClasses}`}>*/}
            {/*            <li className={`menu-item menu-item-rel`}>*/}
            {/*                <NavLink className="menu-link" to={route['index']}>*/}
            {/*                    <span className="menu-text"><i className="fa fa-arrow-circle-left mr-2"/> Strona główna</span>*/}
            {/*                </NavLink>*/}
            {/*            </li>*/}
            {/*        </ul>*/}
            {/*    </div>*/}
            {/*</>}*/}
        </div>
      </>
    )
}
