// PUBLIC
import {routeAuth} from "app/config/routes/app/auth";
import {routeConfig} from "app/config/routes/app/config";
import {routePublic} from "app/config/routes/app/public";

// PLAYER PANEL
import {routePP} from "app/config/routes/pp/pp";
import {routePPAccount} from "app/config/routes/pp/account";
import {routePPResults} from "app/config/routes/pp/results";
import {routePPTrainers} from "app/config/routes/pp/trainers";
import {routePPTrainingPlans} from "app/config/routes/pp/trainingPlans";

// TRAINER PANEL
import {routeTPProfile} from "app/config/routes/tp/account/profile";
import {routeTPSettings} from "app/config/routes/tp/account/settings";
import {routeTPClients} from "app/config/routes/tp/clients/clients";
import {routeTPClientTrainingPlans} from "app/config/routes/tp/clients/trainingPlans";
import {routeTPClientTrainings} from "app/config/routes/tp/clients/trainings";
import {routeTPExercises} from "app/config/routes/tp/templates/exercises";
import {routeTPTrainingPlans} from "app/config/routes/tp/templates/trainingPlans";
import {routeTPTrainings} from "app/config/routes/tp/templates/trainings";
import {routeTP} from "app/config/routes/tp/tp";
import {routeConfigurator} from "app/config/routes/tp/configurator";

// TODO: remove
import {routeCommon} from "app/config/routes/common/common";
import {routeCP} from "app/config/routes/cp/cp";
import {routePPOrders} from "app/config/routes/pp/orders";
import {routeTPOrders} from "app/config/routes/tp/orders";
import {routeTPSurveys} from "app/config/routes/tp/surveys";

export const route = {


    // ===== PUBLIC =====
    ...routeAuth,
    ...routeConfig,
    ...routePublic,
    ...routeCommon,


    // ===== PLAYER PANEL =====
    ...routePP,
    ...routePPAccount,
    ...routePPResults,
    ...routePPTrainers,
    ...routePPTrainingPlans,
    ...routePPOrders,


    // ===== TRAINER PANEL =====
    ...routeTP,
    // account
    ...routeTPProfile,
    ...routeTPSettings,
    ...routeTPOrders,
    // clients
    ...routeTPClients,
    ...routeTPClientTrainingPlans,
    ...routeTPClientTrainings,
    // templates
    ...routeTPExercises,
    ...routeTPTrainingPlans,
    ...routeTPTrainings,
    // configurator
    ...routeConfigurator,

    // surveys
    ...routeTPSurveys,


    // ===== CLUB PANEL =====
    ...routeCP,
};
