import React, {useContext, useEffect} from "react";
import {AnimateLoadingContext} from "app/providers/TraineronProvider";

const AnimateLoading = () => {

    const { allowUpdate } = useContext(AnimateLoadingContext);

    useEffect(() => {
        if(allowUpdate()) {
            scrollToTop();
        }
    });


    const scrollToTop = () => {
        const scrollToTopBtn = document.getElementById("kt_scrolltop");
        if (scrollToTopBtn) {
            scrollToTopBtn.click();
        }
    }

    return <></>;
}

export default AnimateLoading;
