import React from 'react';
import PropTypes from 'prop-types';
import { useField } from "formik";

const FormikCheckbox = ({ name, label, labelClassName='' }) => {

    const [ field, meta ] = useField(name);
    // console.log(meta.touched && meta.error)
    // console.log(field.value)
    return (
        <div className="form-group">
            <label className={`w-100 m-0 d-flex align-items-center font-size-h6 cursor-pointer ${labelClassName}`}>
                <input
                    type="checkbox"
                    {...field}
                    checked={field?.value}
                    className="mr-3"
                />
                {label}
            </label>
            {meta.touched && meta.error && <div className="error text-danger small-90 font-size-sm w-100">{meta.error}</div>}
        </div>
    );
};

FormikCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired
};

export default FormikCheckbox;
