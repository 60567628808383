import React from 'react';
import styled from 'styled-components';
import {Link} from "react-router-dom";
import {route} from "app/config/routes/route";
import {TraineronDarkLogoAsset, TraineronLightLogoAsset} from './Assets';

const LOGO_COLORS = {
    dark: TraineronDarkLogoAsset,
    light: TraineronLightLogoAsset,
};

export const getLogo = (color) => LOGO_COLORS[color] ?? LOGO_COLORS.dark;

const StyledLink = styled(Link)`
    display: flex;
    align-items: flex-start;
    align-content: stretch;
    img {
        max-height: 40px;
        max-width:100%;
    }
`

const Logo = ({ url, color }) => (
    <StyledLink className="py-3" to={url ?? route['index']}>
        <img alt="logo" src={getLogo(color)}/>
        <img className="wcag-high-contrast-logo d-none" alt="logo" src={getLogo('light')}/>
    </StyledLink>
);

export default Logo;

export const InlineLogo = ({ color, className }) => (
    <img
        className={className ?? 'pb-3 '}
        style={{ height: '1.75em' }}
        alt="Traineron logo"
        src={getLogo(color)}
    />
);
