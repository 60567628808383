export const firstChars = (text, limit = 20, withBrackets=false) => {
    if(!text) return '';
    if(text.length > limit) return (withBrackets?'"':'') + text.substring(0, limit) + '...' + (withBrackets?'"':'');
    else return (withBrackets?'"':'') + text + (withBrackets?'"':'');
}


export const countWithText2 = (num, single, more) => {
    if( num<=0 ) return null;
    return num + ' ' + (num===1 ? single : more);
}

export const countWithText3 = (num, single, few, more) => {
    if (num <= 0) return null;
    if (num === 1) return `${num} ${single}`;
    // for 2, 3, 4 | (NOT 12, 13, 14) | 22, 23, 24 | 32, 33, 34...
    const rest = num % 10
    if (rest >= 2 && rest <= 4 && (num < 10 || num > 20)) return `${num} ${few}`;
    return `${num} ${more || few}`;
}
