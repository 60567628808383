import {reducerError} from "app/redux/configurator/reducers/reducerError";
import {unsavedTrainings} from "app/redux/configurator/utils/configuratorHelpers";

export const reducerSectionDelete = (state, { trainingIndex, sectionIndex, force }) => {
    if(!force) return state;
    if(!state.trainings[trainingIndex]) {
        return reducerError(state, 'section.delete.trainingNotExist');
    }
    if(!state.sections[sectionIndex] || state.trainings[trainingIndex].sections.indexOf(sectionIndex) === -1) {
        return reducerError(state, 'section.delete.sectionNotExist');
    }
    const { [sectionIndex]: deletedSection, ...filteredSections} = state.sections;
    const { [sectionIndex]: deletedExercises, ...filteredExercises} = state.exercises;

    // noinspection EqualityComparisonWithCoercionJS
    return {
        ...state,
        trainings: {
            ...state.trainings,
            [trainingIndex]: {
                ...state.trainings[trainingIndex],
                sections: [
                    ...state.trainings[trainingIndex].sections.filter(key => key != sectionIndex),
                ],
            },
        },
        sections: {
            ...filteredSections,
        },
        exercises: {
            ...filteredExercises,
        },
        helpers: {
            ...state.helpers,
            unsavedTrainings: unsavedTrainings(state.helpers).remove(trainingIndex),
        }
    }
}