import {unsavedTrainings} from "app/redux/configurator/utils/configuratorHelpers";

export const getTestExercise = (testExercise) => {
    if(!testExercise || !testExercise?.id) {
        return null;
    }
    const measureUnitOption = testExercise?.measureUnitOption;
    const betterScore = testExercise?.betterScore;
    return ({
        id: testExercise?.id,
        name: testExercise?.name,
        subName: testExercise?.subName ?? `${measureUnitOption ? `${measureUnitOption?.name} (${measureUnitOption?.unitCode}), `: ''}${betterScore?.name ? `${betterScore?.name} lepszy` : ''}`
    });
}

export const reducerExerciseSetTestExercise = (state, { sectionIndex, exerciseIndex, testExercise }) => {

    return ({
        ...state,
        exercises: {
            ...state.exercises,
            [ sectionIndex ]: {
                ...state.exercises[sectionIndex],
                exercises: [
                    ...state.exercises[sectionIndex].exercises.slice(0, exerciseIndex),
                    {
                        ...state.exercises[sectionIndex].exercises[exerciseIndex],
                        testExercise: getTestExercise(testExercise),
                    },
                    ...state.exercises[sectionIndex].exercises.slice(exerciseIndex+1),
                ],
            },
        },
        helpers: {
            ...state.helpers,
            unsavedTrainings: unsavedTrainings(state.helpers).add([state.sections[sectionIndex].trainingIndex]),
        },
    })
}