import React from 'react';
import styled from "styled-components";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {acceptCookies} from "app/redux/settings/settingsActionTypes";
import {MessengerStyles} from "components/MessengerStyles";

const StyledCookies = styled.div`
    bottom:10px;
    right:10px;
    z-index: 1000;
    box-shadow: 0 0 10px rgba(0,0,0,.3);
`;

const I_Cookies = () => {

    const { cookiesAccepted } = useSelector(({settings}) => settings);
    const dispatch = useDispatch();

    if(cookiesAccepted) return null;

    return (
        <StyledCookies className="badge badge-light position-fixed">
            <MessengerStyles />
            <span>Korzystając z traineron.pl zgadzasz się z </span>
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip className="tooltip-dark" style={{maxWidth: '400px'}} id={`tooltip-top`}>
                        <>
                            Platforma wykorzystuje pliki cookies w celu konfiguracji strony traineron.pl, w tym dostosowania zawartości strony do
                            Twoich preferencji oraz optymalizacji korzystania z Platformy, prawidłowej identyfikacji użytkowników Platformy,
                            zidentyfikowania Twojego logowania na stronie i utrzymania sesji, a także prowadzenia anonimowych statystyk
                            przedstawiających sposób korzystania ze strony traineron.pl. Korzystanie z Platformy po wyrażeniu poniższej zgody
                            oznacza, że cookies będą zamieszczane w Twoim urządzeniu końcowym i wykorzystane również w celu prezentowania
                            treści dopasowanych do Twoich zainteresowań. Możesz dokonać w każdym czasie zmiany ustawień dotyczących cookies
                            oraz wyłączyć profilowanie. Szczegółowe informacje znajdziesz w Polityce Prywatności. Kliknięcie przycisku 'OK'
                            oznacza, że wyrażasz na to zgodę.
                        </>
                    </Tooltip>
                }
            >
                <u className="cursor-help">polityką ciasteczek</u>
            </OverlayTrigger>
            <span className="btn btn-primary btn-xs py-1 px-1 ml-3" onClick={() => dispatch(acceptCookies())}>
                <i className="fas fa-cookie-bite"/> OK
            </span>
        </StyledCookies>
    );
};

export default I_Cookies;
