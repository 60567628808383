import {matchPath, useLocation} from "react-router-dom";

const useMatchPathname = (path, options = {}) => {
    let location = useLocation();
    const match = matchPath(location.pathname, {
        path,
        exact: true,
        strict: false,
        ...options,
    });
    return !!match;
}

export default useMatchPathname;
