import {useSelector} from "react-redux";


export const useSelectChatsSorted = () => {
  return useSelector(
    ({chats: {chatsSorted}})=> ({
      chatsSorted,
    }),
    (a,b) => (
        a.chatsSorted===b.chatsSorted
    )
  );
}
