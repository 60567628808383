import axios from 'axios';
import {BASE_URL_API} from "app/config/env";

export const TP_Notifications = ({queryKey: [_key, _panelType, page, results]}) => axios({
    url: `${BASE_URL_API}/v1/trainer-panel/notifications`,
    method: 'GET',
    params: {
        PageNumber: page,
        PageSize: results,
    },
});
