import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import styled, {keyframes} from "styled-components";
import {isMobile} from "react-device-detect";
import {actionSetLastMobilePopup} from "app/redux/settings/settingsActionTypes";
import ExternalLink from "app/core/router/components/ExternalLink";


const showPopup = keyframes`
  from {
    transform: translateY(150px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const StyledMobilePopup = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 3rem 2rem;
    display: flex;
    justify-content:center;
    align-content: center;
    z-index: 9999999;
    background: #fff;
    border-top-left-radius: 1.3rem;
    border-top-right-radius: 1.3rem;
    animation: ${showPopup} .4s forwards;
`;


const StyledMobilePopupBackdrop = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top:0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.7);
    z-index: 9999998;
`;

export function iOS() {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export const MobilePopup = () => {

    const {lastMobilePopup} = useSelector(({settings}) => settings);
    const [showPopup, setShowPopup] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!lastMobilePopup || moment().diff(moment(lastMobilePopup), 'days') > 5) {
            setShowPopup(true);
        } else {
            setShowPopup(false)
        }
    }, [lastMobilePopup]);

    const handleClickHidePopup = () => {
        setShowPopup(false);
        dispatch(actionSetLastMobilePopup());
    }

    if(!showPopup || !isMobile) return null;
    return (
        <>
            <StyledMobilePopupBackdrop onClick={handleClickHidePopup}/>
            <StyledMobilePopup>
                <div className="w-100">
                    <div className="text-center mb-16 h4">
                        <strong className="mr-1">Traineron.com</strong> <span className="small">w aplikacji mobilnej</span>
                    </div>
                    <div className="w-100 d-flex justify-content-between align-items-center mb-20">
                        <img src="https://api.traineron.com/images/logo/logo_traineron_black.png" style={{maxWidth:'120px'}} alt=""/>
                        <ExternalLink
                            className="btn btn-lg btn-danger rounded"
                            to={iOS() ? 'https://apps.apple.com/us/app/traineron/id1537567987' : 'https://play.google.com/store/apps/details?id=com.traineron.traineronapp'}
                        >
                            Pobierz aplikację
                        </ExternalLink>
                    </div>
                    <div className="w-100 text-center text-muted" onClick={handleClickHidePopup}>
                        Kontynuuj w przeglądarce
                    </div>
                </div>
            </StyledMobilePopup>
        </>
    );
};