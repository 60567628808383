import {errorHandler} from "app/core/errorHandler";
import {unsavedTrainings} from "app/redux/configurator/utils/configuratorHelpers";


export const reducerSeriesSetMode = (state, { sectionIndex, exerciseIndex, serieIndex, measureUnitId, mode }) => {
    if(!state.exercises?.[sectionIndex]?.exercises?.[exerciseIndex]?.series?.serieRows?.[serieIndex]?.items?.[measureUnitId]) {
        errorHandler({
            file: 'reducerSeriesSetMode.js',
            expected: `state.exercises?.[sectionIndex]?.exercises?.[exerciseIndex]?.series?.serieRows?.items?.`
                +`[serieIndex]?.[measureUnitId]`,
            got: state.exercises?.[sectionIndex]?.exercises?.[exerciseIndex]?.series?.serieRows?.[serieIndex]?.items?.[measureUnitId],
            json: { sectionIndex, exerciseIndex, serieIndex, measureUnitId, mode },
        });
        return state;
    }
    // noinspection DuplicatedCode
    return {
        ...state,
        exercises: {
            ...state.exercises,
            [sectionIndex]: {
                ...state.exercises[sectionIndex],
                exercises: [
                    ...state.exercises[sectionIndex].exercises.slice(0, exerciseIndex),
                    {
                        ...state.exercises[sectionIndex].exercises[exerciseIndex],
                        series: {
                            ...state.exercises[sectionIndex].exercises[exerciseIndex].series,
                            serieRows: [
                                ...state.exercises[sectionIndex].exercises[exerciseIndex].series.serieRows.slice(0, serieIndex),
                                {
                                    ...state.exercises[sectionIndex].exercises[exerciseIndex].series.serieRows[serieIndex],
                                    items: {
                                        ...state.exercises[sectionIndex].exercises[exerciseIndex].series.serieRows[serieIndex].items,
                                        [measureUnitId]: {
                                            ...state.exercises[sectionIndex].exercises[exerciseIndex].series.serieRows[serieIndex].items[measureUnitId],
                                            mode,
                                        },
                                    },
                                },
                                ...state.exercises[sectionIndex].exercises[exerciseIndex].series.serieRows.slice(serieIndex+1),
                            ],
                        },
                    },
                    ...state.exercises[sectionIndex].exercises.slice(exerciseIndex+1),
                ],
            },
        },
        helpers: {
            ...state.helpers,
            unsavedTrainings: unsavedTrainings(state.helpers).add([state.sections[sectionIndex].trainingIndex])
        }
    };
}