import axios from 'axios';
import {BASE_URL_API} from "app/config/env";

export const PP_Notifications_Info = () => axios({
    url: `${BASE_URL_API}/v1/player-panel/notifications/info`,
    method: 'GET',
});


export const PP_Notifications = ({queryKey: [_key, _panelType, page, results]}) => axios({
    url: `${BASE_URL_API}/v1/player-panel/notifications`,
    method: 'GET',
    params: {
        PageNumber: page,
        PageSize: results,
    },
});