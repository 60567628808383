import {ACCEPT_COOKIES, SET_LAST_MOBILE_POPUP} from "app/redux/settings/settingsActionTypes";

const initialState = {
    cookiesAccepted: false,
    lastMobilePopup: null,
}


const settingsReducer = ( state = initialState, action) => {

    switch(action.type) {
        case ACCEPT_COOKIES:
            return {
                ...state,
                cookiesAccepted: action.payload,
            }
        case SET_LAST_MOBILE_POPUP:
            return {
                ...state,
                lastMobilePopup: action.payload,
            }
        case 'persist/REHYDRATE':
            return {
                ...initialState,//just in case (user or other scripts can edit localstorage state)
                ...state,
            }
        default:
            return state;
    }

}

export default settingsReducer;