import React, {useMemo} from "react";
import objectPath from "object-path";
import {useHtmlClassService} from "_metronic/layout/_core/MetronicLayout";
import Logo from "components/Logo";
import useAuth from "app/hooks/useAuth";
import DropdownUserNavigation from "templates/includes/I_DropdownUserNavigation";
import {Link, matchPath, useLocation} from "react-router-dom";
import {route} from "app/config/routes/route";
import {useDispatch, useSelector} from "react-redux";
import SymbolImage from "components/SymbolImage";
import {dispatchToggleAsideMobileOpen, LAYOUT_MODE_PUBLIC} from "app/redux/app/appActionTypes";

export function HeaderMobile({isAside}) {
    const uiService = useHtmlClassService();
    const { isLoggedIn } = useAuth();
    const { user } = useSelector(({ auth }) => auth);
    const { layoutMode } = useSelector(({app})=>app.layout)
    const { pathname } = useLocation();
    const match = (path) => !!matchPath(pathname, { path });
    const dispatch = useDispatch();

    const layoutProps = useMemo(() => {
        return {
            headerLogo: uiService.getStickyLogo(),
            asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
            headerMenuSelfDisplay:
              objectPath.get(uiService.config, "header.menu.self.display") === true,
            headerMobileCssClasses: uiService.getClasses("header_mobile", true),
            headerMobileAttributes: uiService.getAttributes("header_mobile")
        };
    }, [uiService]);

    const handleToggleAsideOpen = () => dispatch(dispatchToggleAsideMobileOpen());

    return (
        <>
        {/*begin::Header Mobile*/}
        <div
            id="kt_header_mobile"
            className={`header-mobile align-items-center ${layoutProps.headerMobileCssClasses}`}
            {...layoutProps.headerMobileAttributes}
        >
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center justify-content-between w-100">
                {isAside && (
                    <>
                      {/*begin::Aside Mobile Toggle*/}
                      <button className="btn p-0 mr-2" onClick={handleToggleAsideOpen}>
                        <span className="fas fa-bars fa-2x" />
                      </button>
                      {/*end::Aside Mobile Toggle*/}
                    </>
                )}

                {!isAside && layoutMode===LAYOUT_MODE_PUBLIC && (
                    <>
                      {/*begin::Header Menu Mobile Toggle*/}
                      <button className="btn p-0 mr-2" onClick={handleToggleAsideOpen}>
                        <span className="fas fa-bars fa-2x" />
                      </button>
                      {/*end::Header Menu Mobile Toggle*/}
                    </>
                )}

                {/*begin::Logo*/}
                <Logo
                    color="light"
                    url={match(route['pp']) ? route['pp']
                    : (match(route['tp']) ? route['tp']
                      : route['index'])
                    }
                />

                {/*end::Logo*/}

                {/*begin::Topbar Mobile Toggle*/}
                {isLoggedIn() && (
                  <DropdownUserNavigation>
                    <button className="btn btn-hover-text-primary p-0 ml-2">
                      {/* <span className="fas fa-user-alt fa-2x" /> */}
                      {user?.data?.profilePhotoUrl && <SymbolImage maxWidth={35} className="rounded" src={user.data.profilePhotoUrl} alt=""/>}
                    </button>
                  </DropdownUserNavigation>
                )}
                {!isLoggedIn() && (
                  <Link to={route['auth.login']} className="btn btn-hover-text-primary p-0 ml-2">
                    <span className="fas fa-user-alt fa-2x" />
                  </Link>
                )}
                {/*end::Topbar Mobile Toggle*/}
            </div>
          {/*end::Toolbar*/}
        </div>
        {/*end::Header Mobile*/}
        </>
    );
}
