import React from 'react';
import Select, { components } from "react-select";
import {useField} from "formik";
import {getInputClasses} from "app/formik/getInputClasses";


// limit menu list to 15 && add label if there are more results
export const MenuList = ({children, ...props}) => {
    const LIMIT = 40;
    return (
        <components.MenuList {...props}>
            { Array.isArray(children) ? children.slice(0, LIMIT) : children }
            { Array.isArray(children) && children.length>LIMIT && <div className="text-muted text-center py-2">+ {children.length-LIMIT}</div> }
        </components.MenuList>
    );
};

// limit group menu list to 8 && add label if there are more results
export const Group = ({children, ...props}) => {
    const LIMIT = 8;
    return <components.Group {...props}>
        { Array.isArray(children) ? children.slice(0, LIMIT) : children }
        { Array.isArray(children) && children.length>LIMIT && <div className="text-muted small py-0 px-4">+ {children.length-LIMIT}</div> }
    </components.Group>
};

// add custom heading
export const GroupHeading = (props) => <components.GroupHeading className="text-dark py-2 bg-secondary" {...props}/>


export const findOption = (value, options) => {
    if (!value) return null;
    return options?.reduce((acc, item) => {
        if (acc) return acc;
        if (item?.options) return findOption(value, item.options);
        return item?.value?.toString() === value ? item : null;
    }, /*initial value*/ null) || null /*default value*/;
}

const FormikSearch = ({
    label,
    options,
    placeholder,
    name,
    optionFormat,
    onChange = () => {},
    required,
    disableSearch,
    filterOption = undefined,
    disabled,
    className,
    ...props
}) => {

    const [{ value }, meta, { setValue, setTouched }] = useField(name);

    const handleOnChange = (option) => {
        setTouched(true);
        setValue(option.value);
        onChange(option);
    }

    return (
        <div {...{ className }}>
            {(label || required) && (
                <strong>{label}{required && <span className="text-danger">*</span>}</strong>
            )}
            <Select
                options={options}
                value={findOption(value?.toString(), options)}
                placeholder={placeholder}
                onChange={handleOnChange}
                menuPlacement="auto"
                formatOptionLabel={optionFormat}
                isSearchable={!disableSearch}
                components={{
                    MenuList,
                    Group,
                    GroupHeading,
                }}
                openMenuOnFocus
                isDisabled={disabled}
                noOptionsMessage={() => 'Nie znaleziono'}
                className={`select-search ${getInputClasses(meta, '')}`}
                classNamePrefix="select-search"
                filterOption={filterOption}
                {...props}
            />
            {meta.touched && meta.error && <div className="error invalid-feedback">{meta.error}</div>}
        </div>
    );
};

export default FormikSearch;
