import React from 'react';
import {OverlayTrigger} from "react-bootstrap";
import styled from "styled-components";
import { Tooltip as BootstrapTooltip } from 'react-bootstrap';

const StyledTooltip = styled.div`
    cursor: ${({ cursor }) => cursor};
`;

const Tooltip = ({
    id,
    tooltip,
    children,
    className = '',
    placement = 'top',
    cursor = 'help',
    innerComponent = 'div',
    ...props
}) => {
    const InnerComponent = innerComponent
    if (!tooltip)
        return (
            <InnerComponent className={className} {...props}>
                {children}
            </InnerComponent>
        )
    return (
        <OverlayTrigger
            placement={placement}
            overlay={
                <BootstrapTooltip id={id} className="tooltip-dark">
                    {tooltip}
                </BootstrapTooltip>
            }
        >
            <StyledTooltip cursor={cursor} as={innerComponent} className={className} {...props}>
                {children}
            </StyledTooltip>
        </OverlayTrigger>
    );
};

export default Tooltip;

export const FormTooltip = (props) => (
    <Tooltip {...props} innerComponent="span" className="ml-2">
        <i className="fas fa-question-circle fa-sm text-muted" />
    </Tooltip>
);
