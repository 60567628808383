import React from "react";
import {NotificationIcon} from "components/Icons";

const TopbarNotificationUnreadBtn = ({ count, onClick }) => (
    <div className="topbar-item mr-1 cursor-pointer text-hover-dark mr-2 position-relative">
        <div onClick={onClick} className="position-relative p-2">
            <NotificationIcon className="fa-2x p-0 text-dark-50" />
            {!!count && (
                <span className="badge badge-danger badge-pill py-1 px-2 position-absolute top-0 right-0">
                    {count}
                </span>
            )}
        </div>
    </div>
);

export default TopbarNotificationUnreadBtn;
