import React, {useContext} from 'react';
import {APP_VERSION} from "app/config/env";
import localVersion from "build-version.local.json";
import globalVersion from "build-version.json";

const BuildVersionContext = React.createContext("");
export const useBuildVersion = () => useContext(BuildVersionContext);

const BuildVersionProvider = ({ children }) => {
    const buildVersion = APP_VERSION + '.' + Math.max(localVersion?.['build-version']??0, globalVersion?.['build-version']??0);

    return (
        <BuildVersionContext.Provider value={buildVersion}>
            {children}
        </BuildVersionContext.Provider>
    );
};

export default BuildVersionProvider;
