import React from 'react';
import { useFormikContext } from "formik";
import {Alert} from "react-bootstrap";

const FormikErrors = () => {
    const { status } = useFormikContext();
    if (!status) return null;

    return (
        <Alert variant="danger" className="my-4">
            {status}
        </Alert>
    );
};

export default FormikErrors;
