import React from 'react';
import DefaultLayout from "templates/public/L_Default";
import { Link } from 'react-router-dom';
import { route } from 'app/config/routes/route';
import styled from 'styled-components';
import {useSelector} from "react-redux";

const PageNotFoundContainer = styled.div`
    min-height: calc(90vh - 20em);
`;

const Error404 = () => {
    const { user, authToken } = useSelector(({ auth }) => auth);
    return (
        <DefaultLayout>
            <PageNotFoundContainer
                className="d-flex flex-column align-items-center justify-content-center text-center p-10 pb-lg-30">
                <h1 className="display-2 font-weight-bold">Przepraszamy!</h1>
                <h4 className="display-4 mb-15">Tej strony nie ma w naszym systemie.</h4>
                {authToken && user?.data && (
                    <>
                        <h5 className="mb-5">
                            Jest za to mnóstwo {' '}
                            <Link to={route['p.trainingPlans']} className="font-weight-bold">planów treningowych</Link>
                            {' '} i {' '}
                            <Link to={route['p.trainers']} className="font-weight-bold">trenerów</Link>
                            , którzy czekają <b>właśnie na Ciebie</b> :)
                        </h5>
                        <Link to={route['p.trainingPlans']}>
                            <button type="button" className="btn btn-primary btn-lg px-10 mb-3">Zobacz</button>
                        </Link>
                    </>
                )}
                <Link to={route['index']}>
                    <button type="button" className="btn btn-secondary btn-lg px-10">Strona główna</button>
                </Link>
            </PageNotFoundContainer>
        </DefaultLayout>
    );
};

export default Error404;
