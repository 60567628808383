
export const reducerSectionChangeDisplayType = (state, { sectionIndex, sectionDisplayType }) => {

    // const descriptionHTML = state.sections[sectionIndex].description;
    // const fakeDiv = document.createElement('div');
    // fakeDiv.innerHTML = descriptionHTML;
    // const description = fakeDiv.innerText;

    return {
        ...state,
        sections: {
            ...state.sections,
            [sectionIndex]: {
                ...state.sections[sectionIndex],
                sectionDisplayType,
                // description,
            },
        },
    };
};