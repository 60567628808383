import React, { useState } from 'react';
import {useHistory} from 'react-router-dom';
import {useForceSaveTraining} from "app/configurator/utils/utilsTraining";
import {useSelector} from "react-redux";
import {selectTraining} from "app/configurator/utils/utilsReduxUseSelector";
import {useSnackbar} from "notistack";
import {route} from "app/config/routes/route";
import {useConfiguratorType} from "app/configurator/contex/configuratorContext";
import {CONFIGURATOR_DEFAULT, CONFIGURATOR_PLAYER_PLAN, CONFIGURATOR_SINGLE_TRAINING} from "app/config/configurator";

const SaveTrainingButton = ({label='Zapisz', trainingPlanId, moduleId, trainingId}) => {
    const [isSaving, setIsSaving] = useState(false);
    const training = useSelector(selectTraining(trainingId));
    const type = useConfiguratorType();
    const forceSaveTraining = useForceSaveTraining(
        type===CONFIGURATOR_SINGLE_TRAINING ? trainingId : trainingPlanId,
        moduleId,
        isSaving,
        setIsSaving
    );
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const handleAddSuccess = ({data}) => {
        if(type===CONFIGURATOR_DEFAULT) {
            history.replace(route['tp.configurator.template.trainingPlan.module'](trainingPlanId, moduleId, data?.id??null));
        } else if(type===CONFIGURATOR_PLAYER_PLAN) {
            history.replace(route['tp.configurator.clientTrainingPlan.module'](trainingPlanId, moduleId, data?.id??null));
        } else if(type===CONFIGURATOR_SINGLE_TRAINING) {
            //do nothing
        }
        enqueueSnackbar(
            `Pomyślnie zapisano trening.`,
            {
                variant: 'success',
            },
        );
    }
    const handleAddError = () => {
        enqueueSnackbar(
            `Przy zapisywaniu treningu wsytąpił błąd.`,
            {
                variant: 'error',
            },
        );
    }
    const handleForceSaveTraining = () => forceSaveTraining(training, handleAddSuccess, handleAddError);

    return (
        <button
            className={`btn btn-primary btn-sm ${isSaving ? 'spinner spinner-sm spinner-white spinner-left' : ''}`}
            onClick={handleForceSaveTraining}
            disabled={isSaving}
        >
            {label}
        </button>
    );
};

export default SaveTrainingButton;
