import {DAY} from "app/redux/configurator/configuratorTypes";

export const reducerClipboardDayCopy = (state, {dayNo, mode}) => {
    return {
        ...state,
        helpers: {
            ...state.helpers,
            clipboard: {
                scope: DAY,
                mode,
                dayNo,
            }
        }
    };
};