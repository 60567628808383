import axios from "axios";
import usePaginatedQuery2 from "app/hooks/usePaginatedQuery2";
import {BASE_URL_API} from "app/config/env";

const getConversations = ({queryKey: [_key, panelType, Search = '', PageSize]}) => {
    return axios({
        method: 'GET',
        url: panelType==='tp' ? (
            `${BASE_URL_API}/v1/trainer-panel/chats`
        ) : (
            `${BASE_URL_API}/v1/player-panel/chats`
        ),
        params: {
            Search,
            PageSize,
        }
    });
}

export const useConversations = (panelType, onSuccess, search='', pageSize) => {
    return usePaginatedQuery2({
        queryKey: ['common.messages', panelType, search, pageSize],
        queryFn: getConversations,
        config: {
            onSuccess: ({data})=>onSuccess(data),
        }
    });
}
