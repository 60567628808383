import React, {Suspense, useEffect, useMemo} from 'react';
import {Route} from "react-router-dom";
import Helmet from "app/core/Helmet";
import {useDispatch} from "react-redux";
import {dispatchSetLayoutMode, LAYOUT_MODE_PUBLIC} from "app/redux/app/appActionTypes";
import Loading from "components/Loading";
import Error401 from "views/pages/core/errors/Error401";

export const AppRoute = (
    {
        path,
        layoutMode,
        meta,
        component: Component,
        outer: Outer,
        requiredRoles,
        componentProps = {},
        chatPlugin,
        exact,
        loading = <Loading />,
        validate,
        ...props
    }
) => {

    const dispatch = useDispatch();
    // useEffect(() => {
    //     console.log('TEST CHAT PLUGIN', chatPlugin)
    //     if(chatPlugin===true || chatPlugin===false) {
    //         window.fbChat.style.display = chatPlugin ? 'block' : 'none';
    //     }
    // }, [chatPlugin])

    const metaTags = useMemo(()=>{
        if(!meta || !meta?.title) {
            return false;
        } else {
            let newMetaTags = {};
            if(meta?.title)       newMetaTags.title = meta.title;
            if(meta?.description) newMetaTags.description = meta.description;
            if(meta?.customMeta)  newMetaTags.customMeta = meta.customMeta;
            if(meta?.image)       newMetaTags.image = meta.image;
            return newMetaTags;
        }
    }, [meta]);

    useEffect(() => {
        if (validate === false) {
            dispatch(dispatchSetLayoutMode(LAYOUT_MODE_PUBLIC));
        } else if (layoutMode) {
            dispatch(dispatchSetLayoutMode(layoutMode));
        }
    }, [layoutMode, validate]);

    if (validate === false) {
        return <Error401 />
    }

    return (
        <Route
            path={path}
            exact={exact}
            render={
                () => (
                    <Suspense fallback={loading}>
                        {Outer ? (
                            <Outer>
                                {metaTags && <Helmet {...metaTags}/>}
                                <Component {...componentProps}/>
                            </Outer>
                        ) : (
                            <>
                                {metaTags && <Helmet {...metaTags}/>}
                                <Component {...componentProps}/>
                            </>
                        )}
                    </Suspense>
                )
            }
            {...props}
        />
    );
};
