import {reducerError} from "app/redux/configurator/reducers/reducerError";
import {reducerSectionCreate} from "app/redux/configurator/reducers/3.section/reducerSectionCreate";
import {createExerciseObj} from "app/configurator/utils/utilsExercise";
import {unsavedTrainings} from "app/redux/configurator/utils/configuratorHelpers";
import warning from "react-redux/lib/utils/warning";
import {debugWarn} from "components/_dev/debugLog";
import {reorderNo} from "app/configurator/utils/superseries/reorderNo";

export const reducerExerciseCreate = (state, { sectionIndex, trainingIndex, dayNo, newExerciseIndex, exercise }, alreadyTriedCreateSection = false) => {
    if(!state.sections[sectionIndex] || !state.exercises[sectionIndex]) {
        if(!sectionIndex && !trainingIndex && !dayNo) {
            return reducerError(state, 'exercise.create.sectionNotExist.missingDataToCreate');
        }
        if(alreadyTriedCreateSection) {
            return reducerError(state, 'exercise.create.sectionNotExist.createSectionFailed')
        }
        const stateWithNewlyCreatedSection = reducerSectionCreate(state, {dayNo, trainingIndex});
        const newSectionIndex = `c${stateWithNewlyCreatedSection.helpers.itemsCount-1}`;
        return reducerExerciseCreate(stateWithNewlyCreatedSection, {sectionIndex: newSectionIndex, trainingIndex, dayNo, exercise}, true);
    }
    const newExercise = createExerciseObj({
        ...exercise,
        _key: `n${state.helpers.itemsCount}`,
    });
    const prevExercise = newExerciseIndex===0 ? null : state.exercises[sectionIndex].exercises[newExerciseIndex-1];
    const nextExercise = state.exercises[sectionIndex].exercises[newExerciseIndex] || null;
    debugWarn('TODO: dMissing error message "superserie is too big"')
    // console.log(newExercise)
    if(!newExercise) {
        return reducerError(state, 'exercise.create.exerciseValidationError');
    }
    if(!prevExercise) {
        newExercise._superseries.isLast = true;
        newExercise._superseries.superserieSize = 1;
        newExercise.orderNo = 0;
    } else {
        const prevSuperserieNo = !prevExercise ? -1 : Math.floor(prevExercise.orderNo/10);
        const nextSuperserieNo = !nextExercise ? 0  : Math.floor(nextExercise.orderNo/10);
        const superserieSize   = !prevExercise ? 0  : prevExercise._superseries.superserieSize;
        //
        newExercise._superseries.isLast = true;
        newExercise._superseries.superserieSize = null;
        // console.log(prevSuperserieNo, nextSuperserieNo)
        //
        if(prevSuperserieNo === nextSuperserieNo) {
            if(superserieSize >=9) {
                return state; // superserie is too big //TODO: add error message
            }
            newExercise._superseries.isLast = false;
            newExercise.orderNo = prevExercise.orderNo;
        } else {
            if(!prevExercise && !nextExercise) {
                newExercise.orderNo = 0;
            } else {
                const orderNoToRecalculate = prevExercise ? prevExercise.orderNo+10 : nextExercise.orderNo;
                newExercise.orderNo = Math.floor(orderNoToRecalculate/10)*10;
            }
        }
    }

    const exercisesToReorder = [
        ...state.exercises[sectionIndex].exercises.slice(0, newExerciseIndex),
        {
            ...newExercise,
        },
        ...state.exercises[sectionIndex].exercises.slice(newExerciseIndex),
    ];

    const exercises = reorderNo(exercisesToReorder);

    return {
        ...state,
        exercises: {
            ...state.exercises,
            [sectionIndex]: {
                ...state.exercises[sectionIndex],
                exercises,
            },
        },
        helpers: {
            ...state.helpers,
            itemsCount: state.helpers.itemsCount+1,
            unsavedTrainings: unsavedTrainings(state.helpers).add([state.sections[sectionIndex].trainingIndex]),
        },
    }
}