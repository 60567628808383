import {
  TYPE_SET_CHAT,
  TYPE_SET_CHATS,
  TYPE_SET_MESSAGES, TYPE_SET_TMP_MESSAGE,
  TYPE_SET_OPEN_CHAT, TYPE_SET_SEARCH, TYPE_ADD_NEW_MESSAGE, TYPE_SET_CHAT_PAGINATION
} from "app/redux/chats/chatsTypes";
import {reducerCreateChat} from "app/redux/chats/reducers/chat/reducerCreateChat";
import {reducerCreateChats} from "app/redux/chats/reducers/chat/reducerCreateChats";
import {reducerSetMessages} from "app/redux/chats/reducers/chatMessages/reducerSetMessages";
import {reducerSetActiveChatId} from "app/redux/chats/reducers/chat/reducerSetActiveChatId";
import {reducerSetSearch} from "app/redux/chats/reducers/chat/reducerSetSearch";
import {reducerSortChats} from "app/redux/chats/reducers/chat/reducerSortChats";
import {reducerSetNewTempMessage} from "app/redux/chats/reducers/chat/reducerSetNewTempMessage";
import {reducerSetNewMessage} from "app/redux/chats/reducers/chatMessages/reducerSetNewMessage";
import {LOGOUT} from "app/redux/auth/actionTypes";
import {PAGE_SIZE, reducerSetChatPagination} from "app/redux/chats/reducers/chat/reducerSetChatPagination";

const initialState = () => ({
  chats: {},
  chatMessages: {},
  chatsPagination: PAGE_SIZE,
  activeChatId: null,
  search: '',
  chatsSorted:[],
});

export const chatsReducer = (state = initialState(), {type, payload}) => {

  switch(type){

    case LOGOUT:
      return initialState();
    // CHATS
    case TYPE_SET_CHAT:
      return reducerSortChats(reducerCreateChat(state, payload));
    case TYPE_SET_CHATS:
      return reducerSortChats(reducerCreateChats(state, payload));
    case TYPE_SET_OPEN_CHAT:
      return reducerSetActiveChatId(state, payload);
    case TYPE_SET_SEARCH:
      return reducerSetSearch(state, payload);
    case TYPE_SET_TMP_MESSAGE:
      return reducerSetNewTempMessage(state, payload);

    // MESSAGES
    case TYPE_SET_MESSAGES:
      return reducerSetMessages(state, payload);
    case TYPE_SET_CHAT_PAGINATION:
      return reducerSetChatPagination(state, payload);
    case TYPE_ADD_NEW_MESSAGE:
      return reducerSortChats(reducerSetNewMessage(state, payload));

    // DEFAULT
    default:
      return state;
  }

}
