
export const CONFIGURATOR_SET_ENTIRE_MODULE = 'configurator.module.setEntire';
export const CONFIGURATOR_SET_TYPE = 'configurator.setType';

export const WEEK_ADD    = 'configurator.week.add';
export const WEEK_REMOVE = 'configurator.week.remove';

export const DAY_CREATE          = 'configurator.day.create';
export const DAY_EDIT_BY_KEY     = 'configurator.day.edit';
export const DAY_TOGGLE_DAY_TYPE = 'configurator.day.toggleType';
export const DAY_EDIT_NOTE       = 'configurator.day.editNote';

export const TRAINING_CREATE                = 'configurator.training.create';
export const TRAINING_EDIT_BY_KEY           = 'configurator.training.edit';
export const TRAINING_TOGGLE_COLLAPSE       = 'configurator.training.toggleCollapse';
export const TRAINING_SET_MODAL_ID          = 'configurator.training.setModalId';
export const TRAINING_DELETE                = 'configurator.training.delete';
export const TRAINING_UPDATE_FORCE_FROM_API = 'configurator.training.forceUpdateFromApi';
export const TRAINING_TOGGLE_IS_UPDATING    = 'configurator.training.toggleIsUpdating';
export const TRAINING_ADD_FROM_TEMPLATE     = 'configurator.training.addFromTemplate';

export const SECTION_CREATE              = 'configurator.section.create';
export const SECTION_EDIT_BY_KEY         = 'configurator.section.edit';
export const SECTION_DELETE              = 'configurator.section.delete';
export const SECTION_REORDER             = 'configurator.section.reorder';
export const SECTION_TOGGLE_COLLAPSE     = 'configurator.section.toggleCollapse';
export const SECTION_CHANGE_DISPLAY_TYPE = 'configurator.section.changeDisplayType';

export const EXERCISE_CREATE_AT_INDEX             = 'configurator.exercise.create';
export const EXERCISE_EDIT_BY_KEY                 = 'configurator.exercise.edit';
export const EXERCISE_TOGGLE_TEST_EXERCISE        = 'configurator.exercise.toggleTestExercise';
export const EXERCISE_MOVE                        = 'configurator.exercise.move';
export const EXERCISE_REORDER                     = 'configurator.exercise.sort';
export const EXERCISE_DELETE                      = 'configurator.exercise.delete';
export const EXERCISE_SET_TEST_EXERCISE           = 'configurator.exercise.setTestExercise';
export const EXERCISE_TOGGLE_COLLAPSE             = 'configurator.exercise.toggleCollapse';
export const EXERCISE_SERIES_TOGGLE_ARE_THE_SAME  = 'configurator.exercise.series.toggleAreSeriesTheSame';
export const EXERCISE_SERIES_SET_COUNT            = 'configurator.exercise.series.setCount';
export const EXERCISE_SERIES_SET_SERIE_VALUE      = 'configurator.exercise.series.serie.setValue';
export const EXERCISE_SERIES_SET_SERIE_MULTIPLIER = 'configurator.exercise.series.serie.setMultiplier';
export const EXERCISE_SERIES_SET_SERIE_MODE       = 'configurator.exercise.series.serie.setMode';
export const EXERCISE_SERIES_SET_MATH_MODE        = 'configurator.exercise.series.serie.setMathMode';

export const SUPERSERIE_CREATE = 'configurator.superserie.create';
export const SUPERSERIE_REMOVE = 'configurator.superserie.remove';

export const SET_TOOLBAR_SEARCH = 'configurator.toolbar.setSearch';
export const SET_SEARCH_EXERCISE_FOLDER = 'configurator.search.setExerciseFolder';
export const SET_TOOLBAR_TAB    = 'configurator.toolbar.setTab';

export const CLIPBOARD_DAY_COPY   = 'configurator.clipboard.day.copy';
export const CLIPBOARD_DAY_PASTE  = 'configurator.clipboard.day.paste';
export const CLIPBOARD_CANCEL     = 'configurator.clipboard.day.cancel';
////// ///// //////////////////// / ///////////
export const DAY = 'DAY';
export const COPY = 'COPY';
export const CUT  = 'CUT';
///
export const FOLDER_TRAINERON = true;
export const FOLDER_TRAINER = false;
export const FOLDER_ALL = null;