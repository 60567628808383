import React, {useEffect, useState, useRef} from 'react';
import {useLocation} from "react-router-dom";
// import { QueryClient, QueryClientProvider  } from 'react-query';
import usePrevious from "app/hooks/usePrevious";
import I_Cookies from "templates/includes/I_Cookies";
import { SnackbarProvider } from 'notistack';
import SocialLoginProvider from './SocialLoginProvider';
import BuildVersionProvider from "app/providers/BuildVersionProvider";
import {SignalRProvider} from "app/providers/signalR/SignalRProvider";
import {MeasureUnitGroupProvider} from "app/providers/MeasureUnitGroupProvider";
import {MobilePopup} from "app/core/MobilePopup";

// const queryClient = new QueryClient()

export const AnimateLoadingContext = React.createContext({pathname:'',prevPathname:''});

const AnimateLoadingProvider = ({children}) => {

    const location = useLocation();
    const [ update, setUpdate ] = useState(true);
    const prevLocation = usePrevious(location);

    useEffect(() => {
        if(prevLocation && (location.pathname !== prevLocation.pathname || location.search !== prevLocation.search)) {
            setUpdate(true);
        }
    }, [prevLocation, location.pathname, location.search, location]);

    const allowUpdate = () => {
        if(update){
            setUpdate(false);
            return true;
        }
        return false;
    }

    return (
        <AnimateLoadingContext.Provider value={{update,allowUpdate}}>
            <SignalRProvider>
                <MeasureUnitGroupProvider>
                    {/*<QueryClientProvider client={queryClient}>*/}
                        {children}
                    {/*</QueryClientProvider>*/}
                </MeasureUnitGroupProvider>
            </SignalRProvider>
            <I_Cookies/>
        </AnimateLoadingContext.Provider>
    );
};

const NotificationProvider = ({ children }) => {
    const ref = useRef();
    return (
        <SnackbarProvider
            maxSnack={5}
            ref={ref}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            action={(key) => (
                <span className="btn" onClick={() => ref?.current?.closeSnackbar(key)}>
                    <i className="fas fa-times text-white opacity-50" />
                </span>
            )}
        >
            {children}
        </SnackbarProvider>
    );
};

const TraineronProvider = ({ children }) => (
    <AnimateLoadingProvider>
        <NotificationProvider>
            <SocialLoginProvider>
                <BuildVersionProvider>
                    {children}
                    <MobilePopup/>
                </BuildVersionProvider>
            </SocialLoginProvider>
        </NotificationProvider>
    </AnimateLoadingProvider>
);

export default TraineronProvider;
