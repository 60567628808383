import React from "react";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {PersistGate} from "redux-persist/integration/react";
// import { I18nProvider } from "_metronic/i18n";
import {Layout, LayoutSplashScreen} from "_metronic/layout";
import TraineronProvider from "../providers/TraineronProvider";
import Routes from "views/routes/Routes";
import {QueryClient, QueryClientProvider} from "react-query";
import 'react-contexify/dist/ReactContexify.min.css';


const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            cacheTime: 0,
        }
    }
})

export default function App({ store, persistor, basename }) {
    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
                    <React.Suspense fallback={<LayoutSplashScreen />}>
                        {/*<BrowserRouter basename={basename}>*/}
                        <TraineronProvider>
                            <Layout>
                                <Routes />
                            </Layout>
                        </TraineronProvider>
                        {/*</BrowserRouter>*/}
                    </React.Suspense>
                </PersistGate>
            </Provider>
        </QueryClientProvider>
    );
}
