import axios from "axios";
import {BASE_URL_API} from "app/config/env";
import useQuery2 from "app/hooks/useQuery2";

const getMenuData  = ({queryKey: [_key, panelType]}) => axios({
  method: 'GET',
  url: `${BASE_URL_API}/v1/${panelType === 'pp' ? 'player' : 'trainer'}-panel/my-account/menu-data`,
});

export const useMenuData = (panelType, enabled) => useQuery2({
  queryKey:['menu.data', panelType],
  queryFn: getMenuData,
  config: {
    refetchInterval: 10000,
    enabled:false,
  },
});
