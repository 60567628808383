import {reducerError} from "app/redux/configurator/reducers/reducerError";
import {reducerDayCreate} from "app/redux/configurator/reducers/1.day/reducerDayCreate";
import {createTrainingObj} from "app/configurator/utils/utilsTraining";
import {unsavedTrainings} from "app/redux/configurator/utils/configuratorHelpers";

export const reducerTrainingCreate = (state, { dayNo = null, name = 'Trening 1' }, alreadyTriedDayCreate = false) => {
    // validation
    if(!state.days[dayNo]) {
        if(alreadyTriedDayCreate){
            return reducerError(state, 'training.create.dayNotExist.createDayFailed');
        }
        const stateWithNewlyCreatedDay = reducerDayCreate(state, { dayNo });
        return reducerTrainingCreate(stateWithNewlyCreatedDay, { dayNo, name }, true);
    }
    // create training
    const newTrainingId = `${state.helpers.itemsCount}.c`;
    return {
        ...state,
        days: {
            ...state.days,
            [dayNo]: {
                ...state.days[dayNo],
                trainings: [
                    ...state.days[dayNo].trainings,
                    newTrainingId,
                ]
            }
        },
        trainings: {
            ...state.trainings,
            [newTrainingId]: createTrainingObj({ name, dayNo, trainingIndex: newTrainingId }),
        },
        helpers: {
            ...state.helpers,
            unsavedTrainings: unsavedTrainings(state.helpers).add([ newTrainingId ]),
            itemsCount: state.helpers.itemsCount+1,
        }
    }
}